import Loader from 'react-loader-spinner';

export default function Spinner() {
  return (
    <div
      className='row justify-content-center align-items-center m-auto  spinner'
    >
      <Loader type='ThreeDots' color='#1bb0b8' secondaryColor='#123f87' height={50} width={50} />
    </div>
  );
}
