import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    heading: '',
    content: '',
    type: 'success',
};

const alertSlice = createSlice({
    name: 'alert',
    initialState,
    reducers: {
        warn: (state, action) => {
            return {
                ...state,
                type: 'error',
                heading: action.heading,
                content: action.content,
            };
        },
        info: (state, action) => {
            return {
                ...state,
                type: 'success',
                heading: action.heading,
                content: action.content,
            };
        },
        clear: () => initialState,
    },
});

// Action creators are generated for each case reducer function
export const { warn, info, clear } = alertSlice.actions;

export default alertSlice.reducer;
