import { useState, useEffect } from 'react';
import { FaPercent, FaRegMoneyBillAlt } from 'react-icons/fa';
import PropTypes from 'prop-types';

import { translate } from '../../../../../../app/i18n';
import { TextField } from '../../../../../molecules';

export default function ModalContentDiscount({
  price,
  discount,
  callBackDiscount,
}) {
  const defaultDiscount = {
    discount_unit: 'percentage',
    discount_amount: 0,
    discount_percentage: 0,
  };
  const { discount_unit, discount_amount, discount_percentage } =
    discount || defaultDiscount;

  const [state, setState] = useState({
    active: 'percentage',
    priceAfterDiscount: price,
  });

  const limitPercentage = 90,
    limitAmount = price * 0.9,
    // check which button is active
    checkPercentageIsActive = state.active === 'percentage';

  useEffect(() => {
    if (discount) {
      setState({
        active: discount_unit,
        priceAfterDiscount: price - discount_amount,
        value:
          discount_unit === 'percentage'
            ? discount_percentage
            : discount_amount,
      });
    }
  }, []);

  const handleOnClick = (e) => {
    setState({
      ...state,
      isError: false,
      active: e.target.getAttribute('id'),
      priceAfterDiscount: price - discount_amount,
      value:
        e.target.getAttribute('id') === 'percentage'
          ? discount_percentage
          : discount_amount,
    });
  };

  const handleOnChange = (e) => {
    // when active is percentage
    if (
      checkPercentageIsActive &&
      e.target.value <= limitPercentage &&
      e.target.value >= 0
    ) {
      setState({
        ...state,
        isError: false,
        value: e.target.value,
        priceAfterDiscount: price - (price * e.target.value) / 100,
      });
      let body = {
        discount_unit: 'percentage',
        discount_value: parseInt(e.target.value),
      };
      callBackDiscount(body);
    }
    //when active is value
    else if (
      !checkPercentageIsActive &&
      e.target.value <= limitAmount &&
      e.target.value >= 0
    ) {
      setState({
        ...state,
        isError: false,
        value: e.target.value,
        priceAfterDiscount: price - e.target.value,
      });
      let body = {
        discount_unit: 'amount',
        discount_value: parseInt(e.target.value),
      };
      callBackDiscount(body);
    } else {
      setState({ ...state, isError: true });
    }
  };

  return (
    <div className='discount-container'>
      <div className='discount-buttons-container text-center'>
        <button
          id='percentage'
          className={checkPercentageIsActive && 'active'}
          onClick={handleOnClick}
        >
          <FaPercent className='discount-buttons-container__icon' />
          {translate('general.percentage')}
        </button>
        <button
          id='amount'
          className={state.active === 'amount' && 'active'}
          onClick={handleOnClick}
        >
          <FaRegMoneyBillAlt className='discount-buttons-container__icon' />
          {translate('general.value')}
        </button>
      </div>
      <TextField
        name='discount'
        type='number'
        max={checkPercentageIsActive ? limitPercentage : limitAmount}
        defaultValue={
          checkPercentageIsActive ? discount_percentage : discount_amount
        }
        value={state.value}
        handleOnChange={handleOnChange}
        className='discount-text'
      />
      {state.isError && (
        <small className='text-danger'>
          {translate('general.discountErrorMessage')}
        </small>
      )}
      <p className='mt-2 text-center'>
        {translate('general.priceAfterDiscount')}
        {state.priceAfterDiscount}
      </p>
    </div>
  );
}

ModalContentDiscount.propTypes = {
  title: PropTypes.string,
  price: PropTypes.number,
  discount: PropTypes.object,
  callBackDiscount: PropTypes.func,
};
