import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Row, Col } from 'react-bootstrap';
import Skeleton from 'react-loading-skeleton';

import { SearchResult, Pagination } from '../../../atoms';
import { Searchbar , Sidebar  } from '../../../molecules';
import SingleTab from '../singleTab/singleTab';
import { translate } from '../../../../app/i18n';
import { getNumberOfPagesByLimit , isEmpty } from '../../../../app/helper/general';
import { returnsMapper } from '../../../../app/mappers/mappers';
import {
  fetchReturns,
  clearFilters,
  updateQueryObject,
  fetchReturnFilters
} from '../../../../redux/actions/returnsAction';

export default function Returns() {
  const dispatch = useDispatch();

  const {
    data,
    numberOfResults,
    isDataLoading,
    queryObject,
    options,
  } = useSelector((state) => state.returns);

  useEffect(() => {
    Promise.all([
      dispatch(fetchReturnFilters()),
      dispatch(fetchReturns())
    ]);
  }, []);

  const updateData = (name, value) => {
    dispatch(updateQueryObject(name, value));
    dispatch(fetchReturns());
  };

  const reset = () => {
    dispatch(clearFilters());
    dispatch(fetchReturns());
  };

  return (
    <div>
      <Row>
        <Col lg={3}>
        {isEmpty(options.filters) ? 
            <Skeleton style={{ height: '600px' }} /> :
          <Sidebar
            filters={options.filters}
            updateData={updateData}
            queryObject={queryObject}
            reset={reset}
          />
          }
        </Col>
        <Col>
          <Searchbar
            numberOfResults={numberOfResults}
            sortOptions={options.sort}
            queryObject={queryObject}
            type={
              numberOfResults === 1
                ? translate('return.singular')
                : translate('return.plural')
            }
            updateData={updateData}
          />
          <SearchResult
            keyWord={queryObject.kw}
            totalNumberOfResults={numberOfResults}
          />
          <SingleTab
            allData={data}
            data={returnsMapper(data)}
            type='Return'
            isDataLoading={isDataLoading}
          />
          <Pagination
            handleActivePage={(page) => updateData('page', page)}
            data={{
              counter: getNumberOfPagesByLimit('returns'),
              currentPage: queryObject.page,
            }}
          />
        </Col>
      </Row>
    </div>
  );
}
