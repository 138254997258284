import React from 'react';
import AuthHeader from './header/authHeader';
import AuthFooter from './footer/authFooter';

export default function Index(props) {
  return (
    <div className='auth-layout'>
      <AuthHeader />
      {props.children}
      <AuthFooter />
    </div>
  );
}
