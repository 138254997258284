import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    id: null,
    refreshToken: null,
    token: null,
    language: 'en',
    error: null,
    isDataLoading: false,
    isAuthenticated: null,
};

const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        loading: (state) => ({ ...state, isDataLoading: true }),
        loginSuccess: (state, action) => {
            return {
                ...state,
                id: action.payload.id,
                refreshToken: action.payload.refreshToken,
                token: action.payload.token,
                language: action.payload.language,
                isAuthenticated: true,
                isDataLoading: false,
                error: null,
            };
        },
        loginFailed: (state, action) => {
            return {
                ...state,
                error: action.payload.error,
                isDataLoading: false,
            };
        },
        logoutSuccess: (state) => {
            return {
                ...state,
                ...initialState,
            };
        },
        logoutFailed: (state, action) => {
            return {
                ...state,
                isDataLoading: false,
                error: action.payload.error,
            };
        },
        authSuccess: (state, action) => {
             return {
                ...state,
                id: action.payload.id,
                refreshToken: action.payload.refreshToken,
                token: action.payload.token,
                language: action.payload.language,
                isAuthenticated: true,
                isDataLoading: false,
                error: null,
            };
        },
        destroySession: (state) => {
            return {
                ...state,
                isAuthenticated: false,
            };
        },
    },
});

// Action creators are generated for each case reducer function
export const {
    loading,
    loginSuccess,
    loginFailed,
    destroySession,
    authSuccess,
} = authSlice.actions;

export default authSlice.reducer;
