import { translate } from '../../../app/i18n';

export default function PostDetails({ id }) {


    return (
        //@TODO 
        <div className='screen'>
            <h1>{translate('post.postDetails')}</h1>
        </div>
    )
}
