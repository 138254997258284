import { API } from '../constants';

export default function categoryService(httpClient) {
    const getCategoryParents = () => {
        return httpClient.get(API.GET_CATEGORY_PARENTS);
    };
    const getCategoryChilds = (id) => {
        return httpClient.get(`${API.GET_CATEGORY_CHILDS}${id}`);
    };
    const getBulkUploads = (body) => {
        return httpClient.get(`${API.GET_BULK_UPLOADS}` , body);
    };
    const postBulkUploads = (body) => {
        return httpClient.post(`${API.POST_BULK_UPLOADS}` , body);
    };
    const getDownloadLink = (link)=> {
            return httpClient.get(link, undefined,{ 'Access-Control-Allow-Origin':'*' })
       
    }
    const getBulkHistory = ()=>{
        return httpClient.get(`${API.GET_BULK_HISTORY}`)
    }

    return { getCategoryParents, getCategoryChilds , getBulkUploads , getDownloadLink ,postBulkUploads, getBulkHistory };
}