import { API } from '../constants';

export default function userService(httpClient) {

    const getUserData = () => {
        return httpClient.get(API.GET_USER_DATA);
    }

    const getUserWallet = () => {
        return httpClient.get(API.GET_USER_WALLET);
    };

    const getUserQuestions = () => {
        return httpClient.get(API.GET_USER_QUESTIONS);
    };

    const getUserLocation = async () => {
        // set Cairo as default location
        const location = { lat: '30.008', lon: '31.2194' };

        try {
            // Try to get user location from ipapi
            const data = await httpClient.get('https://ipapi.co/json');
            location.lat = data.latitude;
            location.lon = data.longitude;
        } catch (error) {
            // If failed get location from navigator
            navigator.geolocation.getCurrentPosition((pos) => {
                location.lat = pos.coords.latitude;
                location.lon = pos.coords.longitude;
            });
        }
        return location;
    };

    const updateUserLanguage = (body) => {
        return httpClient.post(API.UPDATE_USER_LANGUAGE, body)
    }

    return { getUserWallet, getUserQuestions, getUserLocation , getUserData , updateUserLanguage };
}
