import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { orderDetailMapper } from '../../../app/mappers/mappers';
import services from '../../../services';
import OrderItem from './orderItem/orderItem';
import OrderInfo from './orderInfo/orderInfo';
import { translate } from '../../../app/i18n';

const OrderDetails = () => {
    const [order, setOrder] = useState(null);
    let { id: orderId } = useParams();

    const fetchOrder = async (id) => {
        try {
            const data = await services.orderService.getOrderDetails(id);
            setOrder(orderDetailMapper(data));
        } catch (error) {
            throw new Error(error);
        }
    };

    useEffect(async () => {
        if (orderId) fetchOrder(orderId);
    }, []);

    return (
        <div className='screen'>
            <h1>{translate('order.orderDetails')}</h1>
            {order && (
                <div>
                    <OrderInfo
                        date={order.date}
                        price={order.totalPrice}
                        id={order.esId}
                    />
                    {order.posts.map((post) => (
                        <OrderItem key={post.id} post={post} />
                    ))}
                </div>
            )}
        </div>
    );
};

export default OrderDetails;
