import { createSlice } from '@reduxjs/toolkit';
import Service from '../../services'

const initialState = {
    wallet: {
        balance: 0.0,
        onHold: 0.0,
        pending: 0.0,
        credit: 0.0,
        debit: 0.0,
        goldCoins: 0.0,
        silverCoins: 0.0,
    },
    isDataLoading: false,
    data : {
        language : Service.storageService.get('i18nextLng') || 'en'
    },
    error: '',
};

const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        loading: (state) => ({ ...state, isDataLoading: true }),
        fetchUserDataSuccess: ( state , action ) => {
            return {
                ...state,
                data: action.payload,
                isDataLoading: false
            }
        },
        fetchUserDataFailed: ( state , action ) => {
            return {
                ...state,
                error: action.payload.error,
                isDataLoading: false
            }
        },
        fetchUserWalletSuccess: (state, action) => {
            return {
                ...state,
                wallet: action.payload,
                isDataLoading: false
            };
        },
        fetchUserWalletFailed: (state, action) => {
            return {
                ...state,
                error: action.payload.error,
                isDataLoading: false
            };
        },
        updateUserLanguageSuccess: (state, action) => {
            return {
                ...state,
                data: {...state.data , langauge : action.payload},
                isDataLoading: false
            };
        },
        updateUserLanguageFailure: ( state , action ) => {
            return {
                ...state,
                error: action.payload.error,
                isDataLoading: false
            }
        },
    },
});

// Action creators are generated for each case reducer function
export const {
    loading,
    fetchUserWalletSuccess,
    fetchUserWalletFailed,
    fetchUserDataSuccess,
    fetchUserDataFailed,
    updateUserLanguageFailure
} = userSlice.actions;

export default userSlice.reducer;
