/* eslint-disable max-lines */
import { translate } from '../i18n';
import {
    thousandsSeparators,
    formatDate,
    formatDateLong,
} from '../helper/formaters';
import { formatStatus } from '../helper/general';
import { routeConstants } from '../../constants';

export const postMapper = (data) => {
    return data.map((post) => {
        let { esId, title, price, availableQuantity, thumbnail, createdAt } =
            post;
        createdAt = createdAt.substring(0, 10);
        return {
            [translate('post.table.postId')]: '#' + esId,
            [translate('post.table.thumbnail')]: thumbnail,
            [translate('post.table.title')]: title,
            [translate('post.table.availableQuantity')]: availableQuantity,
            [translate('post.table.price')]: price,
            [translate('post.table.createdAt')]: createdAt,
            [translate('post.table.status')]: '',
            [translate('post.table.actions')]: '',
        };
    });
};

export const returnsMapper = (data) => {
    return data.map((returnRequest) => {
        let { esId, post, items, createdAt , status } = returnRequest;
        let title = post.title;
        let thumbnail = post.thumbnail;
        createdAt = createdAt.substring(0, 10);
        return {
            [translate('return.table.returnId')]: '#' + esId,
            [translate('return.table.thumbnail')]: thumbnail,
            [translate('return.table.title')]: title,
            [translate('return.table.item')]: items.length,
            [translate('return.table.createdAt')]: createdAt,
            [translate('return.table.status')]: status,
        };
    });
};

export const orderMapper = (data) => {
    return data.map((order) => {
        let { esId, items, createdAt , status } = order;
        let title = items[0].post.title;
        let thumbnail = items[0].post.thumbnail;
        createdAt = createdAt.substring(0, 10);
        return {
            [translate('order.table.orderId')]: '#' + esId,
            [translate('order.table.thumbnail')]: thumbnail,
            [translate('order.table.title')]: title,
            [translate('order.table.createdAt')]: createdAt,
            [translate('order.table.status')]: status,
        };
    });
};

export const initPostActionsMapper = (item) => {
    const { status, sellingMethod, wholeEdit } = item;
    const hasBuyNow = sellingMethod === 'buyNow';
    const applyDiscount =
        item.discount && item.discount.discount_value > 0
            ? 'manageDiscount'
            : 'applyDiscount';

    switch (status) {
        case 'available': {
            let buttons = hasBuyNow
                ? [
                      'managePromotion',
                      applyDiscount,
                      'appendImageDescription',
                      'takeOffline',
                      'restock',
                      'share',
                      'clone',
                  ]
                : [
                      'managePromotion',
                      'appendImageDescription',
                      'takeOffline',
                      'share',
                      'clone',
                  ];

            if (item.auction && item.auction.biddingCount > 0) {
                buttons = ['managePromotion', 'share', 'clone'];
            }
            return {
                color: '#32c832',
                text: 'active',
                buttons,
            };
        }

        case 'standBy': {
            const buttons = [
                'repost',
                'repostWithModifications',
                'share',
                'clone',
                'archive'
            ];

            return {
                color: '#ffcc00',
                text: 'standBy',
                buttons,
            };
        }

        case 'sold': {
            let buttons = [];

            if (!wholeEdit) {
                if (hasBuyNow) {
                    buttons = [
                        'repost',
                        'repostWithModifications',
                        'share',
                        'clone',
                    ];
                } else {
                    buttons = ['repostWithModifications', 'share', 'clone'];
                }
            }

            return {
                color: '#32c832',
                text: 'sold',
                buttons,
            };
        }

        case 'pendingReview': {
            const buttons =['editPost','archive','share','clone'];

            return {
                color: 'grey',
                text: 'pendingReview',
                buttons,
            };
        }

        case 'underReview': {
            return {
                color: 'grey',
                text: 'underReview',
                buttons: [],
            };
        }

        case 'declined': {
            return {
                color: '#ffcc00',
                text: 'declined',
                buttons: ['editDeclinedPost', 'archive'],
            };
        }

        case 'deleted': {
            return {
                color: 'black',
                text: 'deleted',
                buttons: [],
            };
        }

        case 'rejected': {
            return {
                color: '#cd3c14',
                text: 'rejected',
                buttons: [],
            };
        }

        case 'waitingStartDate': {
            return {
                color: '#32c832',
                text: 'waitingStartDate',
                buttons: [],
            };
        }

        default: {
            return {
                color: '#32c832',
                text: 'active',
                buttons: [],
            };
        }
    }
};

export const promotionPlanMapper = (data) => {
    let arr = [];

    data &&
        data.map((element) => {
            let searchResult = (function () {
                if (element.features.includes('SearchResults')) {
                    return {
                        text: translate('post.promotionOptions.searchResults'),
                        color: 'text-black',
                    };
                } else if (!element.features.includes('SearchResults')) {
                    return {
                        text: translate('post.promotionOptions.searchResults'),
                        color: 'text-grey',
                    };
                }
            })();
            let categoryView = (function () {
                if (element.features.includes('CategoryView')) {
                    return {
                        text: translate('post.promotionOptions.categoryView'),
                        color: 'text-black',
                    };
                } else if (!element.features.includes('CategoryView')) {
                    return {
                        text: translate('post.promotionOptions.categoryView'),
                        color: 'text-grey',
                    };
                }
            })();
            let homePage = (function () {
                if (element.features.includes('HomePage')) {
                    return {
                        text: translate('post.promotionOptions.homePage'),
                        color: 'text-black',
                    };
                } else if (!element.features.includes('HomePage')) {
                    return {
                        text: translate('post.promotionOptions.homePage'),
                        color: 'text-grey',
                    };
                }
            })();
            let personalizedEmail = (function () {
                if (element.features.includes('PersonalizedEmail')) {
                    return {
                        text: translate(
                            'post.promotionOptions.personalizedEmail'
                        ),
                        color: 'text-black',
                    };
                } else if (!element.features.includes('PersonalizedEmail')) {
                    return {
                        text: translate(
                            'post.promotionOptions.personalizedEmail'
                        ),
                        color: 'text-grey',
                    };
                }
            })();
            let monthlySubscription = (function () {
                if (element.features.includes('MonthlySubscription')) {
                    return {
                        text: translate(
                            'post.promotionOptions.monthlySubscription'
                        ),
                        color: 'text-black',
                    };
                } else if (!element.features.includes('MonthlySubscription')) {
                    return {
                        text: translate(
                            'post.promotionOptions.monthlySubscription'
                        ),
                        color: 'text-grey',
                    };
                }
            })();
            let obj = {
                price: element.price,
                features: [
                    searchResult,
                    categoryView,
                    homePage,
                    personalizedEmail,
                    monthlySubscription,
                ],
                title: (function () {
                    switch (element.title) {
                        case 'NoPlan':
                            return translate('post.promotionPlans.noPlan');
                        case 'Silver':
                            return translate('post.promotionPlans.silver');
                        case 'Gold':
                            return translate('post.promotionPlans.gold');
                    }
                })(),
            };
            arr.push(obj);
        });
    return arr;
};

export const transactionMapper = (data) => {
    return data.map((trans) => {
        const item = {
            status: trans?.status,
            type: trans?.type,
            value: thousandsSeparators(trans?.value),
            date: formatDate(trans.createdAt),
        };
        if (item.type === 'Deposit') {
            item.id = '#' + trans?.depositRequest?.esId;
            // @TODO: route to proper page
            item.navTo = routeConstants.POST_DETAILS;
        } else if (item.type === 'Withdrawal') {
            item.id = '#' + trans?.withdrawalRequest?.esId;
            // @TODO: route to proper page
            item.navTo = routeConstants.POST_DETAILS;
        } else if (item.type === 'Return') {
            item.id = '#' + trans?.returnRequest?.esId;
            // @TODO: route to return detail page
            item.navTo = routeConstants.POST_DETAILS;
        } else if (item.type === 'Promotion') {
            item.id = '#' + trans?.post?.esId;
            // @TODO: route to post detail page
            item.navTo = routeConstants.POST_DETAILS;
        } else {
            item.id = '#' + trans?.order?.esId;
            item.navTo = `/order/${trans?.order.id}`;
        }
        return item;
    });
};

export const inquiriesMapper = (data) => {
    return data.map((inquiry) => {
        return {
            id: inquiry?.questions[0]?._id,
            title: inquiry?.title,
            question: inquiry?.questions[0]?.question,
            sender: {
                id: inquiry?.questions[0]?.user?._id,
                name: inquiry?.questions[0]?.user?.username,
                avatar: inquiry?.questions[0]?.user?.avatar,
            },
            answers:
                inquiry.questions[0]?.answer?.map((answer) => {
                    return {
                        content: {
                            images: answer?.images,
                            text: answer?.answer || '',
                        },
                        timeStamp: formatDateLong(answer?.answerDate),
                        authorId: answer?.user?._id,
                    };
                }) || [],
        };
    });
};

export const messagesMapper = ({ pendingReviewAnswers, question }, userId) => {
    const reviewedMessages = question?.answer?.map((msg) => {
        return {
            content: {
                images: msg?.images,
                text: msg?.answer || '',
            },
            authorId: msg?.user?._id,
            isMine: msg?.user?._id === userId,
            timeStamp: formatDateLong(msg?.answerDate),
            isReviewed: true,
            isAccepted: true,
        };
    });

    const pendingMessages = pendingReviewAnswers?.map((msg) => {
        return {
            content: {
                images: msg?.images,
                text: msg?.answer || '',
            },
            authorId: msg?.user,
            isMine: msg?.user === userId,
            timeStamp: formatDateLong(msg?.createdAt),
            isReviewed: msg?.adminReview?.isReviewed,
            isAccepted: msg?.adminReview?.isAccepted,
        };
    });

    return {
        title: question.post?.title,
        subtitle: question.question,
        messages: [...reviewedMessages, ...pendingMessages],
    };
};

export const orderDetailMapper = (data) => {
    return {
        esId: data.order.esId,
        id: data._id,
        soldPrice: data.order.soldPrice,
        totalPrice: data.order.totalPrice,
        date: formatDateLong(data.order.checkOutTime),
        posts: data.cards.map((card) => {
            return {
                id: card.post._id,
                esId: card.post.esId,
                title: card.post.title,
                thumbnail: card.post.thumbnail,
                quantity: card.quantity,
                status: formatStatus(card.sellerStatus),
                statusClass: card.sellerStatus,
                price: card.price,
                seller: {
                    id: card.post.seller._id,
                    phone: card.post.seller.phone.number,
                    username: card.post.seller.username,
                },
                sellerHistory: card.sellerHistory.map((item) => {
                    return {
                        ...item,
                        date: formatDate(item.date),
                    };
                }),
            };
        }),
    };
};

