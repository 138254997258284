import React from 'react';
import LightOverlay from './light-overlay.svg'

export default function FeedbackImg({ imgSrc }) {
  return (
    <div className='feedback-Img'>
        <div className='feedback-Img__light-overlay'>
            <img src={LightOverlay} alt='light overlay'/>
            <div className='top-img'>
              <img src={imgSrc} alt='feedback'/>
            </div>
        </div>
    </div>
  )
}
