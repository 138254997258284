import { combineReducers } from '@reduxjs/toolkit';
import authReducer from './authSlice';
import alertReducer from './alertSlice';
import postsReducer from './postsSlice';
import postReducer  from './postSlice';
import ordersReducer from './ordersSlice';
import returnsReducer from './returnsSlice';
import inquiriesReducer from './inquiriesSlice';
import transactionReducer from './transactionSlice';
import userReducer from './userSlice';
import categoryReducer from './categorySlice';
export const combinedReducer = combineReducers({
    auth: authReducer,
    alert: alertReducer,
    posts: postsReducer,
    post : postReducer,
    orders: ordersReducer,
    returns: returnsReducer,
    inquiries: inquiriesReducer,
    transactions: transactionReducer,
    user: userReducer,
    category:categoryReducer,
});
