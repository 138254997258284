import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import UploadFile from './uploadFile';
import DownloadFile from './downloadFile';
import BulkHistory from './bulkHistory';
import { fetchParentCategories } from '../../../redux/actions/categoryActions';
import { CustomTabs } from '../../organisms';
import { translate } from '../../../app/i18n';

export default function bulkUpload() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchParentCategories());
  }, []);

  return (
    <CustomTabs
      tabs={[
        { name: 'download', label: translate('bulk.downloadLable') },
        { name: 'upload', label: translate('bulk.uploadLable') },
        { name:'history', label:translate('bulk.historyLable') }
      ]}
    >
      <main className="bulk-uploads ">
        <div className="container grey-container">
          <DownloadFile hidden={false} />
        </div>
      </main>
      <main className="bulk-uploads ">
        <div className="container grey-container">
          <UploadFile />
        </div>
      </main>
      <main className="bulk-uploads ">
        <div className="container grey-container">
        <BulkHistory />
        </div>
      </main>
    </CustomTabs>
  );
}
