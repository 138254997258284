import { useEffect, useState } from 'react';
import { IoIosArrowBack , IoIosArrowForward } from 'react-icons/all';
import { useSelector } from 'react-redux';
import { getIconDirection } from '../../../app/helper/general';

export default function pagination(props) {
  const { counter, currentPage } = props.data;
  const [ pages, setPages ] = useState([]);
  const { language } = useSelector(state => state.user.data)
  const PaginationLimit = 3;
  let prevBtn = document.querySelector('.pagination__prev');
  let nextBtn = document.querySelector('.pagination__next');
  let dotsAfter = document.querySelector('.pagination__item--after');
  let dotsBefore = document.querySelector('.pagination__item--before');

  useEffect(() => {
    if(counter <= PaginationLimit){
      setPages([])
       for(let i = 1 ; i <= counter ; i++){
            setPages(prevPage => [ ...prevPage , i ])
       }
    }
    else{
        if (currentPage === 1) {// if first page selected
            setPages([ currentPage, currentPage + 1  , currentPage + 2  ]);
        } 
        else if (currentPage > 1 && currentPage < counter) {
          setPages([ currentPage - 1, currentPage, currentPage + 1 ]);
      }
    }
  }, [ counter , currentPage ]);

  useEffect(()=>{
    if (currentPage === 2) {
        hide(dotsBefore);
        hide(prevBtn);
    }
    else if(currentPage > 2) {
        show(dotsBefore);
        show(prevBtn)
    }
    // dots after pagination
    if (currentPage < counter) {
      show(dotsAfter);
      show(nextBtn);
    }
    if(currentPage === counter - 1  ) {
      hide(dotsAfter);
      hide(nextBtn)
    }
    if(counter === PaginationLimit){
      hide(dotsBefore);
      hide(prevBtn);
      hide(dotsAfter);
      hide(nextBtn)
    }
  },[ pages ])

  const handleOnClick = (clickedIndex) => {
    if( currentPage !== clickedIndex ){
      props.handleActivePage(clickedIndex);
    }
  };
  const handlePrev = () => {
      props.handleActivePage(currentPage - 1 >= 1 ? currentPage - 1 : 1);
  };
  const handleNext = () => {
      props.handleActivePage(currentPage + 1 <= counter ? currentPage + 1 : counter);
  };
  const show = (selector) => {
    selector && selector.classList.add('display-inline-block');
  }
  const hide = (selector) => {
    selector && selector.classList.remove('display-inline-block');
  }
  return (
    <>
    {counter > 1 && 
      <div className='pagination w-auto'>
        <button className='pagination__prev' onClick={() => handlePrev()}>
          <IoIosArrowBack className="bg-red" style={getIconDirection(language)}/>
        </button>
        <span className='pagination__item--before'>...</span>
        {pages?.map((element) => (
          <button
            onClick={() => handleOnClick(element)}
            className={`pagination__item ${
              element == currentPage ? 'pagination__item--selected' : ''
            }`}
            key={element}
          >
            {element}
          </button>
        ))}
        <span className='pagination__item--after'>...</span>
        <button className='pagination__next' onClick={() => handleNext()}>
          <IoIosArrowForward style={getIconDirection(language)}/>
        </button>
      </div> 
      }
   </>
  );
}
