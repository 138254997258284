import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { ErrorMessage, PasswordStrengthBar } from '../../atoms';
import 'react-phone-number-input/style.css';
import PhoneInput from 'react-phone-number-input';
import { translate } from '../../../app/i18n';
export default function TextField({
  register,
  error,
  className,
  endLabel,
  title,
  endLabelClass,
  endButton,
  handleEndButton,
  endButtonDisabled,
  barColor,
  showPasswordBar,
  type,
  ...props
}) {
  const CustomInput = type === 'textarea' ? 'textarea' : 'input';
  const [passwordShown, setPasswordShown] = useState(false);
  const handleTogglePassword = () => {
    setPasswordShown(!passwordShown);
  };
  const ToggleViewPassword = () => (
    <button
      className='text-field__toggle-password'
      onClick={handleTogglePassword}
      type='button'
    >
      <span
        className={
          passwordShown
            ? 'icon-item_viewed password-eye'
            : 'icon-hide_eye password-eye'
        }
      ></span>
    </button>
  );
  const PasswordStrengthText = () => {
    let strengthObj = { text: '', color: '' };
    if (barColor?.firstBarBackground === 'bg-error') {
      strengthObj.text = translate('passwordStrength.weakPassword');
      strengthObj.color = 'text-error';
    } else if (barColor?.firstBarBackground === 'bg-orange') {
      strengthObj.text = translate('passwordStrength.averagePassword');
      strengthObj.color = 'text-orange';
    } else if (barColor?.firstBarBackground === 'bg-secondary') {
      strengthObj.text = translate('passwordStrength.greatPassword');
      strengthObj.color = 'text-secondary';
    }
    return (
      <p
        className={`text-field__underline__strength-password ${strengthObj.color}`}
      >
        {strengthObj.text}
      </p>
    );
  };
  return (
    <div
      className={`text-field  text-field--${
        className && className
      } text-field--${type}`}
    >
      <fieldset className={`border ${error && 'fieldset-error'}`}>
        {title && <legend className='legend-placeholder'>{title}</legend>}
        {type === 'tel' ? (
          <PhoneInput
            international
            defaultCountry='EG'
            countryCallingCodeEditable={false}
            {...props}
          />
        ) : (
          <CustomInput
            aria-label={props.placeholder || props.name}
            className={`text-field__input`}
            type={
              type === 'password' ? (passwordShown ? 'text' : 'password') : type
            }
            draggable='false'
            {...(register && { ...register(props.name) })}
            {...props}
          />
        )}
        {type === 'password' && (
          <>
            {showPasswordBar && <PasswordStrengthBar {...barColor} />}
            <ToggleViewPassword />
          </>
        )}
        {endLabel && (
          <small
            className={`text-field__end-label ${
              endLabelClass && endLabelClass
            }`}
          >
            {endLabel}
          </small>
        )}
        {endButton && (
          <button
            className='text-field__end-button'
            disabled={endButtonDisabled}
            onClick={handleEndButton}
            type='button'
          >
            {endButton}
          </button>
        )}
      </fieldset>
      <div className='text-field__underline'>
        <ErrorMessage error={error} name={props.name} />
        <PasswordStrengthText />
      </div>
    </div>
  );
}

TextField.propTypes = {
  type: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  value: PropTypes.string,
  defaultValue: PropTypes.string,
  className: PropTypes.string,
  max: PropTypes.number,
  error: PropTypes.shape({
    message: PropTypes.string,
    ref: PropTypes.any,
    type: PropTypes.string,
  }),
  endLabel: PropTypes.string,
  title: PropTypes.string,
  barColor: PropTypes.object,
  register: PropTypes.func,
  onChange: PropTypes.func,
  endButtonDisabled: PropTypes.bool,
  autoComplete: PropTypes.oneOf(['on', 'username', 'email', 'new-password']),
};
