import PropTypes from 'prop-types';
import { formatDate } from '../../../app/helper/formaters';
import { formatStatus } from '../../../app/helper/general';
import translate from '../../../app/i18n/translate'; 

const Timeline = ({ data = [] }) => {
    return (
        <div>
            {data.length > 0 && (
                <div className='row'>
                    <div className='col'>
                        <div
                            className='timeline-steps aos-init aos-animate'
                        >
                            {data.map((item, i) => (
                                <div className='timeline-step' key={i}>
                                    <div className='timeline-content'>
                                        <div
                                            className={[
                                                'inner-circle',
                                                `inner-circle--${item.status.toLowerCase()}`,
                                            ].join(' ')}
                                        ></div>
                                        <p className='h6 mt-3 mb-0'>{translate('status.'+formatStatus(item.title))}</p>
                                        {item.subtitle && (
                                        <small className='text-muted ms-1 me-1 mb-0 mb-lg-0 text-darkgray'>
                                            {translate('status.'+formatStatus(item.subtitle))}
                                        </small>
                                        )}
                                        {item.date && (
                                        <small className='text-muted mb-0 mb-lg-0 text-darkgray'>
                                            {formatDate(item.date)}
                                        </small>
                                        )}
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

Timeline.propTypes = {
    data: PropTypes.array,
};

export default Timeline;
