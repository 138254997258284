import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Row, Col } from 'react-bootstrap';
import Skeleton from 'react-loading-skeleton';

import { SearchResult, Pagination } from '../../../atoms';
import { Searchbar , Sidebar  } from '../../../molecules';
import SingleTab from '../singleTab/singleTab';
import { translate } from '../../../../app/i18n';
import { getNumberOfPagesByLimit , isEmpty } from '../../../../app/helper/general';
import { orderMapper } from '../../../../app/mappers/mappers';
import {
    updateQueryObject,
    fetchOrders,
    clearFilters,
    fetchOrderFilters
} from '../../../../redux/actions/ordersAction';

export default function Orders() {

    const {
        data,
        numberOfResults,
        isDataLoading,
        queryObject,
        options,
    } = useSelector((state) => state.orders);
    const dispatch = useDispatch();

    useEffect(() => {
        Promise.all([
            dispatch(fetchOrderFilters()),
            dispatch(fetchOrders())
          ]);
    }, []);

    const updateData = (name, value) => {
        dispatch(updateQueryObject(name, value));
        dispatch(fetchOrders());
    };

    const reset = () => {
        dispatch(clearFilters());
        dispatch(fetchOrders());
    };

    return (
        <div>
            <Row>
                <Col lg={3}>
                {isEmpty(options.filters) ? 
                    <Skeleton style={{ height: '600px' }} /> :
                    <Sidebar
                        filters={options.filters}
                        queryObject={queryObject}
                        updateData={updateData}
                        reset={reset}
                    />
                }
                </Col>
                <Col>
                    <Searchbar
                        numberOfResults={numberOfResults}
                        sortOptions={options.sort}
                        queryObject={queryObject}
                        type={
                            numberOfResults === 1
                              ? translate('order.singular')
                              : translate('order.plural')
                          }
                        updateData={updateData}
                    />
                    <SearchResult
                        keyWord={queryObject.kw}
                        totalNumberOfResults={numberOfResults}
                    />
                     <SingleTab  allData={data} data={orderMapper(data)} type="Order" isDataLoading={isDataLoading}/>
                    <Pagination
                        handleActivePage={(page) => updateData('page', page)}
                        data={{
                            counter: getNumberOfPagesByLimit('orders'),
                            currentPage: queryObject.page,
                        }}
                    />
                </Col>
            </Row>
        </div>
    );
}
