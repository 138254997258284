import { useState } from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import { TextField } from "../../../molecules";
import { Button } from "../../../atoms";
import { translate } from "../../../../app/i18n";
import { validationInput } from "../../../../app/helper/custom-validations";
import Loader from "react-loader-spinner";
import { AuthWrapper } from "../../../templates";
import { Link } from "react-router-dom";
import { routeConstants } from "../../../../constants";
import { SlogansAndTitle } from "../../../organisms";
export default function LoginForm({ submit }) {
  const { isDataLoading } = useSelector((state) => state.auth);
  const [form, setForm] = useState({
    phone: "",
    password: "",
    code: "",
  });

  const [errors, setErrors] = useState({
    phone: "",
    password: "",
    code: "",
  });
  const schema = {
    phone: {
      isRequired: true,
    },
    password: {
      min: 8,
      isRequired: true,
    },
    code: {
      min: 6,
      isRequired: true,
    },
  };

  const handleChangeInput = (event, props, name) => {
    let value = event.target.value.trim();
    setForm({ ...form, [props]: value });
    let error =
      schema[props] &&
      validationInput(
        value,
        translate(`login.${name}`),
        schema[props].min,
        schema[props].max,
        schema[props].isRequired
      );
    setErrors({ ...errors, [props]: error ? error : "" });
  };
  const onSubmitHandler = (e) => {
    e.preventDefault();
    const errorsObj = {
      phone: "",
      password: "",
      code: "",
    };

    if (!form.phone)
      errorsObj.phone = `${translate(`login.mobileOrUsername`)} ${translate(
        `login.isRequired`
      )}`;

    if (!form.password)
      errorsObj.password = `${translate(`login.password`)} ${translate(
        `login.isRequired`
      )}`;

    if (!form.code)
      errorsObj.code = `${translate(`login.code`)} ${translate(
        `login.isRequired`
      )}`;

    if (form.code && form.code != "10a64W")
      errorsObj.code = `${translate(`login.code`)} ${translate(
        `login.isIncorrect`
      )}`;
    setErrors(errorsObj);

    if (
      !errors.phone &&
      !errors.password &&
      !errors.code &&
      form.password &&
      form.phone &&
      form.code === "10a64W"
    )
      submit(form.phone, form.password);
  };

  return (
    <AuthWrapper>
      <SlogansAndTitle title={translate("login.growYourBusiness")} />
      <div className='auth-paper paper-login-padding'>
        <div className='login-form'>
          <div className='login-form__header'>
            <h3>{translate("login.getStarted")}</h3>
          </div>
          <form onSubmit={onSubmitHandler}>
            <TextField
              title={translate("login.mobileOrUsername")}
              name='phone'
              type='text'
              placeholder={translate("login.enterMobileUsername")}
              error={errors["phone"]}
              value={form.phone}
              onChange={(e) =>
                handleChangeInput(e, "phone", "mobileOrUsername")
              }
            />
            <TextField
              title={translate("login.password")}
              name='password'
              showPasswordBar={false}
              type='password'
              placeholder={translate("login.enterYourPassword")}
              error={errors["password"]}
              value={form.password}
              onChange={(e) => handleChangeInput(e, "password", "password")}
            />
            <TextField
              title={translate("login.code")}
              name='code'
              type='text'
              placeholder={translate("login.enterLoginCode")}
              error={errors["code"]}
              value={form.code}
              maxLength={6}
              onChange={(e) => handleChangeInput(e, "code", "code")}
            />
            <div className='submit-button'>
              <Button
                fullWidth
                className='button  button--medium '
                type='submit'
              >
                {isDataLoading ? (
                  <Loader
                    type='TailSpin'
                    className='loader-icon'
                    width={20}
                    height={20}
                    color='white'
                  />
                ) : (
                  translate("login.signIn")
                )}
              </Button>
            </div>
          </form>
          <div className='login-form__footer'>
            <p className='login-form__footer__need-account'>
              {translate("login.dontHaveAccount")}
              <Link to={routeConstants.REGISTER}>
                <span className='login-form__footer__signup link-no-underline link-transition'>
                  {translate("login.signUp")}{" "}
                </span>
              </Link>
            </p>
          </div>
        </div>
      </div>
    </AuthWrapper>
  );
}

LoginForm.propTypes = {
  submit: PropTypes.func,
};
