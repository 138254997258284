import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import Loader from 'react-loader-spinner';
import { Button } from '../../atoms';
import DropDown from './dropDown';
import services from '../../../services';
import {
  fetchChildCategories,
  setSubCategory,
} from '../../../redux/actions/categoryActions';
import { translate } from '../../../app/i18n';

export default function DownloadFile({ hidden }) {
  const [selectedCategory, setSelectedCategory] = useState();
  const [selectedSubCategory, setSelectedSubCategory] = useState();
  const [showChildSection, setShowChildSection] = useState(false);
  const [downloadLink, setDownloadLink] = useState();
  const [fileName, setFileName] = useState();
  const [btnClicked, setBtnClicked] = useState(false);

  const dispatch = useDispatch();
  const { parentCategory, isLoading, subCategory } = useSelector(
    (state) => state.category
  );

  const { language } = useSelector((state) => state.user?.data);
  const lang = language === 'ar' ? 'arabic' : 'english';
  const onSelectCategory = (e, id) => {
    setShowChildSection(true);
    dispatch(fetchChildCategories(id));
    setSelectedCategory(id);
    setSelectedSubCategory(null);
  };
  const onSelectSubCategory = (e, id) => {
    setSelectedSubCategory(id);
    dispatch(setSubCategory(id));
  };
  const onClickDownload = async () => {
    setBtnClicked(true);
    const { link , fileName } = await services.categoryService.getBulkUploads({
      category: selectedSubCategory,
      parentCategory:selectedCategory
    });
    setDownloadLink(link);
    setFileName(fileName);
  };
  return (
    <div className="bulk-uploads__line-bottom">
      <div className="bulk-uploads__content">
        <div>
          <h3>{translate('bulk.selectCategory')}</h3>
          <DropDown
            lang={lang}
            label={translate('bulk.category')}
            name="categories"
            onSelect={onSelectCategory}
            selected={selectedCategory}
            options={parentCategory}
            loading={isLoading}
          />
          {showChildSection && (
            <DropDown
              lang={lang}
              label={translate('bulk.subCategory')}
              name="subCategories"
              onSelect={onSelectSubCategory}
              selected={selectedSubCategory}
              options={subCategory}
              loading={isLoading}
            />
          )}
        </div>
        {selectedSubCategory && !hidden && (
          <div className="bulk-uploads__download">
            <Button size="small" onClick={onClickDownload} type="submit">
              {translate('bulk.downloadButton')}
            </Button>
          </div>
        )}
      </div>
      <div className="bulk-uploads__link">
        {!hidden && downloadLink ? (
          <a href={downloadLink}>{fileName}</a>
        ) : (
          btnClicked && (
            <Loader
              type="TailSpin"
              color="#1bb0b8"
              secondaryColor="#123f87"
              height={20}
              width={20}
              className="d-inline-block"
            />
          )
        )}
      </div>
    </div>
  );
}
DownloadFile.propTypes = {
  hidden: PropTypes.bool,
};
