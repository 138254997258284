import React, { useEffect, useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { Button, CustomSwitch } from "../../atoms";
import { Info, TextField } from "../../molecules";
import services from "../../../services";
import Loader from "react-loader-spinner";
import { checkUserPlatform } from "../../../app/helper/general";
import { AuthWrapper } from "../../templates";
import {
  validatePhoneNumber,
  validationInput,
  passwordStrengthBar,
} from "../../../app/helper/custom-validations";
import { routeConstants } from "../../../constants";
import { translate } from "../../../app/i18n";
import { Link } from "react-router-dom";
import { useQuery } from "../../../app/helper/custom-hooks";
import config from "../../../config";
import { SlogansAndTitle } from "../../organisms";

export default function RegistrationForm({
  getMobileNumber,
  handleShowStep,
  verified = services.storageService.get("isMobileNumberVerified") === "true"
    ? true
    : false,
  isNumberVerified,
  getStepOneDate,
  ...props
}) {
  const query = useQuery();
  const token = query.get("token");
  const formData = services.storageService.get("registerStepOne");
  const [isLoading, setIsLoading] = useState(
    formData && formData.isLoading ? formData.isLoading : false
  );
  const [passwordBarColor, setPasswordBarColor] = useState(
    formData && formData.passwordBarColor ? formData.passwordBarColor : false
  );
  const [isValid, setIsValid] = useState(
    formData && formData.isValid ? formData.isValid : false
  );
  const [validUserName, setValidUserName] = useState(false);
  const [verfiedRecaptcha, setVerfiedRecaptcha] = useState(false);
  const [submissionFailed, setSubmissionFailed] = useState(false);
  const [submissionSuccess, setSubmissionSuccess] = useState(false);
  const [isInvitationCodeChecked, setIsInvitationCodeChecked] = useState(
    formData && formData.isInvitationCodeChecked
      ? formData.isInvitationCodeChecked
      : false
  );
  const [form, setForm] = useState({
    username: "",
    password: "",
    markStore: false,
    phone: "",
    invitationToken: "",
    ...formData?.form,
  });
  const [errors, setErrors] = useState({
    username: "",
    password: "",
    phone: "",
    submit: "",
    ...formData?.errors,
  });
  const schema = {
    username: {
      min: 3,
      max: 50,
      isRequired: true,
    },
    password: {
      min: 8,
      isRequired: true,
    },
  };
  useEffect(() => {
    if (token) {
      setIsInvitationCodeChecked(true);
      setForm({ ...form, invitationToken: token });
    }
  }, [token]);

  const handleSwitchInvitationCode = (checked) => {
    setIsInvitationCodeChecked(checked);
    setForm({
      ...form,
      invitationToken: checked === true && token ? token : "",
    });
  };
  const handleChangeInput = (event, props, func) => {
    let value = event.target.value.trim();
    func && func(event);
    setForm({ ...form, [props]: value });
    let error =
      schema[props] &&
      validationInput(
        value,
        translate(`registration.${props}`),
        schema[props].min,
        schema[props].max,
        schema[props].isRequired
      );
    setErrors({ ...errors, [props]: error ? error : "", submit: "" });
  };
  const handleOnChangePhone = (value) => {
    isNumberVerified(false);
    setErrors({
      ...errors,
      submit: "",
      phone: "",
    });
    const { isValid, phoneNumber } = validatePhoneNumber(value);
    setIsValid(value ? isValid : false);
    setForm({ ...form, phone: phoneNumber });
  };
  const handleOnChangeName = (e) => {
    let value = e.target.value;
    setErrors({
      username: "",
      ...errors,
    });
    setValidUserName(false);
    if (value.length > 2) {
      setIsLoading(true);
      getUniqueUserNameDelay(value);
    }
  };
  const getUniqueUserNameDelay = (value) => {
    setTimeout(() => {
      services.registrationService
        .getUniqueUserName(value)
        .then(() => {
          setIsLoading(false);
          !isLoading && setValidUserName(true);
        })
        .catch((e) => {
          setErrors({
            ...errors,
            username: e.message
              ? e.message
              : translate("httpErrorMsg.somethingWrong"),
          });
          setIsLoading(false);
          setValidUserName(false);
        });
    }, 200);
  };
  const handleOnChangePassword = (event) => {
    setErrors({
      ...errors,
      submit: "",
      password: "",
    });
    setPasswordBarColor(passwordStrengthBar(event.target.value));
  };
  const handleRegisteredBusiness = (checked) => {
    return;
  };
  const handleVerified = () => {
    services.registrationService
      .postMobileNumberVerifyAndOtp({
        phone: `${form.phone}`,
        service: "primary",
      })
      .then(() => {
        setErrors({
          phone: "",
          ...errors,
        });
        getMobileNumber(form.phone);
        getStepOneDate({
          form,
          passwordBarColor,
          isValid,
          errors,
          isInvitationCodeChecked,
        });
        props.goToStep(2);
      })
      .catch((e) => {
        setErrors({
          ...errors,
          phone: e.message
            ? e.message
            : translate("httpErrorMsg.somethingWrong"),
        });
      });
  };
  const handleFormSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    if (!isFormDisabled()) {
      let data = {
        userObj: { ...form, markStore: true, phone: { number: form.phone } },
      };
      services.registrationService
        .postRegistrationData(data)
        .then(() => {
          services.storageService.set("isMobileNumberVerified", "");
          services.storageService.set("registerStepOne", "");
          setSubmissionFailed(false);
          setSubmissionSuccess(true);
          setIsLoading(false);
        })
        .catch((e) => {
          setErrors({
            ...errors,
            submit: e.message
              ? e.message
              : translate("httpErrorMsg.somethingWrong"),
          });
          setSubmissionSuccess(false);
          setSubmissionFailed(true);
          setIsLoading(false);
        });
    }
  };

  const onVerifyRecaptchaHandler = () => {
    setVerfiedRecaptcha(true);
  };

  const onRecaptchaExpired = () => {
    setVerfiedRecaptcha(false);
  };

  const isFormDisabled = () => {
    if (
      verified &&
      !errors.username &&
      !errors.password &&
      !errors.phone &&
      !isLoading &&
      verfiedRecaptcha &&
      !submissionSuccess &&
      form.username &&
      form.password &&
      form.phone
    ) {
      return false;
    } else {
      return true;
    }
  };
  const Title = () => <>{translate("registration.opportunityKnocks")} </>;
  const RegistrationFooter = () => (
    <div className='registration-form__footer'>
      <p className='registration-form__footer__have-account'>
        {translate("registration.alreadyHaveAccount")}
        <Link to={routeConstants.LOGIN}>
          <span className='registration-form__footer__have-account__signin link-no-underline link-transition'>
            {translate("registration.signIn")}{" "}
          </span>
        </Link>
      </p>
      <p className='registration-form__footer__agree-on-terms'>
        {translate("login.byContinuing")}
        <a
          href={config.PROJECT_X_PRIVACY_POLICY}
          target='_blank'
          className='text-secondary privacy-policy link-no-underline link-transition'
          rel='noreferrer'
        >
          {translate("general.privacyPolicy")}
        </a>
        {translate("login.and")}
        <a
          className='text-secondary terms-of-use link-no-underline link-transition'
          href={config.PROJECT_X_TERMS}
          target='_blank'
          rel='noreferrer'
        >
          {translate("login.termsOfUse")}
        </a>
      </p>
    </div>
  );
  const SubmitButton = () => (
    <div className='submit-button'>
      <Button
        type='submit'
        fullWidth
        className='bg-secondary text-white w-100 text-uppercase'
        disabled={isFormDisabled()}
      >
        {isLoading && validUserName ? (
          <Loader
            type='TailSpin'
            className='loader-icon'
            width={20}
            height={20}
            color='#123f87'
          />
        ) : (
          translate("registration.joinUs")
        )}
      </Button>
    </div>
  );
  return (
    <AuthWrapper>
      <SlogansAndTitle title={translate("registration.growYourBusiness")} />
      <div className='auth-paper paper-reg-padding'>
        <div className='registration-form'>
          <div className='registration-form__header'>
            <Info
              showDescription={false}
              className={"info-reg"}
              title={<Title />}
            />
          </div>
          <form onSubmit={handleFormSubmit}>
            <TextField
              type='tel'
              title={translate("registration.phoneNumber")}
              name='phone'
              placeholder='Phone Number'
              value={form.phone}
              onChange={handleOnChangePhone}
              endButton={!verified && translate("registration.verify")}
              endLabel={
                verified && (
                  <span className='icon-Verified endLabel-icon text-secondary'></span>
                )
              }
              endButtonDisabled={!isValid}
              handleEndButton={handleVerified}
              error={errors["phone"]}
            />
            <TextField
              type='text'
              title={translate("registration.username")}
              name='name'
              autoComplete='username'
              placeholder={translate("registration.enterUsername")}
              value={form.username}
              onChange={(e) =>
                handleChangeInput(e, "username", handleOnChangeName)
              }
              endLabel={
                isLoading && !validUserName ? (
                  <Loader
                    type='TailSpin'
                    className='loader-icon'
                    width={20}
                    height={20}
                    color='#00b0b8'
                    secondaryColor='#123f87'
                  />
                ) : (
                  form.username.length > 2 &&
                  validUserName && (
                    <span className='icon-Verified  endLabel-icon text-secondary'></span>
                  )
                )
              }
              endButtonDisabled={!validUserName}
              error={errors["username"]}
            />
            <TextField
              title={translate("registration.password")}
              name='password'
              type='password'
              autoComplete='new-password'
              showPasswordBar={true}
              placeholder={translate("registration.passwordCharacters")}
              value={form.password}
              onChange={(e) =>
                handleChangeInput(e, "password", handleOnChangePassword)
              }
              error={errors["password"]}
              barColor={passwordBarColor}
            />
            <CustomSwitch
              name='verification-code'
              onChange={handleSwitchInvitationCode}
              className={"invitation-field"}
              isChecked={isInvitationCodeChecked}
              label={<>{translate("registration.invitationCode")}</>}
            />
            {isInvitationCodeChecked && (
              <TextField
                type='text'
                title={translate("registration.invitationCode")}
                name='invitationToken'
                placeholder='INV010assPd'
                value={form.invitationToken}
                onChange={(e) => handleChangeInput(e, "invitationToken")}
                endLabel={translate("general.optional")}
                endLabelClass={"optional-label"}
              />
            )}
            <CustomSwitch
              name='registered-business'
              onChange={handleRegisteredBusiness}
              isChecked={true}
              className={"registered-business"}
              label={<>{translate("registration.registeredBusiness")}</>}
            />
            {submissionSuccess && !isLoading && (
              <span className='submission-success'>
                {translate("registration.successSubmission")}
              </span>
            )}
            <div className='recaptcha-reg'>
              <ReCAPTCHA
                sitekey={config.RECAPTCHA_SITE_KEY}
                onChange={onVerifyRecaptchaHandler}
                onExpired={onRecaptchaExpired}
              />
            </div>

            <SubmitButton />
            {submissionFailed && !isLoading && (
              <span className='submission-error'>{errors.submit}</span>
            )}
          </form>
          <RegistrationFooter />
        </div>
      </div>
    </AuthWrapper>
  );
}
