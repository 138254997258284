import { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { MdOutlineContentCopy as CopyIcon } from 'react-icons/md';

export default function ModalContentShare() {
    const { sharedLink } = useSelector((state) => state.post);

    const [copySuccess, setCopySuccess] = useState('');
    const textAreaRef = useRef(null);

    function copyToClipboard(e) {
        e.preventDefault();
        textAreaRef.current.select();
        document.execCommand('copy');
        // This is just personal preference.
        // I prefer to not show the whole text area selected.
        e.target.focus();
        setCopySuccess('Copied!');
    }

    return (
        <div className='copy-link'>
            <form className='copy-link__form'>
                <input
                    className='copy-link__input'
                    ref={textAreaRef}
                    value={sharedLink}
                />
                <button
                    onClick={copyToClipboard}
                    className={[
                        'copy-link__button',
                        `${copySuccess ?  'copy-link__button--success' : ''}`,
                    ].join(' ')}
                >
                    {copySuccess ? copySuccess : <CopyIcon />}
                </button>
            </form>
        </div>
    );
}

ModalContentShare.propTypes = {
    title: PropTypes.string,
    price: PropTypes.number,
    discount: PropTypes.object,
    callBackDiscount: PropTypes.func,
};
