import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

const LinkButton = ({ children, to='/' }) => {
    return <Link to={to}>{children}</Link>;
};

LinkButton.propTypes = {
    children: PropTypes.node,
    to: PropTypes.string,
};

export default LinkButton;
