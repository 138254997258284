import { API } from '../../constants';
import jwtDecode from 'jwt-decode';

export default function AuthService(httpClient, storageService) {
    const login = async (phone, password) => {
        const data = await httpClient.post(API.LOGIN, {
            phone,
            password,
        });

        storageService.set('user', data);
        return data;
    };

    const getLocalRefreshToken = () => {
        const user = storageService.get('user');
        return user?.refreshToken;
    };

    const getLocalAccessToken = () => {
        const user = storageService.get('user');
        return user?.token;
    };

    const getCurrentUser = () => {
        return storageService.get('user');
    };

    const updateLocalAccessToken = (token) => {
        const user = storageService.get('user');
        user.token = token;
        storageService.set('user', user);
    };

    const checkAuth = async () => {
        const user = getCurrentUser();
        let token = user?.token;

        if (!token) return Promise.reject('httpErrorMsg.sessionExpired');
        else {
            const decoded = jwtDecode(token, { complete: true });
            const isExpired = decoded.exp < new Date().getTime() / 1000;

            if (isExpired) {
                const data = await httpClient.post(API.REFRESH_TOKEN, {
                    refreshToken: getLocalRefreshToken(),
                });

                token = data.token;
                updateLocalAccessToken(token);
            }

            return Promise.resolve({ ...user, token });
        }
    };

    const removeLocalUser = async () => {
        storageService.remove('user');
    };

    return Object.freeze({
        login,
        getLocalRefreshToken,
        getLocalAccessToken,
        updateLocalAccessToken,
        getCurrentUser,
        checkAuth,
        removeLocalUser,
    });
}
