import { formatDateLong } from '../../app/helper/formaters';
import { inquiriesMapper, messagesMapper } from '../../app/mappers/mappers';
import services from '../../services';
import {
    loading,
    fetchUserInquiriesSuccess,
    fetchUserInquiriesFailed,
    addAnswerFailed,
    addAnswerSuccess,
    fetchMessagesSuccess,
    fetchMessagesFailed,
} from '../slices/inquiriesSlice';

export const fetchUserInquries = () => async (dispatch) => {
    try {
        dispatch(loading());
        const data = await services.inquiryService.getUserInquiries();
        dispatch(fetchUserInquiriesSuccess(inquiriesMapper(data)));
    } catch (error) {
        dispatch(fetchUserInquiriesFailed({ error: error }));
    }
};

export const addAnswer = (data, type, questionId) => async (dispatch) => {
    let answer;
    try {
        if (type === 'text') {
            const res = await services.inquiryService.postInquiryAnswer(data);
            answer = {
                timeStamp: res.answer.createdAt,
                authorId: res.answer.user,
                isMine: true,
                content: {
                    text: res.answer.answer,
                    images: [],
                },
            };
        } else if (type === 'image') {
            const res = await services.inquiryService.postInquiryImage(data);
            answer = {
                timeStamp: formatDateLong(res.answer.createdAt),
                authorId: res.answer.user,
                isMine: true,
                content: {
                    text: '',
                    images: [...res.answer.images],
                },
            };
        }
        dispatch(addAnswerSuccess({ answer, questionId }));
    } catch (error) {
        dispatch(addAnswerFailed({ error: error }));
    }
};

export const fetchMessages = (questionId, userId) => async (dispatch) => {
    try {
        const data = await services.inquiryService.getInquiryMessages(
            questionId
        );
        dispatch(fetchMessagesSuccess(messagesMapper(data, userId)));
    } catch (error) {
        dispatch(fetchMessagesFailed(error));
    }
};
