import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    data: [],
    options: {
        filters: {},
        sort: [],
    },
    queryObject: {
        sort: 'Urgent',
        page: 1,
    },
    isDataLoading: false,
    error: '',
};

export const returnsSlice = createSlice({
    name: 'returns',
    initialState,
    reducers: {
        loading: (state) => {
            return { ...state, isDataLoading: true };
        },
        fetchReturnsSuccess: (state, action) => {
            return {
                ...state,
                data: action.payload.returnRequests,
                numberOfResults: action.payload.numberOfResults,
                isDataLoading: false,
            };
        },
        fetchReturnsFailure: (state, action) => {
            return { ...state, isDataLoading: false, error: action.payload.error };
        },
        fetchReturnFiltersSuccess: (state, action) => {
            return { ...state, options: action.payload, isDataLoading: false };
        },
        fetchReturnFiltersFailure: (state, action) => {
            return { ...state, isDataLoading: false, error: action.payload.error };
        },
        seteQueryObject: (state, action) => {
            if (action.payload.name === 'page')
                return {
                    ...state,
                    queryObject: {
                        ...state.queryObject,
                        [action.payload.name]: action.payload.value,
                    },
                };
            return {
                ...state,
                queryObject: {
                    ...state.queryObject,
                    [action.payload.name]: action.payload.value,
                    page: 1,
                },
            };
        },
        resetQueryObject: (state) => {
            return {
                ...state,
                queryObject: { ...initialState.queryObject },
            };
        },
    },
});

export const {
    loading,
    fetchReturnsSuccess,
    fetchReturnsFailure,
    fetchReturnFiltersFailure,
    fetchReturnFiltersSuccess,
    seteQueryObject,
    resetQueryObject,
} = returnsSlice.actions;

export default returnsSlice.reducer;
