import { useState } from 'react';
import PropTypes from 'prop-types';
import { TextField } from '../../../../../molecules';
import { translate } from '../../../../../../app/i18n';

export default function ModalContentQuantity({
  type,
  postTitle,
  postQuantity,
  callBackQuantity,
  textLabel,
  sellingMethod,
  postPrice
}) {
  const [ state , setState ] = useState({
    quantity : type === 'repost' ? postQuantity : '',
    price : postPrice
  })

  const handleOnChange = props => event => {
    if (event.target.value > 0) {
      setState({ ...state , [props] : parseInt(event.target.value) })
      callBackQuantity({ ...state , [props]: parseInt(event.target.value) });
    }
  };

  return (
    <div>
      <div>
        <h5>{postTitle}</h5>
      </div>
      <label>{textLabel}</label>
      {type === 'repost' ? (
        <>
          <TextField
            defaultValue={postQuantity}
            value={state.quantity}
            type='number'
            handleOnChange={handleOnChange('quantity')}
          />
        {(sellingMethod === 'buyNow' ||
          sellingMethod === 'auctionWithMinPriceAndBuyNow' ||
          sellingMethod === 'auctionWithoutMinPriceAndBuyNow')  &&
           <div className='mt-2'>
            <label>{translate('post.table.price')}</label>
            <TextField
              defaultValue={postPrice}
              value={state.price}
              type='number'
              handleOnChange={handleOnChange('price')}
            />
          </div>
        }
        <small>{state.quantity}</small>
        </>
      ) : (
        <>
          <TextField
            type='number'
            defaultValue={postQuantity}
            value={state.quantity}
            handleOnChange={handleOnChange('quantity')}
          />
          <small>{Number(postQuantity) + Number(state.quantity)}</small>
        </>
      )}
    </div>
  );
}

ModalContentQuantity.propTypes = {
  type: PropTypes.string,
  postTitle: PropTypes.string,
  textLabel: PropTypes.string,
  postQuantity: PropTypes.number,
  callBackQuantity: PropTypes.func,
  sellingMethod : PropTypes.string,
  postPrice : PropTypes.number,
};
