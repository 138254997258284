import { API } from '../constants';

export default function RegistrationService(httpClient) {

    const postMobileNumberVerifyAndOtp = (body) => {
        return httpClient.post(`${API.POST_MOBILE_NUMBER_VERIFY_AND_OTP}`,body);
    };
    const postResendOtp = (body) => {
        return httpClient.post(`${API.POST_RESEND_OTP}`,body);
    };
    const getUniqueUserName = (userName) => {
        return httpClient.get(`${API.GET_UNIQUE_USER_NAME}${userName}`);
    };
    const postRegistrationData = (body)=>{
        return httpClient.post(`${API.POST_REGISTRATION_DATA}`,body); 
    };
    const postOtpSubmit = (body)=>{
        return httpClient.post(`${API.POST_OTP_SUBMIT}`,body); 
    }
    return { postMobileNumberVerifyAndOtp,getUniqueUserName,postRegistrationData,postOtpSubmit , postResendOtp};
}