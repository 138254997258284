// Paths
import { routeConstants } from '../constants/route.constants';
// components
import {
  Home,
  Inquiries,
  Transactions,
  PostDetails,
  OrderDetails,
  ReturnDetails,
  BulkUpload

} from '../components/pages';
const PrivateRoutes = [
  {
    path: routeConstants.TRANSACTIONS,
    component: <Transactions />,
  },
  {
    path: routeConstants.HOME,
    component: <Home />,
  },
  {
    path: routeConstants.POST_DETAILS,
    component: <PostDetails />,
  },
  {
    path: routeConstants.INQUIRIES,
    component: <Inquiries />,
  },
  {
    path: routeConstants.ORDER_DETAILS,
    component: <OrderDetails />,
  },
  {
    path: routeConstants.RETURN_DETAILS,
    component: <ReturnDetails />,
  },
  {
    path: routeConstants.BULK_UPLOAD,
    component: <BulkUpload />,
  }
];

export default PrivateRoutes;
