import { configureStore } from '@reduxjs/toolkit';
import { combinedReducer } from './slices';

const rootReducer = (state, action) => {
  if (action.type === 'auth/destroySession') {
    state = undefined;
  }
  return combinedReducer(state, action);
};
export default configureStore({
  reducer: rootReducer,
});
