import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    data: [],
    isDataLoading: false,
    messages: {
        messages: [],
        title: '',
        subtitle: '',
    },
    error: '',
};

const inquirySlice = createSlice({
    name: 'inquiry',
    initialState,
    reducers: {
        loading: (state) => ({ ...state, isDataLoading: true }),
        fetchUserInquiriesSuccess: (state, action) => {
            return {
                ...state,
                data: action.payload,
                isDataLoading: false,
            };
        },
        fetchUserInquiriesFailed: (state, action) => {
            return {
                ...state,
                error: action.payload.error,
                isDataLoading: false,
            };
        },
        addAnswerSuccess: (state, action) => {
            return {
                ...state,
                messages: {
                    ...state.messages,
                    messages: [
                        ...state.messages.messages,
                        action.payload.answer,
                    ],
                },
            };
        },
        addAnswerFailed: (state, action) => {
            return {
                ...state,
                error: action.payload.error,
                isDataLoading: false,
            };
        },
        fetchMessagesSuccess: (state, action) => {
            return {
                ...state,
                messages: action.payload,
                isDataLoading: false,
            };
        },
        fetchMessagesFailed: (state, action) => {
            return {
                ...state,
                error: action.payload,
                isDataLoading: false,
            };
        },
    },
});

export const {
    loading,
    fetchUserInquiriesSuccess,
    fetchUserInquiriesFailed,
    addAnswerSuccess,
    addAnswerFailed,
    fetchMessagesSuccess,
    fetchMessagesFailed,
} = inquirySlice.actions;

export default inquirySlice.reducer;
