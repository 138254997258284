import React from "react";
import sloganImg from "./keyPoint.svg";
import { translate } from "../../../app/i18n";
import { Slogan } from "../../molecules";
import secured from "../../../assets/icons/secured.svg";
import feedback from "../../../assets/icons/feedback.svg";
import product from "../../../assets/icons/product.svg";
import promoted from "../../../assets/icons/promoted.svg";
import money from "../../../assets/icons/money.svg";
import delivery from "../../../assets/icons/delivery.svg";

const SlogansAndTitle = ({ title }) => {
  return (
    <div className='auth-wrapper__title-with-slogans'>
      <div className='slogans-title'>
        <h2 className='slogans-title__big-title'>{title}</h2>
        <div className='slogans-title__slogans-tablets  d-tablet'>
          <div className='slogans-title__slogans-tablets__horizontal-slogans'>
            <Slogan
              img={secured}
              title={translate("general.secure")}
              description={translate("login.secured")}
            />
            <Slogan
              img={money}
              title={translate("general.secure")}
              description={translate("login.moneyCollection")}
            />
          </div>

          <div className='slogans-title__slogans-tablets__horizontal-slogans'>
            <Slogan
              img={feedback}
              title={translate("general.secure")}
              description={translate("login.feedback")}
            />
            <Slogan
              img={product}
              title={translate("general.secure")}
              description={translate("login.product")}
            />
          </div>
          <div className='slogans-title__slogans-tablets__horizontal-slogans'>
            <Slogan
              img={promoted}
              title={translate("general.secure")}
              description={translate("login.promotedListing")}
            />
            <Slogan
              img={delivery}
              title={translate("general.secure")}
              description={translate("login.delivery")}
            />
          </div>
        </div>
        <div className='slogans-title__web-mobile-views'>
          <Slogan
            img={secured}
            title={translate("general.secure")}
            description={translate("login.secured")}
          />
          <Slogan
            img={money}
            title={translate("general.secure")}
            description={translate("login.moneyCollection")}
          />
          <Slogan img={feedback} description={translate("login.feedback")} />
          <Slogan img={product} description={translate("login.product")} />
          <Slogan
            img={promoted}
            title={translate("general.secure")}
            description={translate("login.promotedListing")}
          />
          <Slogan
            img={delivery}
            title={translate("general.secure")}
            description={translate("login.delivery")}
          />
        </div>
      </div>
    </div>
  );
};

export default React.memo(SlogansAndTitle);
