import PropTypes from 'prop-types';
import { translate } from '../../../app/i18n';

import { Modal, Button } from 'react-bootstrap';

export default function CustomModal({
  title,
  show,
  content,
  handleClose,
  handleSubmit,
}) {
  return (
    <Modal
      show={show}
      onHide={handleClose}
      backdrop='static'
      keyboard={false}
      size='lg'
    >
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{content}</Modal.Body>
      <Modal.Footer>
        <Button variant='secondary' onClick={handleClose}>
          {translate('post.actions.cancel')}
        </Button>
        <Button onClick={handleSubmit} variant='primary'>
          {translate('post.actions.proceed')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
CustomModal.propTypes = {
  title: PropTypes.string,
  id: PropTypes.string,
  show: PropTypes.bool,
  handleClose: PropTypes.func,
  content: PropTypes.node,
  handleSubmit: PropTypes.func,
};
