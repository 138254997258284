import PropTypes from 'prop-types';
import { useRef } from 'react';
import { translate } from '../../../app/i18n';

export default function ImageUploader({
    children,
    onFileSelectError,
    onFileSelectSuccess,
}) {
    const inputRef = useRef(null);

    const handleUploadClick = (event) => {
        const file = event.target.files[0];
        const reader = new FileReader();
        reader.readAsDataURL(file);

        if (file.size > 1024 * 1000)
            onFileSelectError({
                error: translate('userMessages.fileSizeLimit'),
            });

        reader.onloadend = function () {
            inputRef.current.value = null;
            onFileSelectSuccess(file, [reader.result]);
        };
    };

    return (
        <div className='file-uploader'>
            <input
                ref={inputRef}
                accept='image/*'
                className='file-uploader__input'
                id='contained-button-file'
                multiple
                type='file'
                onChange={handleUploadClick}
            />
            <label htmlFor='contained-button-file'>
                {children || translate('general.chooseFile')}
            </label>
        </div>
    );
}

ImageUploader.propTypes = {
    children: PropTypes.node,
    onFileSelectError: PropTypes.func,
    onFileSelectSuccess: PropTypes.func,
};
