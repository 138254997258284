import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import config from '../../../../config';
import { Timeline } from '../../../atoms';
import { routeConstants } from '../../../../constants/route.constants';
import { translate } from '../../../../app/i18n';
import { getOrderStatusColor } from '../../../../app/helper/general';

const OrderItem = ({ post }) => {
    return (
        <div className='order-item'>
            <div className='order-item__info'>
                {/* Post image */}
                <img
                    className='order-item__info__img'
                    src={post?.thumbnail?.startsWith('http') ? post.thumbnail :`${config.IMG_URL}${post.thumbnail}`}
                />
                <div className='order-item__info__data'>
                    {/* Post status */}
                    <div
                        className={[
                            'order-item__info__data__item order-item__status',
                            `order-item__status--${getOrderStatusColor(
                                post.statusClass
                            )}`,
                        ].join(' ')}
                    >
                        {translate(`status.${post.status}`)}
                    </div>
                    {/* Post Title */}
                    <div className='order-item__info__data__item order-item__title'>
                        {post.title}
                    </div>
                    {/* Post ID */}
                    <div className='order-item__info__data__item'>
                        <span>{translate('post.table.postId')}:</span>
                        <span>#{post.esId}</span>
                    </div>
                    {/* Post Seller */}
                    <div className='order-item__info__data__item'>
                        {/* @TODO: route to seller profile page */}
                        <span>{translate('seller.singular')}: </span>
                        <Link to={routeConstants.HOME}>
                            {post.seller.username}
                        </Link>
                    </div>
                </div>
                <div className='order-item__info__timeline'>
                    <Timeline data={post.sellerHistory} />
                </div>
            </div>
        </div>
    );
};

OrderItem.propTypes = {
    post: PropTypes.any,
};

export default OrderItem;
