const ar = {
    account: {
        login: 'تسجيل دخول',
        usernameOrPass: 'اسم المستخدم او رقم الهاتف',
        password: 'كلمة المرور',
    },
    post: {
        plural: 'الاعلانات',
        singular: 'اعلان',
        postDetails: 'تفاصيل الاعلان',
        table: {
            postId: 'رقم الاعلان',
            thumbnail: 'صوره الاعلان',
            title: 'الاسم',
            availableQuantity: 'الكميه المتاحه',
            price: 'السعر',
            status: 'الحاله',
            createdAt: 'وقت الانشاء',
            actions: 'اوامر',
        },
        status: {
            active: 'متاح',
            deleted: 'محزوف',
            pendingReview: 'في انتظار المراجعة',
            underReview: 'قيد المراجعة',
            declined: 'مرفوض',
            rejected: 'مرفوض',
            waitingStartDate: 'انتظار تاريخ البدء',
            standBy: 'جاهز',
            sold: 'مباع',
        },
        actions: {
            managePromotion: 'تغيير الترقية',
            manageDiscount: 'تغيير الخصم',
            applyDiscount: 'تطبيق الخصم',
            appendImageDescription: 'إلحاق وصف الصورة',
            takeOffline: 'خذ دون اتصال',
            restock: 'اعاده تخزين',
            repost: 'اعاده النشر',
            repostWithModifications: 'إعادة النشر مع التعديلات',
            editPost: 'تعديل الاعلان',
            archive: 'أرشيف',
            editDeclinedPost: 'تحرير رسالة مرفوضة',
            proceed: 'تقدم',
            cancel: 'الغاء',
            share: 'مشاركة',
            clone: 'استنساخ المنشور',
        },
        general: {
            increaseQuantityBy: 'زيادة الكمية بمقدار',
            quantity: 'الكميه',
            chooseFile: 'اخنر ملف',
            typeMessage: 'اترك رسالة',
            discountErrorMessage: 'لا يمكنك إضافة خصم أكبر منلا يمكنك إضافة خصم يزيد عن 90٪ أو أقل من 0.',
            percentage: 'النسبة المئوية',
            value: 'قيمة',
            egOff: 'ج.م',
            priceAfterDiscount: 'السعر بعد الخصم سيكون: ',
            done: 'تم!',
            salesManagement: 'إدارة المبيعات',
            transactions: 'المعاملات',
            inquiries: 'استفسارات',
            bulk:'تحميل مجمع',
            logout: 'تسجيل خروج',
            auction: 'مزاد',
            directSale: 'بيع مباشر',
            expandAll: 'فتح الكل',
            collapseAll: 'اغلاق الكل',
            clearAll: 'مسح الكل',
            clone: 'استنساخ الاعلان',
        },
        promotionOptions: {
            promotionPackage: 'باقه الترقيه',
            searchResults: 'نتيجة بحث مميزة',
            categoryView: 'حقول الفئات المميزة',
            homePage: 'صفحه مزادات الرئيسيه',
            personalizedEmail: 'في البريد الإلكتروني المخصص',
            monthlySubscription: 'عرض المنتج في القصص',
        },
        promotionPlans: {
            noPlan: 'بدون دفعة',
            silver: 'دفعة مميزة',
            gold: 'دفعه مميزه اكثر',
        },
    },
    return: {
        plural: 'المرتجعات',
        singular: 'مرتجع',
        returnDetails: 'تفاصيل المرتجع',
        returnReason: 'سبب الارجاع',
        table: {
            returnId: 'رقم المرتجع',
            thumbnail: 'صوره الاعلان',
            title: 'الاسم',
            status: 'الحاله',
            createdAt: 'تاريخ الطلب',
            item: 'العنصر',
        },
    },
    order: {
        plural: 'الطلبات',
        singular: 'طلب',
        orderDate: 'تاريح الطلب',
        orderTotal: 'إجمالى الطلب',
        orderDetails: 'بيانات الطلب',
        viewOrderDetails: 'معاينة تفاصيل الطلب',
        table: {
            orderId: 'رقم الطلب',
            thumbnail: 'صوره الاعلان',
            title: 'الاسم',
            status: 'الحاله',
            createdAt: 'وقت طلب الاوردر',
        },
    },
    transaction: {
        plural: 'المعاملات',
        singular: 'المعاملة',
        balance: 'الرصيد',
        onHold: 'في الانتظار',
        goldCoins: 'عملات ذهبية',
        sliverCoins: 'عملات فضية',
        noTransaction: 'ليس لديك معاملات',
        table: {
            id: 'رقم المعاملة',
            status: 'الحالة',
            createdAt: 'تاريح الطلب',
            item: 'البند',
            type: 'النوع',
        },
    },
    userMessages: {
        messagesSentSuccessfully: 'تم إرسال الرسالة بنجاح',
        fileSizeLimit: 'حجم الملف لايجب ان يتعدى الواحد ميجا',
    },
    filters: {
        sortBy: 'ترتيب علي حسب',
        search: 'بحث',
        result: 'نتيجه البحث ل',
        status: 'الحالة',
        duration: 'المدة',
    },
    general: {
        increaseQuantityBy: 'زيادة الكمية بمقدار',
        quantity: 'الكميه',
        chooseFile: 'اخنر ملف',
        and:' و ',
        back:'رجوع',
        typeMessage: 'اترك رسالة',
        mazadatInc:'©شركة مزادات، ',
        optional : 'اختياري',
        allRights:'جميع الحقوق محفوظة.',
        discountErrorMessage:
            'لا يمكنك إضافة خصم أكبر منلا يمكنك إضافة خصم يزيد عن 90٪ أو أقل من 0.',
        percentage: 'النسبة المئوية',
        value: 'قيمة',
        home:'الرئيسية',
        egOff: 'ج.م',
        priceAfterDiscount: 'السعر بعد الخصم سيكون: ',
        done: 'تم!',
        secure:'٪100 آمن',
        salesManagement: 'إدارة المبيعات',
        transactions: 'المعاملات',
        inquiries: 'استفسارات',
        logout: 'تسجيل خروج',
        auction: 'مزاد',
        directSale: 'بيع مباشر',
        expandAll: 'فتح الكل',
        collapseAll: 'اغلاق الكل',
        clearAll: 'مسح الكل',
        privacyPolicy: 'سياسة الخصوصية',
        termsAndConditions: 'الأحكام والشروط',
        bulk:'تحميل مجمع',
        urgent : 'ضروري',
        bulkEdit:'تعديل الاعلانات'
    },
    alert: {
        offline:
            'هل أنت متأكد أنك تريد نقل هذه المشاركة في وضع عدم الاتصال؟ يرجى ملاحظة أنه إذا قمت بتطبيق عرض ترويجي ، فستنتهي صلاحيته ولن يكون قابلاً للاسترداد',
        delete: 'هل أنت متأكد أنك تريد الاستمرار؟',
    },
    httpErrorMsg: {
        serverUnreachable: 'يتعذر الوصول إلى الخادم',
        somethingWrong: 'حدث خطأ ما',
        unkownError: 'خطأ غير معروف',
        networkError: 'خطأ في الشبكة',
        unauthorized: 'غير مصرح به',
        notFound: 'غير موجود',
        noRefreshToken: 'لا يوجد رمز تحديث',
    },
    seller: {
        plural: 'البائع',
        singular: 'البائعون',
    },
    status: {
        pendingpickup: 'قيد الاستلام',
        pendingdropoff: 'قيد التسليم',
        waitingforpickup: 'في انتظار الاستلام',
        waitingfordropoff: 'في انتظار التسليم',
        pendingcompletion: 'في انتظار الانتهاء',
        completed: 'مكتمل',
        cancelled: 'ملغي',
        rejected: 'مرفوض',
        returned: 'طلب استرجاع',
        orderplaced: 'تم الطلب',
        itemdroppedoffbyseller: 'تم تسليم السلعة من قبل البائع',
        itemdroppedoffbyyou: 'تم تسليم السلعة بواسطتك',
        itempickedupbybuyer: 'تم استلام السلعة من قبل الشاري',
        itempickedupbyyou: 'تم استلام السلعة بواسطتك',
        ordercompleted: 'تم اكتمال الطلب',
        ordercancelled: 'تم إلغاء الطلب',
        itemrejectedbythebuyer: 'تم رفض السلعة من المشتري',
        itemrejectedbyyou: 'تم رفض السلعة بواسطتك',
        created: 'انشئ',
        closed: 'مغلق',
        pending: 'معلق',
        returnDetailsWindowTitle: 'تفاصيل الاسترجاع',
        returnrequestcreated: 'تم إنشاء طلب الاسترجاع',
        sellerresponds: 'قرار البائع',
        buyerresponds: 'قرار المشتري',
        yourespond: 'قرارك',
        itemsdroppedoffbybuyer: 'تم تسليم السلعة من قبل المشتري',
        itemsdroppedoffbyyou: 'تم تسليمك للسلعة',
        itemspickedupbyseller: 'تم استلام السلعة بواسطة البائع',
        itemspickedupbybuyer: 'تم استلام السلعة بواسطة المشتري',
        itemspickedupbyyou: 'تم استلام السلعة بواسطتك',
        returnrequestclosed: 'تم إغلاق طلب الاسترجاع',
        returnrequestcancelled: 'تم إلغاء طلب الاسترجاع',
        investigation: 'فتح تحقيق',
        dueon: 'ينتهي في',
        acceptedon: 'تم القبول',
        rejectedon: 'تم الرفض',
        yourequestedaninvestigation: 'طلبت فتح تحقيق',
        buyerrequestedaninvestigation: 'طلب المشتري فتح التحقيق',
        youcancelledtherequest: 'ألغيت طلب الاسترجاع',
        buyercancelledtherequest: 'المشتري ألغى طلب الاسترجاع',
        youdidnotshow: 'لم تأتى',
        buyerdidnotshow: 'لم يأتى المشتري',
        sellerdidnotshow: 'لم يأتى البائع',
        youdidnotrespond: 'فاتك مهلة القرار',
        buyerdidnotrespond: 'فوت المشتري مهلة القرار',
        buyersfault: 'لصالح البائع',
        sellersfault: 'لصالح المشتري',
        yourfault: 'لصالح الطرف الآخر',
        complete: 'اكتمل',
        youdidnotdropofftheorder: 'أنت لم تسلم الطلب',
        sellerdidnotdropofftheorder: 'البائع لم يسلم الطلب',
        thisitemisrequestedtobereturned: 'هناك طلب استرجاع مفتوح لهذه السلعة',
        completionispendingopenreturnrequestsofthisorder:
            'إكمال هذا الطلب معلق بسبب طلب استرجاع جاري لسلعة أو أكثر من هذا الطلب.',
        buyerdidnotpickuptheorderintime: 'المشتري لم يستلم الطلب فى الفترة المحددة',
        youdidnotpickuptheorderintime: 'أنت لم تستلم الطلب فى الفترة المحددة',
        orderwasrejectedattheservicepoint: 'تم رفض الطلب فى نقطة الخدمة',
        reason: 'السبب: ',
        completionispendingonopenreturnrequestsofthisorder:
            'إكمال هذا الطلب معلق بسبب طلب استرجاع مفتوح على هذا الطلب.',
    },
    notFound: {
        ooops: '!عفواً',
        notfoundText: 'لم يتم العثور على هذه الصفحة',
        sorrytext: 'نحن آسفون حقاً ، لكن الصفحة التي طلبتها مفقودة. ربما يمكننا مساعدتك أو العودة إلى الصفحة الرئيسية',
        homepage: 'الصفحة الرئيسية',
        contactus: 'اتصل بنا',
        footer: {
            developedBy: 'تم تطوير بواسطة مزادات',
            copyRights: 'جميع حقوق النشر محفوظه لمازادات ٢٠٢١.'
        }
    },
    bulk:{
        downloadLable:'تنزيل الملف',
        uploadLable:'تحميل الملف',
        historyLable:'الملفات السابقه',
        selectCategory:'اختر تصنيفا',
        category:'التصنيف',
        subCategory:'التصنيف الفرعي',
        uploadText:'حمل ملفك',
        noFilesTextL:'لا يوجد ملف!',
        uploadButton:'حمل',
        downloadButton:'اظهر رابط التنزيل',
        link:'الرابط',
        uploadMissingData:'التصنيف والتصنيف الفرعي والملف مطلوبين',
        errorMessage:'حدث خطأ ما',
        successMessage:'تم',
        selectOption:'اختر التصنيف',
        status:'الحاله',
        date:'التاريخ',
        mapStatus:(status)=>{
            switch (status) {
                case 'FAILED':
                    return 'فشلت'
                case 'COMPLETED':
                    return 'أكتملت'
                case 'IN_PROGRESS':
                    return 'قيد التنفيذ' 
                default:
                    break;
            }
            
        }
    },
      login:{
        enterMobileUsername:'أدخل رقم الهاتف أو اسم المستخدم',
        mobileOrUsername:'رقم الهاتف أو اسم المستخدم',
        enterYourPassword:'أدخل كلمة المرور',
        growYourBusiness:'انطلق نحو آفاق جديدة في المبيعات',
        enterLoginCode: 'ادخل رمز الدخول',
        dontWait:'لا تنتظر . الوقت لن يكون صحيحاً ابداً.',
        signIn:'تسجيل الدخول',
        termsOfUse:'شروط الإستخدام ',
        signUp:'سجل دخول',
        getStarted:'نمو أعمالك على بُعد خطوات… بيع وزايد واشتري بأمان وسهولة',
        byContinuing:'بالمتابعة ، فإنك توافق على ',
        solganInspiring:'الشعار يستحق إلهام كل رجل أعمال وإدارة خطة التسويق الخاصة بهم بكفاءة.',
        usernameOrPhoneRequired:'اسم المستخدم او رقم الهاتف مطلوب',
        passwordRequired:'كلمة المرور مطلوبة',
        isRequired:'مطلوب',
        passwordShort:'كلمةالمرور يجب ان تكون علي الاقل 8 احرف',
        password:'كلمة المرور',
        dontHaveAccount:'ليس لديك حساب؟  ',
        secured: 'خصوصية وآمان بنسبة 100% بدون أي مخاطر.',
        moneyCollection: 'سهولة تحصيل الأموال عبر طرق آمنة ومختلفة.',
        feedback: 'آليات متقدمة للحصول على أفضل التقييمات.',
        product: 'البيع بأكثر من طريقة.. مزايدة أو بيع بسعر ثابت أو دمج الطريقتين.',
        promotedListing: 'خطط ترويجية متنوعة للوصول لأكبر عدد من المستخدمين.',
        delivery: 'خدمات توصيل وتخزين.',
        code: 'رمز تسجيل الدخول',
        isIncorrect: 'غير صحيح'
      },
      registration:{
        joinUs:'انضم الآن',
        byContinuing:'بالمتابعة ، فإنك توافق على ',
        termsOfUse:'شروط الاستخدام وسياسة الخصوصية الخاصة بمنصة مزادات.',
        registeredBusiness:'مسجل كشركة',
        invitationCode:'كود الدعوة',
        alreadyHaveAccount:'لديك حساب بالفعل؟',
        verify:'تحقق',
        signIn:'تسجيل الدخول',
        phoneNumber:'رقم الهاتف',
        username:'اسم المستخدم',
        enterUsername:'أدخل اسم المستخدم الخاص بك',
        password:'كلمة المرور',
        passwordCharacters:'كلمة المرور 8+ أحرف',
        opportunityKnocks:'سجل الآن واكتشف عالم جديد من التجارة الإلكترونية.',
        notLate:'لم يفت الأوان للتسجيل.',
        successSubmission:'لقد قمت بالتسجيل بنجاح ، برجاء تسجيل الدخول',
        invitationCodeToolTip:'في مزادات ، يمكنك دعوة أصدقائك وعائلتك برمز دعوة تجده في ملف تعريف المستخدم الخاص بك بعد التسجيل.\nيؤدي القيام بذلك إلى جمع جوائز وأموال رائعة في محفظتك لبدء رحلة التسوق الخاصة بك. إذا تلقيت رمز دعوة ، فاستخدم هذا الخيار عند التسجيل لكتابته.',
        registeredBusinessToolTip:'إذا كنت تمتلك شركة وترغب في الاستفادة من مزادات، فاستخدم هذا الخيار. يرجى ملاحظة أنه سيُطلب منك تقديم السجل التجاري للشركة.',
        secured: 'خصوصية وآمان بنسبة 100% بدون أي مخاطر.',
        moneyCollection: 'سهولة تحصيل الأموال عبر طرق آمنة ومختلفة.',
        feedback: 'آليات متقدمة للحصول على أفضل التقييمات.',
        product: 'البيع بأكثر من طريقة.. مزايدة أو بيع بسعر ثابت أو دمج الطريقتين.',
        promotedListing: 'خطط ترويجية متنوعة للوصول لأكبر عدد من المستخدمين.',
        delivery: 'خدمات توصيل وتخزين.',
        growYourBusiness:'انطلق في نمو أعمالك نحو آفاق جديدة        ',
        and: 'و'
      },
      validationErrorMsg : {
        required : (name) => `${name} مطلوب`,
        min : (name , min) =>  `${name} يجب ان يكون علي الاقل ${min} احرف`,
        max : (name , max) => `${name} يجب ان يكون علي الاكثر ${max} احرف`,
        invalid : (name) => `${name} غير صالح`
      },
      otp : {
        verifyYour : 'تحقق من رقم هاتفك',
        mobile : 'هاتفك',
        number : 'رقم',
        enterTheCode : ' أدخل الرمز المكون من 4 أرقام الذي تم إرساله إلى هاتفك المحمول:',
        change : 'غير الرقم',
        notReceiveCode : 'ألم تتلق الرمز؟',
        resendIn : 'إعادة الإرسال',
        resend : 'إعادة إرسال',
        verify : 'تحقق',
      },
      passwordStrength : {
        weakPassword : 'كلمة مرور ضعيفة',
        averagePassword : 'كلمه مرور متوسطة',
        greatPassword : 'كلمة مرور رائعة'
      },
};

export default ar;
