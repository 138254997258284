import { useState } from 'react';
import PropTypes from 'prop-types';
import { NavDropdown } from 'react-bootstrap';
import Service from '../../../services';
import i18n from '../../../app/i18n/i18n';
import { Spinner } from '../../atoms';
import { Languages } from '../../../constants';

function LanguageSwitcher({ id, active }) {
  const [isLoading, setIsLoading] = useState(false);

  const updateLanguage = (lan) => {
    setIsLoading(true);
    //Here check on id as if user is logged in, if no user logged in then no need to send the request and change language on clientside
    if (id) {
      Service.userService
        .updateUserLanguage({ id, language: lan })
        .then(() => i18n.changeLanguage(lan))
        .then(() => window.location.reload());
    } else {
      i18n.changeLanguage(lan).then(() => window.location.reload());
    }
  };

  return (
    <>
      {isLoading ? (
        <Spinner />
      ) : (
        <NavDropdown
          title={Languages[active]}
          className='language-swicher d-inline-block'
        >
          <NavDropdown.Item
            className={`ps-2 pe-2 ${active === 'ar' && 'active'}`}
            onClick={() => updateLanguage('ar')}
          >
            {Languages.ar}
          </NavDropdown.Item>
          <NavDropdown.Item
            className={`ps-2 pe-2 ${active === 'en' && 'active'}`}
            onClick={() => updateLanguage('en')}
          >
            {Languages.en}
          </NavDropdown.Item>
        </NavDropdown>
      )}
    </>
  );
}
LanguageSwitcher.propTypes = {
  id: PropTypes.string,
  active: PropTypes.string,
};

export default LanguageSwitcher;
