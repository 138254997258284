import PropTypes from 'prop-types';
import { translate } from '../../../../app/i18n';

const OrderInfo = ({ id, date, price }) => (
    <div className='order-info'>
        <div className='order-info__item '>
            <div className='order-info__item__cell order-info__item__cell--indicator'>
                {translate('order.table.orderId')}:
            </div>
            <div className='order-info__item__cell'>#{id}</div>
        </div>
        <div className='order-info__item '>
            <div className='order-info__item__cell order-info__item__cell--indicator'>
                {translate('order.orderDate')}:
            </div>
            <div className='order-info__item__cell'>{date}</div>
        </div>
        <div className='order-info__item'>
            <div className='order-info__item__cell order-info__item__cell--indicator'>
                {translate('order.orderTotal')}:
            </div>
            <div className='order-info__item__cell'>{price}</div>
        </div>
    </div>
);

OrderInfo.propTypes = {
    id: PropTypes.string,
    date: PropTypes.string,
    price: PropTypes.string,
};

export default OrderInfo;
