import { useEffect, useState } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import {
    fetchUserInquries,
    addAnswer,
    fetchMessages,
} from '../../../redux/actions/inquiriesAction';
import ConversationList from './conversationList/conversationList';
import MessageList from './messagesList/messagesList';

export default function Inquiries() {
    const [activeInquiryId, setActiveInquiryId] = useState('');
    const dispatch = useDispatch();

    const {
        inquiries: { data, messages },
        auth: { id: userId },
    } = useSelector((state) => state, shallowEqual);

    const submit = async (answerData, type, cb) => {
        if (type === 'text') {
            const ansewer = { answer: answerData, question: activeInquiryId };
            dispatch(addAnswer(ansewer, type, activeInquiryId));
            cb();
        } else {
            const formData = new FormData();
            formData.append(
                'data',
                JSON.stringify({ question: activeInquiryId })
            );
            answerData.forEach((image) =>
                formData.append('answerImage', image)
            );
            dispatch(addAnswer(formData, type, activeInquiryId));
            cb();
        }
    };

    const getMessages = (questionId) => {
        setActiveInquiryId(questionId);
        return dispatch(fetchMessages(questionId, userId));
    };

    useEffect(() => {
        dispatch(fetchUserInquries());
    }, []);

    return (
        <div className='inquiries'>
            <div className='inquiries__chat-list'>
                <ConversationList questions={data} getMessages={getMessages} />
            </div>
            <div className='inquiries__chat-screen'>
                <MessageList messages={messages} submit={submit} />
            </div>
        </div>
    );
}
