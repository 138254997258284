import React from "react";

const Slogan = ({ img, description }) => {
  return (
    <div className='slogan-content'>
      <img alt='slogan' src={img} />
      <div className='slogan-content__slogans'>
        <span>{description}</span>
      </div>
    </div>
  );
};

export default Slogan;
