import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { promotionPlanMapper } from '../../../../../../app/mappers/mappers';
import { translate } from '../../../../../../app/i18n';

export default function ModalContentPromotion({
  CallBackPromotion,
  promotionPlan,
}) {
  const { isLoading, promotionOptions } = useSelector((state) => state.post);
  const [selected, setSelected] = useState(promotionPlan);
  const handledPromotionOptions = promotionPlanMapper(promotionOptions);
  const [selectedPrice, setSelectedPrice] = useState(
    promotionOptions.filter((y) => y.title === promotionPlan)[0].price
  );

  useEffect(() => {
    selected &&
      document
        .getElementById(`${selected}`)
        .classList.add('promotion-container__selected');

    setSelectedPrice(
      promotionOptions.filter((y) => y.title === selected)[0]?.price
    );
  }, [selected]);

  useEffect(() => {
    setSelectedPrice(
      promotionOptions.filter((y) => y.title === promotionPlan)[0].price
    );
  }, [promotionPlan]);

  const handleDisable = (option) => {
    if (promotionPlan === 'Gold') {
      return option == 'Silver' || option == 'NoPlan' ? true : false;
    } else if (promotionPlan === 'Silver') {
      return option == 'NoPlan' ? true : false;
    }
  };

  const handleOnClick = (promotionPlan) => {
    if (selected !== promotionPlan) {
      document
        .querySelectorAll('.promotion-container__item')
        ?.forEach((ele) =>
          ele.classList.remove('promotion-container__selected')
        );
      setSelected(promotionPlan);
      CallBackPromotion({ promotionPlan: promotionPlan });
    }
  };

  return (
    <section className='text-center'>
      {/* @TODO : replace with loader  */}
      {isLoading ? (
        '...loading'
      ) : (
        <div className='mb-2 promotion-container'>
          {handledPromotionOptions.map((option, index) => (
            <button
              id={promotionOptions[index].title}
              disabled={handleDisable(promotionOptions[index].title)}
              className={`promotion-container__item 
                ${
                  promotionOptions[index].title === promotionPlan
                    ? 'promotion-container__selected'
                    : ''
                }`}
              key={index}
              onClick={() => handleOnClick(promotionOptions[index].title)}
            >
              <p className='mb-0 promotion-container__item__price'>
                {option.price}
              </p>
              <h5>{option.title}</h5>
              {option.features.map((feature, counter) => (
                <p key={counter} className={`${feature.color}`}>
                  {feature.text}
                </p>
              ))}
            </button>
          ))}
        </div>
      )}
      {/* TODO : needs to be translated  */}
      <p className='pt-3'>
        <span className='ps-1 pe-1'>{translate('post.promotionOptions.promotionPackage')}</span>
        {selectedPrice && selectedPrice} {translate('general.egOff')}
      </p>
    </section>
  );
}

ModalContentPromotion.propTypes = {
  promotionPlan: PropTypes.string,
  CallBackPromotion: PropTypes.func,
};
