import { Row, Col } from 'react-bootstrap';
import { AiOutlineInstagram , AiFillFacebook , AiFillLinkedin , AiFillYoutube } from 'react-icons/ai'

export default function Footer() {
  return (
    <footer className='grey-container mt-3 footer'>
      <Row col={6} className='footerContainer'>
        <Col>
          <a
            href='https://www.instagram.com/mazadat_app/'
            target='_blank'
            rel='noopener noreferrer'
          >
            <AiOutlineInstagram className='footer__socialMedia-icon'/>
          </a>
          <a
            href='https://www.facebook.com/mazadatapp'
            target='_blank'
            rel='noopener noreferrer'
          >
            <AiFillFacebook className='footer__socialMedia-icon'/>
          </a>
          <a
            href='https://www.linkedin.com/company/mazadat'
            target='_blank'
            rel='noopener noreferrer'
          >
            <AiFillLinkedin className='footer__socialMedia-icon'/>
          </a>

          <a
            href='https://www.youtube.com/channel/UCXbvf_EmRZgtqLoKTgqbZ3g'
            target='_blank'
            rel='noopener noreferrer'
          >
            <AiFillYoutube className='footer__socialMedia-icon'/>
          </a>
          <p className='mb-0'>
            Lorem Ipsum is simply dummy text of the printing and typesetting
            industry. Lorem Ipsum has been the industrys standard dummy text
            ever.Since the 1500s, when an unknown printer.
          </p>
        </Col>
        <Col >
          <p className='footerTitle'>Follow Us</p>
          <span>
            Since the 1500s, when an unknown printer took a galley of type and
            scrambled.
          </span>
        </Col>
        <Col >
          <p className='footerTitle'>Contact Us</p>
          <span>Lorem Ipsum, 457 Lorem Ipsum Road, </span>
          <span>Lorem Ipsum </span>
          <span>D04 89GR </span>
          <span>Call us now: 0123-456-789 Email: support@whatever.com</span>
        </Col>
        <Col>
          <p className='footerTitle'>Infomation</p>
          <span>About Us </span>
          <span>Infomation</span>
          <span>Privacy Policy </span>
          <span>Terms & Conditions</span>
        </Col>
        <Col>
          <p className='footerTitle'>Service</p>
          <span>About Us </span>
          <span>Infomation</span>
          <span>Privacy Policy </span>
          <span>Terms & Conditions</span>
        </Col>
        <Col>
          <p className='footerTitle'>Extras</p>
          <span>About Us </span>
          <span>Infomation</span>
          <span>Privacy Policy </span>
          <span>Terms & Conditions</span>
        </Col>
        <Col>
          <p className='footerTitle'>My Account</p>
          <span>About Us </span>
          <span>Infomation</span>
          <span>Privacy Policy </span>
          <span>Terms & Conditions</span>
        </Col>
        <Col>
          <p className='footerTitle'>Userful Links</p>
          <span>About Us </span>
          <span>Infomation</span>
          <span>Privacy Policy </span>
          <span>Terms & Conditions</span>
        </Col>
        <Col>
          <p className='footerTitle'>Our Offers</p>
          <span>About Us </span>
          <span>Infomation</span>
          <span>Privacy Policy </span>
          <span>Terms & Conditions</span>
        </Col>
      </Row>
    </footer>
  );
}
