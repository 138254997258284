import { useSelector } from 'react-redux';
import { initPostActionsMapper } from '../mappers/mappers';
import { translate } from '../i18n';
import config from '../../config'
export const delay = (function () {
    let timer = 0;
    return function (callback, ms, that) {
        clearTimeout(timer);
        timer = setTimeout(callback.bind(that), ms);
    };
})();

export const getNumberOfPagesByLimit = (type, limit = 25) => {
    return Math.ceil(
        useSelector((state) => state[type].numberOfResults) / limit
    );
};

export const getPostStatusAndActions = (object, allData) => {
    let esId = object[translate('post.table.postId')].replace('#', '');
    let data = initPostActionsMapper(
        allData.filter((item) => item.esId === parseInt(esId))[0]
    );
    return data;
};

export const getOriginalDataFromHandledOne = (
    allData,
    handledData,
    type 
) => {
    let id = translate(type.toLowerCase()+'.table.'+type.toLowerCase()+'Id');
    let esId = handledData[id]?.replace('#', '');
    return allData.filter((post) => parseInt(post.esId) === parseInt(esId))[0];
};

export const formatStatus = (str) => {
    return  str.replace(/[&/\\#,+()$~%.'":*?<>{}]/g, '').split(/ |-/g).join('').toLowerCase();
};

export const getOrderStatusColor = (status) => {
    if (status === 'Pending Dropoff') {
        return 'yellow';
    } else if (
        status === 'Waiting for Pickup' ||
        status === 'Returned' ||
        status === 'Pending Completion'
    ) {
        return 'blue';
    } else if (status === 'Completed') {
        return 'green';
    } else if (status === 'Cancelled' || status === 'Rejected') {
        return 'red';
    } else {
        return 'gray';
    }
};

export const getIconDirection = (lan) => {
    return lan == 'ar'? { transform : 'rotate(180deg)' } :  { transform : 'rotate(0deg)' };
}

export const isEmpty = ( obj ) => {
    return Object.keys(obj).length === 0;
}
export const acceptOnlyNumbers =(e)=>{
    /* 
        validation for apply numbers only  
        tested in chrome , safari , firefox and Ios devices
    */
    e = e || window.event;
    let charCode = (typeof e.which == 'undefined') ? e.keyCode : e.which;
    let charStr = String.fromCharCode(charCode);
    if (!charStr.match(/^[0-9]+$/))
      e.preventDefault();
  }
  export const checkUserPlatform = () => {
    return (window.location.href = config.MAZADAT_APP_REDIRECTION);
};

  export  const checkMeOut = () => {
        return !window.location.href.includes('localhost')
          ? config.MAZADAT_APP_REDIRECTION
          : 'javascript:;';
  };