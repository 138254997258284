import PropTypes from 'prop-types';
import Header from './header';
import Footer from './footer';

export default function Index(props) {
  return (
    <div className="">
      <Header />
          <main className="">{props.children}</main>
      <Footer />
    </div>
  );
}

Index.propTypes = {
  children: PropTypes.node,
};
