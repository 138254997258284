import PropTypes from 'prop-types';
import { translate } from '../../../app/i18n';

export default function SearchResult(props) {
  return (
    <section>
      {props.keyWord && (
        <p className='text-center pt-3 pb-3 mb-0'>
          <span className="pe-1">{props.totalNumberOfResults}</span>
          {translate('filters.result')}<span className='text-blue'> {props.keyWord} </span>
        </p>
      )}
    </section>
  );
}

SearchResult.propTypes = {
  totalNumberOfResults: PropTypes.number,
  keyWord: PropTypes.string,
};
