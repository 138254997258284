import services from '../../services';
import {
    fetchOrdersSuccess,
    fetchOrdersFailure,
    fetchOrderFiltersSuccess,
    fetchOrderFiltersFailure,
    seteQueryObject,
    loading,
    resetQueryObject,
} from '../slices/ordersSlice';

export const fetchOrders = () => async (dispatch, getState) => {
    const { queryObject } = getState().orders;

    try {
        dispatch(loading());
        let {
            orders,
            numberOfResults,
        } = await services.orderService.getAllOrders(queryObject);
        dispatch(fetchOrdersSuccess({ orders, numberOfResults }));
    } catch (error) {
        dispatch(fetchOrdersFailure(error));
    }
};

export const fetchOrderFilters = () => async (dispatch) => {
    try {
        // handle success
        dispatch(loading());
        let data = await services.orderService.fetchOrderFilters();
        dispatch(fetchOrderFiltersSuccess(data));
    } catch (error) {
        //handle error
        dispatch(fetchOrderFiltersFailure(error));
    }
};

export const updateQueryObject = (name, value) => async (dispatch) => {
    dispatch(seteQueryObject({ name, value }));
};

export const clearFilters = () => async (dispatch) => {
    dispatch(resetQueryObject());
};

