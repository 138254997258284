import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import services from '../../../services';
import toast, { Toaster } from 'react-hot-toast';
import ListGroup from 'react-bootstrap/ListGroup';
import { translate } from '../../../app/i18n';
import Skeleton from 'react-loading-skeleton';
export default function BulkHistory() {
  const { language = 'en' } = useSelector(state =>  state.user.data);
  const [history, setHistory] = useState();
  useEffect(async () => {
    try {
      const { bulkRequest } = await services.categoryService.getBulkHistory();
      setHistory(bulkRequest.reverse());
    } catch (error) {
      toast.error(translate('bulk.errorMessage'));
    }
  }, []);
  const textColor = (state) => {
    if (state === 'COMPLETED') return 'green';
    if (state === 'FAILED') return 'red';
    if (state === 'IN_PROGRESS') return 'blue';
  };
  return (
    <div>
      <Toaster />

      {history ? (
        <ListGroup variant="flush">
          {history.map((obj) => (
            <div className="bulk-uploads__line-bottom" key={obj._id}>
              <ListGroup.Item
              >
                {translate('bulk.status')} :  
               <span style={{ color: textColor(obj.status) }}>{translate('bulk.mapStatus')(obj.status)}</span>
              </ListGroup.Item>
              <ListGroup.Item>
                {translate('bulk.date')}: { new Date(obj.createdAt).toLocaleString(language) }
              </ListGroup.Item>
              {obj.status === 'COMPLETED' && (
                
                <ListGroup.Item>
                  <a href={obj.link}>{obj.fileName}</a>
                </ListGroup.Item>
              )}          
            </div>
          ))}
        </ListGroup>
      ) : (
        <Skeleton style={{ height: '600px' }} /> 
        )}
    </div>
  );
}
