import services from '../../services';
import {
  fetchPostsSuccess,
  fetchPostsFailure,
  fetchPostFiltersSuccess,
  fetchPostFiltersFailure,
  loading,
  seteQueryObject,
  resetQueryObject,
  bulkEditHidden,
  submitClicked,
} from '../slices/postsSlice';

export const fetchPosts = () => async (dispatch, getState) => {
  const { queryObject } = getState().posts;
  try {
    dispatch(loading());
    let { posts, numberOfResults } = await services.postService.getAllPosts(
      queryObject
    );
    dispatch(fetchPostsSuccess({ posts, numberOfResults }));
  } catch (error) {
    dispatch(fetchPostsFailure(error));
  }
};

export const fetchPostFilters = () => async (dispatch) => {
  try {
    // handle success
    dispatch(loading());
    let data = await services.postService.fetchPostFilters();
    dispatch(fetchPostFiltersSuccess(data));
  } catch (error) {
    //handle error
    dispatch(fetchPostFiltersFailure(error));
  }
};

export const updateQueryObject = (name, value) => async (dispatch) => {
  dispatch(seteQueryObject({ name, value }));
};

export const clearFilters = () => async (dispatch) => {
  dispatch(resetQueryObject());
};

export const setBulkEditButton = (action) => async (dispatch) => {
  dispatch(bulkEditHidden(action));
};

export const setSubmitClicked = ()=> async(dispatch) =>{
  dispatch(submitClicked());

}