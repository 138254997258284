import services from '../../services';
import toast from 'react-hot-toast';
import {
    loading,
    fetchCategorySuccess,
    selectCategory,
    fetchCategoryFailure,
} from '../slices/categorySlice';

export const fetchParentCategories = () => async (dispatch) => {
    try {
        dispatch(loading());
        const data = await services.categoryService.getCategoryParents();
        dispatch(fetchCategorySuccess({ name:'parentCategory',data }));
    } catch (error) {
        toast.error(error);
        dispatch(fetchCategoryFailure({ error: error }));
    }
};
export const fetchChildCategories = (id) => async (dispatch) => {
    try {
        dispatch(loading());
        const data = await services.categoryService.getCategoryChilds(id);
        dispatch(fetchCategorySuccess({ name:'subCategory',data }));
        dispatch(selectCategory({ name:'selectedParent',data:id }));
    } catch (error) {
        toast.error(error);
        dispatch(fetchCategoryFailure({ error: error }));
    }
};
export const setSubCategory = (id) => async (dispatch)=>{
    try {
        dispatch(selectCategory({ name:'selectedSub',data:id }));
    } catch (error) {
        toast.error(error);
        dispatch(fetchCategoryFailure({ error: error }));
    }
};