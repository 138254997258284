import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import LoginForm from './loginForm/loginForm';
import { login } from '../../../redux/actions/authActions';
import { useDispatch, useSelector } from 'react-redux';
import { routeConstants } from '../../../constants';

export default function LoginPage() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { isAuthenticated } = useSelector((state) => state.auth);

  const submit = async (phone, password) => {
    dispatch(login(phone, password));
  };

  useEffect(() => {
    if (isAuthenticated) navigate(routeConstants.HOME);
  }, [isAuthenticated]);

  return <LoginForm submit={submit} />;
}
