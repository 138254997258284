import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Row, Col } from 'react-bootstrap';
import Skeleton from 'react-loading-skeleton';

import { SearchResult, Pagination } from '../../../atoms';
import { Searchbar, Sidebar } from '../../../molecules';
import SingleTab from '../singleTab/singleTab';
import { translate } from '../../../../app/i18n';
import { getNumberOfPagesByLimit , isEmpty} from '../../../../app/helper/general';
import { postMapper } from '../../../../app/mappers/mappers';
import { fetchAllPromotionOptions } from '../../../../redux/actions/postActions';
import {
  fetchPosts,
  clearFilters,
  updateQueryObject,
  fetchPostFilters,
  setBulkEditButton
} from '../../../../redux/actions/postsAction';

export default function Posts() {
  const dispatch = useDispatch();

  const { data, numberOfResults, isDataLoading, queryObject, options } =
    useSelector((state) => state.posts);

  useEffect(() => {
    Promise.all([
      dispatch(fetchPostFilters()),
      dispatch(fetchPosts()),
      dispatch(fetchAllPromotionOptions()),
    ]);
  }, []);

  const updateData = (name, value) => {
    dispatch(updateQueryObject(name, value));
    dispatch(fetchPosts());
    dispatch(setBulkEditButton(true));

  };

  const reset = () => {
    dispatch(clearFilters());
    dispatch(fetchPosts());
    dispatch(setBulkEditButton(true));
  };
  return (
    <div>
      <Row>
        <Col md={3}>
          {isEmpty(options.filters) ? 
            <Skeleton style={{ height: '600px' }} /> :
            <Sidebar
              filters={options.filters}
              queryObject={queryObject}
              updateData={updateData}
              reset={reset}
            />
          }
        </Col>
        <Col md={9}>
          <Searchbar
            numberOfResults={numberOfResults}
            type={
              numberOfResults === 1
                ? translate('post.singular')
                : translate('post.plural')
            }
            updateData={updateData}
            sortOptions={options.sort}
            queryObject={queryObject}
          />
          <SearchResult
            keyWord={queryObject.kw}
            totalNumberOfResults={numberOfResults}
          />
          <SingleTab
            allData={data}
            data={postMapper(data)}
            type='Post'
            isDataLoading={isDataLoading}
          />
          <Pagination
            handleActivePage={(page) => updateData('page', page)}
            data={{
              counter: getNumberOfPagesByLimit('posts'),
              currentPage: queryObject.page,
            }}
          />
        </Col>
      </Row>
    </div>
  );
}
