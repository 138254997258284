import PropTypes from 'prop-types';
import config from '../../../../config';

export default function ConversationList({ questions, getMessages }) {
    return (
        <div className='conversation-list scrollable'>
            {questions?.map((quest) => (
                <div
                    key={quest.id}
                    className='conversation-list__item'
                    onClick={() => getMessages(quest.id)}
                >
                    <img
                        className='conversation-list__item__photo'
                        src={`${config.AVATAR_IMG_URL}${quest.sender.avatar}`}
                        alt='conversation'
                    />
                    <div className='conversation-list__item__info'>
                        <div className='conversation-list__item__title'>
                            {quest.sender.name}
                        </div>
                        <p className='conversation-list__item__snippet'>
                            {quest.title}
                        </p>
                    </div>
                </div>
            ))}
        </div>
    );
}

ConversationList.propTypes = {
    questions: PropTypes.array,
    getMessages: PropTypes.func,
};
