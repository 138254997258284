import { HttpClient } from './HttpClient';
import AuthService from './AuthService';
import StorageService from './StorageService';
import PostService from './postService';
import OrderService from './orderService';
import ReturnService from './returnService';
import InquiryService from './inquiryService';
import TransactionService from './transactionService';
import UserService from './userService';
import CategoryService from './categoryService';
import RegistrationService from './registrationService';

const initServices = () => {
    const httpClient = HttpClient();

    const storageService = StorageService;
    const authService = AuthService(httpClient, storageService);
    const postService = PostService(httpClient);
    const orderService = OrderService(httpClient);
    const returnService = ReturnService(httpClient);
    const inquiryService = InquiryService(httpClient);
    const transactionService = TransactionService(httpClient);
    const userService = UserService(httpClient);
    const categoryService = CategoryService(httpClient);
    const registrationService =RegistrationService(httpClient);
    return {
        storageService,
        authService,
        postService,
        orderService,
        returnService,
        inquiryService,
        transactionService,
        userService,
        categoryService,
        registrationService
    };
};

export default initServices();
