import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    data: [],
    options: {
        filters: {},
        sort: [],
    },
    queryObject: {
        sort: 'Urgent',
        page: 1,
    },
    isDataLoading: false,
    error: '',
};

export const OrdersSlice = createSlice({
    name: 'orders',
    initialState,
    reducers: {
        loading: (state) => {
            return { ...state, isDataLoading: true };
        },
        fetchOrdersSuccess: (state, action) => {
            return {
                ...state,
                data: action.payload.orders,
                numberOfResults: action.payload.numberOfResults,
                isDataLoading: false,
            };
        },
        fetchOrdersFailure: (state, action) => {
            return { ...state, isDataLoading: false, error: action.payload.error };
        },
        fetchOrderFiltersSuccess: (state, action) => {
            return { ...state, options: action.payload, isDataLoading: false };
        },
        fetchOrderFiltersFailure: (state, action) => {
            return { ...state, isDataLoading: false, error: action.payload.error };
        },
        seteQueryObject: (state, action) => {
            if (action.payload.name === 'page')
                return {
                    ...state,
                    queryObject: {
                        ...state.queryObject,
                        [action.payload.name]: action.payload.value,
                    },
                };
            return {
                ...state,
                queryObject: {
                    ...state.queryObject,
                    [action.payload.name]: action.payload.value,
                    page: 1,
                },
            };
        },
        resetQueryObject: (state) => {
            return {
                ...state,
                queryObject: { ...initialState.queryObject },
            };
        },
    },
});

export const {
    loading,
    fetchOrdersSuccess,
    fetchOrdersFailure,
    fetchOrderFiltersSuccess,
    fetchOrderFiltersFailure,
    seteQueryObject,
    resetQueryObject,
} = OrdersSlice.actions;

export default OrdersSlice.reducer;
