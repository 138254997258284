import PropTypes from 'prop-types';
import { translate } from '../../../../app/i18n';
import config from '../../../../config';

export default function Message({
    message: { isMine, timeStamp, content, isAccepted },
}) {
    return (
        <div
            className={['message', `${isMine ? 'message--mine' : ''}`].join(
                ' '
            )}
        >
            <div className='message__container'>
                <div className='message__date'>
                    <span>{timeStamp}</span>
                    {!isAccepted && (
                        <span>{translate('post.status.pendingReview')}</span>
                    )}
                </div>
                {content?.images?.length > 0 &&
                    content.images.map((img, i) => {
                        return (
                            <img
                                key={i}
                                src={`${config.INQUIRIES_IMG_URL}${img}`}
                            />
                        );
                    })}
                {content?.text && <div>{content?.text}</div>}
            </div>
        </div>
    );
}

Message.propTypes = {
    message: PropTypes.shape({
        isMine: PropTypes.bool,
        timeStamp: PropTypes.string,
        isAccepted: PropTypes.bool,
        content: PropTypes.shape({
            images: PropTypes.array,
            text: PropTypes.string,
        }),
    }),
};
