import { translate } from '../../app/i18n';
export const HTTPErrorHandler = (error) => {
    switch (error?.status) {
        case undefined:
            return 'httpErrorMsg.networkError';
        case 403:
            return 'httpErrorMsg.unauthorized';
        case 404:
            return error.data?.message? error.data.message :  translate('httpErrorMsg.notFound');
        case 498:
            return 'httpErrorMsg.noRefreshToken';
        case 504:
            return 'httpErrorMsg.serverUnreachable';
        case 500:
            return 'httpErrorMsg.somethingWrong';
        case 401:
            return error.data?.message? error.data.message : 'httpErrorMsg.somethingWrong';
        default:
            return error?.data || 'httpErrorMsg.unknownError';
    }
};
