import PropTypes from 'prop-types';
import { Row, Col } from 'react-bootstrap';
import { BiSearchAlt2 } from 'react-icons/bi';
import { Select } from '../../atoms';

import { delay } from '../../../app/helper/general';
import { translate } from '../../../app/i18n';

export default function Searchbar({
    numberOfResults,
    type,
    sortOptions,
    updateData,
    queryObject,
    showSearch = true,
}) {
    const handleOnKeyUp = (e) => {
        delay(
            function () {
                updateData('kw', e.target.value);
            },
            1000,
            this
        );
    };

    return (
        <div className='grey-container mb-4'>
            <Row className='align-items-center justify-content-around'>
                <Col className=' text-start ' xs={2}>
                    <p className='mb-0 '>
                        {numberOfResults && numberOfResults + ' ' + type}{' '}
                    </p>
                </Col>
                <Col className=' text-start ' xs={7}>
                    <Select
                        label={translate('filters.sortBy')}
                        name='sort'
                        onSelect={updateData}
                        selected={queryObject.sort}
                        options={sortOptions}
                    />
                </Col>
                {showSearch && (
                    <Col
                        className='p-0 order-md-2  order-4  mb-1 text-end'
                        xs={3}
                    >
                        <div className='input-group search'>
                            <span
                                className='input-group-text bg-white border-0'
                                id='basic-addon1'
                            >
                                <BiSearchAlt2 />
                            </span>

                            <input
                                type='text'
                                className='form-control border-0 text-darkblue search'
                                id='search'
                                placeholder={translate('filters.search')}
                                onKeyUp={handleOnKeyUp}
                            />
                        </div>
                    </Col>
                )}
            </Row>
        </div>
    );
}

Searchbar.propTypes = {
    type: PropTypes.string,
    sortOptions: PropTypes.array,
    updateData: PropTypes.func,
    numberOfResults: PropTypes.number,
    queryObject: PropTypes.any,
    showSearch: PropTypes.bool,
};
