import { useState } from 'react';
import PropTypes from 'prop-types';
import { FiPlusSquare as PlusIcon } from 'react-icons/fi';
import { FiMinusSquare as MinusIcons } from 'react-icons/fi';

const Collapsible = ({ label, children }) => {
    const [ open, setOpen ] = useState(true);

    const toggle = () => setOpen(!open);

    return (
        <div className='collapsible'>
            <div
                className='collapsible__label'
                onKeyPress={toggle}
                onClick={toggle}
            >
                {label}
                <i>{open ? <MinusIcons color='gray' /> : <PlusIcon />}</i>
            </div>

            <div>{open && children} </div>
        </div>
    );
};

Collapsible.propTypes = {
    children: PropTypes.node,
    label: PropTypes.string,
};

export default Collapsible;
