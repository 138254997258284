import { API } from '../constants';
import config from '../../src/config'
export default function PostService(httpClient) {
    const getAllPosts = (queryObject) => {
        queryObject = { limit: config.POSTS_PAGINATION_LIMIT,...queryObject }
        return httpClient.get(API.GET_POSTS, queryObject);
    };

    const getAllPromotionOptions = () => {
        return httpClient.get(API.GET_ALL_PROMOTION_OPTIONS);
    };

    const fetchPostFilters = () => {
        return httpClient.get(API.GET_POST_FILTERS);
    };

    const putPostOffline = (body = {}, id) => {
        return httpClient.put(`${API.PUT_POST_OFFLINE}${id}`, body);
    };

    const putPostArchive = (body = {}, id) => {
        return httpClient.put(`${API.PUT_POST_ARCHIVE}${id}`, body);
    };

    const putPostRepost = (body, id) => {
        return httpClient.put(`${API.PUT_POST_REPOST}${id}`, body);
    };

    const putPostRestock = (body, id) => {
        return httpClient.put(`${API.PUT_POST_RESTOCK}${id}`, body);
    };

    const putPostPromotion = (body, id) => {
        return httpClient.put(`${API.PUT_POST_PROMOTION}${id}`, body);
    };

    const putPostDiscount = (body, id) => {
        return httpClient.post(`${API.PUT_POST_DISCOUNT}${id}`, body);
    };

    const sharePost = (id) => {
        return httpClient.put(API.PUT_SHARE_POST, {
            postId: id,
        });
    };

    const clonePost = (id, location) => {
        return httpClient.post(`${API.CLONE_POST}${id}`, location);
    };
    const editPostPrice= (id,price) => {
        return httpClient.put(`${API.EDIT_POST_PRICE}${id}`, {
            price:Number(price)
        });
    };
    const editPostStock = (id,quantity) => {
        return httpClient.put(`${API.EDIT_POST_STOCK}${id}`, {
            quantity:Number(quantity)
        });
    };
    return {
        getAllPosts,
        fetchPostFilters,
        putPostOffline,
        putPostArchive,
        putPostRepost,
        putPostRestock,
        putPostPromotion,
        getAllPromotionOptions,
        putPostDiscount,
        sharePost,
        clonePost,
        editPostPrice,
        editPostStock,
    };
}
