import { useSelector, shallowEqual } from 'react-redux';
import { thousandsSeparators } from '../../../../app/helper/formaters';
import { translate } from '../../../../app/i18n';

export default function UserWallet() {
    const { wallet } = useSelector((state) => state.user, shallowEqual);

    return (
        <div className='user-wallet'>
            <div className='user-wallet__summary'>
                <div className='user-wallet__summary__text'>{translate('transaction.balance')}</div>
                <div className='user-wallet__summary__balance'>
                    {translate('general.egOff')} {thousandsSeparators(wallet.balance)}
                </div>
            </div>
            <div className='user-wallet__summary'>
                <div className='user-wallet__summary__text'>{translate('transaction.onHold')}</div>
                <div className='user-wallet__summary__balance'>
                    {translate('general.egOff')} {thousandsSeparators(wallet.onHold)}
                </div>
            </div>
            <div className='user-wallet__summary'>
                <div className='user-wallet__summary__text'>{translate('transaction.goldCoins')}</div>
                <div className='user-wallet__summary__balance'>
                    {wallet.goldCoins}
                </div>
            </div>
            <div className='user-wallet__summary'>
                <div className='user-wallet__summary__text'>{translate('transaction.sliverCoins')}</div>
                <div className='user-wallet__summary__balance'>
                    {wallet.silverCoins}
                </div>
            </div>
        </div>
    );
}
