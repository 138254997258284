import services from '../../services';
import {
    loading,
    fetchUserWalletFailed,
    fetchUserWalletSuccess,
    fetchUserDataSuccess,
    fetchUserDataFailed,
    updateUserLanguageFailure
} from '../slices/userSlice';

export const fetchUserData = () => async (dispatch) => {
    try {
        dispatch(loading());
        const data = await services.userService.getUserData();
        dispatch(fetchUserDataSuccess(data));
    } catch (error) {
        dispatch(fetchUserDataFailed({ error: error.message }));
    }
};

export const fetchUserWallet = () => async (dispatch) => {
    try {
        dispatch(loading());
        const data = await services.userService.getUserWallet();
        dispatch(fetchUserWalletSuccess(data));
    } catch (error) {
        dispatch(fetchUserWalletFailed({ error: error.message }));
    }
};

export const updateUserLanguage = (lang) => async (dispatch) => {

    try {
        dispatch(loading());
        await services.userService.updateUserLanguage({language: lang })
        dispatch(fetchUserData()); 
    } catch (error) {
       dispatch(updateUserLanguageFailure({ error: error.message }))
    }
};