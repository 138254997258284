import { CustomTabs } from '../../organisms';
import Posts from './posts/posts';
import Orders from './orders/orders';
import Returns from './returns/returns';
import { translate } from '../../../app/i18n';

export default function HomePage() {
 
    return (
        <CustomTabs
            tabs={[
                { name: 'posts', label: translate('post.plural') },
                { name: 'orders', label: translate('order.plural') },
                { name: 'returns', label: translate('return.plural') },
            ]}
        >
            <Posts />
            <Orders />
            <Returns />
        </CustomTabs>
    );
}
