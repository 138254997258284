import React, { useState, useEffect } from 'react';
import { Button, InputOtp } from '../../atoms';
import { Info } from '../../molecules';
import { AuthWrapper } from '../../templates';
import services from '../../../services';
import Loader from 'react-loader-spinner';
import { translate } from '../../../app/i18n';
import upperLayer from './upperLayer.svg';
import { FeedbackImg } from '../../atoms';

export default function OtpForm({
  mobile = services.storageService.get('registerStepOne')?.form?.phone,
  isNumberVerified,
  stepOneDate,
  verified,
  ...props
}) {
  stepOneDate && services.storageService.set('registerStepOne', stepOneDate);
  const [code, setCode] = useState();
  const [counter, setCounter] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [service, setService] = useState('secondary');
  const [error, setError] = useState();
  const [isFormDisabled, setIsFormDisabled] = useState(true);

  useEffect(() => {
    const timer =
      counter > 0 &&
      setInterval(() => {
        setCounter(counter - 1);
        services.storageService.set('counter', counter - 1);
      }, 1000);

    return () => clearInterval(timer);
  }, [counter]);

  useEffect(() => {
    /*
      go to step one if :
      1- user come from any page instead of step one
      2- otp verified before
      ------------------------
      start counter only when open step two
     */
    if (props.currentStep === 2) {
      if (
        services.storageService.get('registerStepOne') === null ||
        verified === true
      )
        props.goToStep(1);
      else setCounter(60);
    }
  }, [props.currentStep]);

  useEffect(() => {
    // when user refresh the page the counter saved
    window.addEventListener(
      'onbeforeunload',
      setCounter(services.storageService.get('counter'))
    );
  }, []);

  const handleFormSubmit = async () => {
    setIsLoading(true);
    try {
      await services.registrationService.postOtpSubmit({
        phone: mobile,
        otpCode: code,
      });
      resetForm();
      isNumberVerified(true);
      props.goToStep(1);
    } catch (error) {
      setIsLoading(false);
      setError(error);
    }
  };
  const resetForm = () => {
    setCode('');
    setError('');
    setIsLoading(false);
  };
  const handleFormDisabled = () => {
    setIsFormDisabled(code.length === 3 ? false : true);
  };
  const handleBacktoStep1 = (e) => {
    e.preventDefault();
    props.goToStep(1);
    resetForm();
  };
  const handleResendCode = () => {
    try {
      services.registrationService.postResendOtp({
        phone: mobile,
        service: service,
      });
      setCounter(60);
    } catch (error) {
      setError(error);
    }
    setService(service === 'primary' ? 'secondary' : 'primary');
  };
  const handleChangeCode = (code) => {
    services.storageService.set('counter', 0);
    setCode(code);
    setError('');
    handleFormDisabled();
  };
  const Title = () => (
    <p
      style={{
        flexWrap: 'wrap',
      }}
    >
      <span>{translate('otp.verifyYour')} </span>
    </p>
  );

  const Description = () => (
    <p>
      <span className='mb-3 d-inline-block'>
        {translate('otp.enterTheCode')}
      </span>
      <span className='otp-mobile'>
        {mobile && <p className='otp-mobile__text'>{mobile}</p>}
        <button
          className='otp-mobile__button button-transparent link-blue-underline link-transition'
          onClick={handleBacktoStep1}
        >
          {translate('otp.change')}
        </button>
      </span>
    </p>
  );

  const Counter = () => (
    <div className='counter'>
      <p className='counter__text'>{translate('otp.notReceiveCode')}</p>
      {counter !== 0 ? (
        <p className='counter__resend-container'>
          <span className='counter__resend'>{translate('otp.resendIn')}</span>{' '}
          <span className='counter__number'>{counter}</span>s
        </p>
      ) : (
        <button
          className='counter__button button-transparent link-no-underline link-transition'
          onClick={handleResendCode}
        >
          {translate('otp.resend')}
        </button>
      )}
    </div>
  );

  const SubmitButton = () => (
    <div className='verify-otp'>
      <Button
        type='button'
        className='bg-secondary text-white submit-otp text-uppercase'
        onClick={handleFormSubmit}
        disabled={isLoading ? true : isFormDisabled}
      >
        {isLoading ? (
          <Loader
            type='TailSpin'
            className='loader-icon'
            width={20}
            height={20}
            color='#123f87'
          />
        ) : (
          translate('otp.verify')
        )}
      </Button>
    </div>
  );

  return (
    <AuthWrapper>
      <div className='auth-paper paper-otp-padding'>
        <div className='otp-form'>
          <FeedbackImg imgSrc={upperLayer} />
          <div className='otp-form__header'>
            <Info
              title={<Title />}
              className={'info-otp'}
              showDescription={true}
              description={<Description />}
            />
          </div>
          <form>
            <InputOtp {...{ code, handleChangeCode, error }} />
            <Counter />
            <SubmitButton />
            <button
              className='back-otp button-transparent link-no-underline link-transition'
              onClick={handleBacktoStep1}
            >
              {translate('general.back')}
            </button>
          </form>
        </div>
      </div>
    </AuthWrapper>
  );
}
