import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import LinkButton from '../../../atoms/linkButton/linkButton';
import { MdArrowForwardIos as RightArrowIcon } from 'react-icons/md';
import { translate } from '../../../../app/i18n';
import { getIconDirection } from '../../../../app/helper/general'

const TransactionItem = ({ transaction }) => {
  const { language } = useSelector(state => state.user.data);

    return (
        <div className='transaction-item'>
            <div className='transaction-item__date'>
                <div className='transaction-item__date__box'>
                    <div className='transaction-item__date__box__day'>
                        {transaction.date.split(' ')[0]}{' '}
                        {transaction.date.split(' ')[1]}
                    </div>
                    <div className='transaction-item__date__box__year'>
                        {transaction.date.split(' ')[2]}
                    </div>
                </div>
            </div>
            <div className='transaction-item__info'>
                <div className='transaction-item__info__title'>
                    {transaction.id}
                </div>
                <div className='transaction-item__info__subtitle'>
                    {transaction.type}
                </div>
            </div>
            <div className='transaction-item__status'>
                <div
                    className={`status status--${transaction.status.toLowerCase()}`}
                >
                    {translate(`status.${transaction.status.toLowerCase()}`)}
                </div>
            </div>
            <div
                className={`transaction-item__value ${
                    transaction.value.charAt(0) === '-'
                        ? 'transaction-item__value--negative'
                        : 'transaction-item__value--positive'
                }`}
            >
                {transaction.value.charAt(0) === '-'
                    ? transaction.value
                    : '+' + transaction.value}
                <span>{translate('general.egOff')}</span>
            </div>
            <div>
                <LinkButton to={transaction.navTo}>
                    <RightArrowIcon style={getIconDirection(language)} size={22} />
                </LinkButton>
            </div>
        </div>
    );
};

TransactionItem.propTypes = {
    transaction: PropTypes.any,
};

export default TransactionItem;
