/* eslint-disable react/prop-types */
import { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Table } from 'react-bootstrap';
import Skeleton from 'react-loading-skeleton';
import PropTypes from 'prop-types';
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from 'react-icons/md';
import { MdArrowForwardIos as RightArrowIcon } from 'react-icons/md';
import { LinkButton, Button } from '../../../atoms';
import { Actions } from '../posts/actions';
import {
  getPostStatusAndActions,
  getOriginalDataFromHandledOne,
  formatStatus,
  getIconDirection,
} from '../../../../app/helper/general';
import config from '../../../../config';
import { translate } from '../../../../app/i18n';
import { useEffect } from 'react';
import {
  setBulkEditButton,
  setSubmitClicked,
} from '../../../../redux/actions/postsAction';
import services from '../../../../services';
/*---------------------------------------------------------------------------------------------*/
/**
 * @component Price Handle Price input
 * @param {Number} index
 * @param {Object} originalData
 */
function Price({ index, originalData }) {
  const { isBulkEditHidden, isSubmitClicked } = useSelector(
    (state) => state.posts
  );
  const dispatch = useDispatch();
  let priceCalc =
    originalData.price ||
    originalData.auction?.lastBid?.pricePerItem ||
    originalData.auction?.startPrice;
  const [price, setPrice] = useState(priceCalc);
  const [sendRequest, setSendRequest] = useState(false);
  const [color,setColor] = useState('black');

  useEffect(async () => {
    if (
      isSubmitClicked & (Number(price) !== Number(priceCalc)) &&
      sendRequest
    ) {
      try {
        setSendRequest(false);
        await services.postService.editPostPrice(
          originalData._id,
          Number(price)
        );
        setColor('green');
      } catch (error) {
        setColor('red');
      }
    }
  }, [isSubmitClicked]);
  function handleChange(event) {
    setPrice(event.target.value);
    setSendRequest(true);
    if (isBulkEditHidden) {
      dispatch(setBulkEditButton(false));
    }
  }
  return (
    <td key={index}>
      {
        <small>
          <input style={{ color , fontWeight: 'bold' }} type="number"value={price} onChange={handleChange}></input>
        </small>
      }
    </td>
  );
}
/*---------------------------------------------------------------------------------------------*/
/**
 * @component Stock
 * @param {Number} key index number
 * @param {Number} value available quantity index
 * @param {String} id id of the post
 */
/*------------------------------------------------------------*/
function Stock({ index, value, id }) {
  const { isBulkEditHidden, isSubmitClicked } = useSelector(
    (state) => state.posts
  );
  const [sendRequest, setSendRequest] = useState(false);
  const [color,setColor] = useState('black');
  const dispatch = useDispatch();
  const [stock, setStock] = useState(value);
  useEffect(async () => {
    if (isSubmitClicked & (Number(stock) !== Number(value)) && sendRequest) {
      try {
        setSendRequest(false);
        await services.postService.editPostStock(id, Number(stock));
        setColor('green');
      } catch (error) {
        setColor('red');
      }
    }
  }, [isSubmitClicked]);
  function handleChange(event) {
    setSendRequest(true);
    setStock(event.target.value);
    if (isBulkEditHidden) {
      dispatch(setBulkEditButton(false));
    }
  }
  return (
    <td key={index}>
      <small>
        <input  style={{ color , fontWeight: 'bold' }} value={stock} type="number" onChange={handleChange}></input>
      </small>
    </td>
  );
}
/*---------------------------------------------------------------------------------------------*/
export default function SingleTab({ allData, data, isDataLoading, type }) {
  const [isExpandAll, setIsExpandAll] = useState(true);
  let header = data.length > 0 && Object.keys(data[0]);
  const { language } = useSelector((state) => state.user.data);
  const { isBulkEditHidden } = useSelector((state) => state.posts);
  const dispatch = useDispatch();
  function SubmitButton() {
    const handleSubmitClicked = () => dispatch(setSubmitClicked());
    return (
      <Button
        fullWidth={true}
        disabled={isBulkEditHidden}
        onClick={handleSubmitClicked}
      >
        {translate('general.bulkEdit')}
      </Button>
    );
  }

  function Status({ row, index }) {
    if (type === 'Post') {
      let postsStatusAndAction = getPostStatusAndActions(row, allData);
      return (
        <td key={index}>
          <small
            className="p-1 text-white rounded"
            style={{ backgroundColor: `${postsStatusAndAction.color}` }}
          >
            {translate(`post.status.${postsStatusAndAction.text}`)}
          </small>
        </td>
      );
    } else {
      const originalData = getOriginalDataFromHandledOne(allData, row, type);
      return (
        <td key={index}>
          <small
            className={`p-1 text-white rounded status status--${originalData.status.toLowerCase()}`}
          >
            {translate(`status.${formatStatus(originalData.status)}`)}
          </small>
        </td>
      );
    }
  }

  const cell = (head, row, key) => {
    const originalData = getOriginalDataFromHandledOne(allData, row, type);
    if (head == translate(`post.table.title`)) {
      return (
        <td key={key}>
          <img
            className="table__thumbnail me-2"
            src={
              row[translate(`post.table.thumbnail`)].startsWith('http')
                ? row[translate(`post.table.thumbnail`)]
                : `${config.IMG_URL}${row[translate(`post.table.thumbnail`)]}`
            }
          />
          <div className="d-inline-block">
            <span className="pe-2">{row[head]}</span>
            {/* in case post tab selected */}
            {originalData && originalData.auction && (
              <small>
                <br />
                {`(${originalData.auction.biddingCount}
                ${originalData.auction.biddingCount > 1 ? 'Bids' : 'Bid'}  ) on
                ${originalData.availableQuantity}
                ${originalData.availableQuantity > 1 ? 'items' : 'item'}`}
              </small>
            )}
          </div>
        </td>
      );
    } else if (head == translate(`post.table.availableQuantity`)) {
      return <Stock value={row[head]} index={key} id={originalData._id} />;
    } else if (head === translate(`post.table.status`))
      return <Status originalData={originalData} row={row} index={key} />;
    else if (head === translate(`post.table.actions`))
      return (
        <Actions
          allData={allData}
          data={row}
          index={key}
          head={head}
          type={type}
        />
      );
    else if (head === translate(`post.table.thumbnail`)) return;
    else if (head === translate(`post.table.price`))
      return <Price originalData={originalData} index={key} />;
    else return <td key={key}>{row[head]}</td>;
  };

  const handleExpandAll = () => {
    setIsExpandAll(false);
    document
      .querySelectorAll('.table__show-more')
      .forEach((item) => item.classList.add('display-block'));
  };

  const handleCollapseAll = () => {
    setIsExpandAll(true);
    document
      .querySelectorAll('.table__show-more')
      .forEach((item) => item.classList.remove('display-block'));
  };
  return (
    <section>
      {isDataLoading ? (
        <Skeleton style={{ height: '712px', marginBottom: '16px' }} />
      ) : (
        <>
          {type === 'Post' && (
            <div className="w-100 text-end">
              {<SubmitButton />}

              {isExpandAll ? (
                <button
                  className=" border-0 bg-transparent"
                  onClick={handleExpandAll}
                >
                  {translate('general.expandAll')}
                  <MdKeyboardArrowDown />
                </button>
              ) : (
                <button
                  className=" border-0 bg-transparent"
                  onClick={handleCollapseAll}
                >
                  {translate('general.collapseAll')}
                  <MdKeyboardArrowUp />
                </button>
              )}
            </div>
          )}
          <Table className="table" responsive="sm" hover>
            <thead>
              <tr>
                {header &&
                  header.map((element, index) =>
                    element !== translate(`post.table.thumbnail`) ? (
                      <th key={index}>{element}</th>
                    ) : (
                      ''
                    )
                  )}
              </tr>
            </thead>
            <tbody>
              {data.map((row, index) => {
                const originalData = getOriginalDataFromHandledOne(
                  allData,
                  row,
                  type
                );
                return (
                  <tr key={index}>
                    {header.map((head, key) => cell(head, row, key))}
                    <td>
                      <LinkButton
                        to={`/${type.toLowerCase()}/${originalData?._id}`}
                      >
                        <RightArrowIcon
                          style={getIconDirection(language)}
                          size={22}
                        />
                      </LinkButton>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </>
      )}
    </section>
  );
}

SingleTab.propTypes = {
  data: PropTypes.array,
  row: PropTypes.object,
  allData: PropTypes.array,
  type: PropTypes.string,
  head: PropTypes.string,
  index: PropTypes.number,
  isDataLoading: PropTypes.bool,
};
