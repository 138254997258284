import React from "react";
import ReactDOM from "react-dom";
import {Provider} from "react-redux";
import {BrowserRouter as Router} from "react-router-dom";
import App from "./app/App";
import store from "./redux/store";
import {LocaleProvider} from "./app/i18n";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-loading-skeleton/dist/skeleton.css";
import "./index.scss";
import {injectStore} from "./services/HttpClient";
import Fingerprint from "./services/Fingerprint";

/**
 * Initializing fingerprint
 */
Fingerprint.init();

/**
 * Inject store for axios interceptors.
 *
 * @see https://github.com/axios/axios#interceptors
 */
injectStore(store);

ReactDOM.render(
    <React.StrictMode>
        <Provider store={store}>
            <LocaleProvider>
                <Router>
                    <App/>
                </Router>
            </LocaleProvider>
        </Provider>
    </React.StrictMode>,
    document.getElementById("root")
);
