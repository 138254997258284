import PropTypes from 'prop-types';

export default function RadioGroup({
    name,
    elements,
    onSelect,
    selected = '',
}) {
    const handleSelect = (val) => {
        onSelect(name, val);
    };

    return (
        <div className='radio-group'>
            {elements.map((element, key) => (
                <label className='radio-group__label' key={key}>
                    <input
                        className='radio-group__input'
                        name={name}
                        type='radio'
                        checked={selected === element.value}
                        onChange={() => handleSelect(element.value)}
                        value={element.value}
                    />
                    {element.label}
                </label>
            ))}
        </div>
    );
}

RadioGroup.propTypes = {
    name: PropTypes.string,
    onSelect: PropTypes.func,
    elements: PropTypes.array,
    initialValue: PropTypes.string,
    selected: PropTypes.string,
};
