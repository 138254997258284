import React from 'react';
import Switch from 'react-switch';

export default function CustomSwitch({
  isChecked,
  onChange,
  className,
  label,
  name,
  switchClass,
}) {
  return (
    <div className={`custom-switch ${className && className}`}>
      <label className={`custom-switch__label ${switchClass && switchClass}`}>
        {label}
      </label>
      <Switch
        name={name}
        className={`custom-switch__input checked--${isChecked}`}
        onChange={onChange}
        checked={isChecked}
        handleDiameter={10}
        uncheckedIcon={false}
        checkedIcon={false}
        height={10}
        width={40}
      />
    </div>
  );
}
