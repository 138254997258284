import { useSelector, shallowEqual } from 'react-redux';
import { Navigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Spinner } from '../components/atoms';

const PrivateRoute = ({ children, redirectTo }) => {
  const { isAuthenticated } = useSelector((state) => state.auth, shallowEqual);

  if (isAuthenticated) {
    return children;
  } else if (isAuthenticated === false) {
    return <Navigate to={redirectTo} />;
  } else {
    // when isAuthenticated === null
    return <Spinner />;
  }
};

PrivateRoute.propTypes = {
  children: PropTypes.node,
  redirectTo: PropTypes.string,
};

export default PrivateRoute;
