import { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { FiMoreHorizontal } from 'react-icons/fi';
import { translate } from '../../../../../app/i18n';
import {
    getPostStatusAndActions,
    getOriginalDataFromHandledOne,
} from '../../../../../app/helper/general';
import { CustomModal } from '../../../../molecules';
import {
    ModalContentQuantity,
    ModalContentPromotion,
    ModalContentDiscount,
    ModalContenShare,
} from './index';
import {
    takePostOffline,
    takePostArchive,
    repost,
    restock,
    updatePromotionPlan,
    applyDiscount,
    sharePost,
    clonePost,
} from '../../../../../redux/actions/postActions';
import services from '../../../../../services';
import toast from 'react-hot-toast';

export default function Actions({ allData, data , type }) {
    const {
        discount,
        _id: id,
        promotionPlan, 
        sellingMethod,
        price
    } = getOriginalDataFromHandledOne(allData, data , type);
    let body;
    const actionButtons = getPostStatusAndActions(data, allData).buttons;

    const dispatch = useDispatch();
    const [popUp, setPopUp] = useState({
        show: false,
        actionType: '',
        id: '',
        data: '',
        link: '',
    });

    const toggleActions = (id) => {
        document.getElementById(id).classList.toggle('display-block');
    };

    const handleShowPopUp = async (actionType, id, data) => {
        if (actionType === 'share') {
            await dispatch(sharePost(id));
        }
        if (actionType === 'clone') {
            const location = await services.userService.getUserLocation();
            await dispatch(clonePost(id, location));
            toast.success(translate('general.done'));
        }
        setPopUp({
            ...popUp,
            show: true,
            actionType: actionType,
            id: id,
            data: data,
        });
    };

    const handleClosePopUp = () => {
        body = {};
        setPopUp({ ...popUp, show: false, actionType: '', id: '', data: '' });
    };

    const handleSubmitPopUp =  (actionType) => {
        dispatch(actionType(body, id));
    };

    const PopUp = () => {
        const actionType = popUp.actionType,
            data = popUp.data;

        switch (actionType) {
            case 'applyDiscount':
                return <DiscountByName name='applyDiscount' />;
            case 'manageDiscount':
                return <DiscountByName name='manageDiscount' />;
            case 'takeOffline':
                return (
                    <CustomModal
                        title={translate('post.actions.takeOffline')}
                        show={popUp.show}
                        handleClose={handleClosePopUp}
                        handleSubmit={() => handleSubmitPopUp(takePostOffline)}
                        content={translate('alert.offline')}
                    />
                );
            case 'archive':
                return (
                    <CustomModal
                        title={translate('post.actions.archive')}
                        show={popUp.show}
                        handleClose={handleClosePopUp}
                        handleSubmit={() => handleSubmitPopUp(takePostArchive)}
                        content={translate('alert.delete')}
                    />
                );
            case 'repost':
                return (
                    <CustomModal
                    
                        title={translate('post.actions.repost')}
                        show={popUp.show}
                        handleClose={handleClosePopUp}
                        handleSubmit={() => handleSubmitPopUp(repost)}
                        content={
                            <ModalContentQuantity
                                type='repost'
                                sellingMethod={sellingMethod}
                                postPrice={price}
                                postQuantity={data['Stock']}
                                postTitle={data['Title']}
                                postThumbnail={data['Thumbnail']}
                                textLabel={translate('general.quantity')}
                                callBackQuantity={handleCallBack}
                            />
                        }
                    />
                );
            case 'restock':
                return (
                    <CustomModal
                        title={translate('post.actions.restock')}
                        show={popUp.show}
                        handleClose={handleClosePopUp}
                        handleSubmit={() => {
                            body = body && {
                                quantity:
                                    Number(body.quantity) +
                                    Number(data['Stock']),
                            };
                            handleSubmitPopUp(restock);
                        }}
                        content={
                            <ModalContentQuantity
                                type='restock'
                                postQuantity={data['Stock']}
                                postTitle={data['Title']}
                                postThumbnail={data['Thumbnail']}
                                textLabel={translate(
                                    'general.increaseQuantityBy'
                                )}
                                callBackQuantity={handleCallBack}
                            />
                        }
                    />
                );
            case 'managePromotion':
                return (
                    <CustomModal
                        title={translate('post.actions.managePromotion')}
                        show={popUp.show}
                        handleClose={handleClosePopUp}
                        handleSubmit={() =>
                            handleSubmitPopUp(updatePromotionPlan)
                        }
                        content={
                            <ModalContentPromotion
                                CallBackPromotion={handleCallBack}
                                promotionPlan={promotionPlan}
                            />
                        }
                    />
                );
            case 'share':
                return (
                    <CustomModal
                        title={translate('post.actions.share')}
                        show={popUp.show}
                        handleClose={handleClosePopUp}
                        handleSubmit={() =>
                            handleSubmitPopUp(updatePromotionPlan)
                        }
                        content={
                            <ModalContenShare
                                CallBackPromotion={handleCallBack}
                                promotionPlan={promotionPlan}
                            />
                        }
                    />
                );
        }
    };

    function DiscountByName({ name }) {
        return (
            <CustomModal
                title={translate(`post.actions.${name}`)}
                show={popUp.show}
                id={id}
                handleClose={handleClosePopUp}
                handleSubmit={() => handleSubmitPopUp(applyDiscount)}
                content={
                    <ModalContentDiscount
                        price={data.Price}
                        callBackDiscount={handleCallBack}
                        discount={discount}
                    />
                }
            />
        );
    }

    const handleCallBack = (param) => {
        body = param;
    };

    return (
        <>
            <td className='text-end'>
                <button
                    onClick={() => toggleActions(id)}
                    className='bg-transparent border-0'
                >
                    <FiMoreHorizontal />
                </button>
                <ul id={id} className='table__show-more'>
                    {actionButtons.map((btn, index) => (
                        <li key={index}>
                            <button
                                className='border-0 bg-transparent'
                                onClick={() => handleShowPopUp(btn, id, data)}
                            >
                                {translate(`post.actions.${btn}`)}
                            </button>
                        </li>
                    ))}
                </ul>
            </td>
            {PopUp()}
        </>
    );
}

Actions.propTypes = {
    allData: PropTypes.array,
    data: PropTypes.object,
};
