import PropTypes from 'prop-types';
import Loader from 'react-loader-spinner';
import { translate } from '../../../app/i18n';
export default function DropDown({
  name,
  required,
  options,
  onSelect,
  selected = '',
  label,
  lang,
  loading,
}) {
  const handleSelect = (val) => {
    onSelect(name, val);
  };
  return (
    <div className="select d-inline-block">
      <label className="select__label" htmlFor={name}>
        {label}
      </label>

      <select
        className="select__field"
        name={name}
        required={required}
        value={selected}
        onChange={(event) => handleSelect(event.target.value)}
        selected={selected}
        disabled={loading}
      >
        <option hidden value="default">
          {translate('bulk.selectCategory')}
        </option>
        {options &&
          options.map((option) => (
            <option key={option._id} value={option._id}>
              {option.name[lang]}
            </option>
          ))}
      </select>
      <span className="ms-3 me-3">
        {loading && (
          <Loader
            type="TailSpin"
            color="#1bb0b8"
            secondaryColor="#123f87"
            height={15}
            width={15}
            className="d-inline-block"
          />
        )}
      </span>
    </div>
  );
}

DropDown.propTypes = {
  name: PropTypes.string,
  required: PropTypes.bool,
  onSelect: PropTypes.func,
  options: PropTypes.array,
  label: PropTypes.string,
  selected: PropTypes.string,
  lang: PropTypes.string,
  loading: PropTypes.bool,
};

DropDown.defaultProps = {
  required: false,
};
