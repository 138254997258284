import PropTypes from 'prop-types';

export default function Button({
    children,
    size,
    onClick,
    type,
    fullWidth,
    className,
    disabled,
}) {
  return (
    <button
      disabled={disabled}
      className={`
                button
                button--${size}
                ${className && className}
                ${fullWidth && 'button--fullWidth'}
            `}
            size={size}
            onClick={onClick}
            type={type}
        >
            {children}
        </button>
    );
}

Button.propTypes = {
    type: PropTypes.oneOf(['button', 'submit', 'link']),
    size: PropTypes.oneOf(['small', 'medium', 'large', 'extra-large']),
    onClick: PropTypes.func,
    text: PropTypes.string,
    children: PropTypes.any,
    fullWidth: PropTypes.bool,
    className: PropTypes.string,
    disabled:PropTypes.bool,
};

Button.defaultProps = {
  type: 'button',
  size: 'medium',
  className: '',
  fullWidth: false,
};
