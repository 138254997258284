import services from '../../services';
import toast from 'react-hot-toast';
import {
    destroySession,
    loading,
    loginFailed,
    loginSuccess,
    authSuccess
} from '../slices/authSlice';

export const login = (phone, password) => async (dispatch) => {
    try {
        dispatch(loading());
        const data = await services.authService.login(phone, password);
        dispatch(loginSuccess(data));
    } catch (error) {
        toast.error(error);
        dispatch(loginFailed({ error: error }));
    }
};

export const checkAuth = () => async (dispatch) => {
    try {
        const token = await services.authService.checkAuth();
        dispatch(authSuccess(token));
    } catch (error) {
        dispatch(destroySession());
    }
};

export const logout = () => (dispatch) => {
    dispatch(destroySession());
    services.authService.removeLocalUser();
};
