import PropTypes from 'prop-types';

const ErrorMessage = ({ error, name = '', className }) => {
  if (!error) return null;

  return (
    <div className={`error-message ${className && className}`}>
      <div
        className='error-message__text'
        aria-label={name ? `${name} error message` : 'error message'}
      >
        {error.message ? error.message : error}
      </div>
    </div>
  );
};

ErrorMessage.propTypes = {
  error: PropTypes.any,
  name: PropTypes.string,
};

export default ErrorMessage;
