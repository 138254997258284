import { useState } from 'react';
import { Button } from '../../atoms';
import services from '../../../services';
import toast , { Toaster } from 'react-hot-toast';
import { translate } from '../../../app/i18n';
export default function UploadFile() {
  let interval;
  const [file, setFile] = useState();
  const [loading,setLoading] = useState(false);
  const [link,setLink] = useState(false);
  const [fileName,setFileName] = useState(false);

  const handleUpload = (e) => {
    setFile(e.target.files[0]);
  };
  const handleToast = (type)=>{
    const message = type ==='error' ? translate('bulk.errorMessage') : translate('bulk.successMessage');
    toast.dismiss();
    toast[type](message);
    clearInterval(interval);
    setLoading(false)
  }
  const handleHistory = async (fileName)=>{
    const { bulkRequest } = await services.categoryService.getBulkHistory();
    const idx = bulkRequest?.findIndex(val => val.fileName === fileName);
    if(idx === -1 ) return
    if(bulkRequest[idx]?.status === 'FAILED'){
      handleToast('error')
    }
    if(bulkRequest[idx]?.status === 'COMPLETED'){
      handleToast('success')
      setLink(bulkRequest[idx]?.link);
      setFileName(bulkRequest[idx]?.fileName);
    }
  }
  const handleSubmit = async () => {
    let formData = new FormData();
     formData.append('myFiles', file);
     try {
      if(!file) return toast.error(translate('bulk.uploadMissingData'));
      const { message,fileName } = await services.categoryService.postBulkUploads(formData);

      if(message && fileName){
        toast.loading(message);
        setLoading(true)
      interval = setInterval(async()=> await handleHistory(fileName) , 5000)
      }
      
     } catch (error) {
      setLoading(false)
      toast.dismiss();
      toast.error(error.message || translate('bulk.errorMessage') );
    }

  };
  return (
    <><div className='bulk-uploads__upload-file'>
      <h3>{translate('bulk.uploadText')}</h3>
      <div className='bulk-uploads__upload-file__content'>
        <div className='bulk-uploads__upload-file__input'>
          <input
            type='file'
            onChange={handleUpload}
            name='upload'
            accept='application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' />
        </div>
        <div className='bulk-uploads__upload-file__submit'>
          <Button  disabled={loading} onClick={handleSubmit} size='small'>
            {translate('bulk.uploadButton')}
          </Button>
        </div>
      </div>
       <Toaster />
       {link && (<div className='bulk-uploads__link'>
        <a href={link}>{fileName}</a>
    </div>)}
    </div></>
  );
}
