export function thousandsSeparators(num) {
    return Math.trunc(num)
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

export const formatDate = (date) => {
    if (checkValidDate(date)) return null;
    return new Date(date).toLocaleDateString('en-UK', {
        day: 'numeric',
        month: 'short',
        year: 'numeric',
    });
};

export const formatDateLong = (date) => {
    if (checkValidDate(date)) return null;
    return new Date(date).toLocaleDateString('en-UK', {
        weekday: 'long',
        day: 'numeric',
        month: 'short',
        year: 'numeric',
    });
};

export const checkValidDate = (date) => {
    return isNaN(Date.parse(date));
};
