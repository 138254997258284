import services from '../../services';
import {
    fetchTransactionsSuccess,
    fetchTransactionsFailure,
    fetchTransactionFiltersSuccess,
    fetchTransactionFiltersFailure,
    loading,
    setQueryObject,
    resetQueryObject,
} from '../slices/transactionSlice';
import { transactionMapper } from '../../app/mappers/mappers';

export const fetchTransactions = () => async (dispatch, getState) => {
    const { queryObject } = getState().transactions;

    try {
        dispatch(loading());
        const {
            transactions,
            numberOfResults,
        } = await services.transactionService.getTransactions(queryObject);

        dispatch(
            fetchTransactionsSuccess({
                transactions: transactionMapper(transactions),
                numberOfResults,
            })
        );
    } catch (error) {
        dispatch(fetchTransactionsFailure(error));
    }
};

export const fetchTransactionFilters = () => async (dispatch) => {
    try {
        // handle success
        dispatch(loading());
        let data = await services.transactionService.getTransactionFilters();
        dispatch(fetchTransactionFiltersSuccess(data));
    } catch (error) {
        //handle error
        dispatch(fetchTransactionFiltersFailure(error));
    }
};

export const updateQueryObject = (name, value) => async (dispatch) => {
    dispatch(setQueryObject({ name, value }));
};

export const clearFilters = () => async (dispatch) => {
    dispatch(resetQueryObject());
};
