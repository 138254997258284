import { useEffect } from 'react';
import { Toaster } from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';
import { Routes, Route } from 'react-router-dom';
import protectedRoutes from '../routes/protected-routes';
import authRoutes from '../routes/auth-routes';
import PrivateRoute from '../routes/PrivateRoute';
import { checkAuth } from '../redux/actions/authActions';
import { fetchUserData } from '../redux/actions/userAction';
import { routeConstants } from '../constants';
import { Layout, AuthLayout } from '../components/organisms';
import services from '../services';
import i18n from './i18n/i18n';

function App() {
  const dispatch = useDispatch();
  const { language } = useSelector((state) => state.user.data);

  useEffect(() => {
    dispatch(checkAuth());
    dispatch(fetchUserData());
  }, []);

  useEffect(() => {
    i18n.changeLanguage(language).then(() => {
      document.body.dir = i18n.dir();
    });
  }, [language]);

  useEffect(() => {
    let href = window.location.href.toString();
    /* 
        when leave registration page 
        Reset data 
     */
    if (
      !href.includes(routeConstants.REGISTER) &&
      !href.includes(routeConstants.REGISTER_PHONE_INVITATION) &&
      !href.includes(routeConstants.REGISTER_SHAREABLE_LINK) &&
      !href.includes(routeConstants.REGISTER_WITH_NO_TOKEN)
    ) {
      services.storageService.set('isMobileNumberVerified', '');
      services.storageService.set('registerStepOne', '');
    }
  }, []);
  return (
    <>
      <Toaster />
      <Routes>
        {authRoutes.map((route, index) => (
          <Route
            key={index}
            path={route.path}
            element={<AuthLayout>{route.component}</AuthLayout>}
          />
        ))}

        {protectedRoutes.map((route, index) => (
          <Route
            key={index}
            path={route.path}
            element={
              <PrivateRoute redirectTo={routeConstants.LOGIN}>
                <Layout>{route.component}</Layout>
              </PrivateRoute>
            }
          />
        ))}
      </Routes>
    </>
  );
}

export default App;
