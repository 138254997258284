import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    data: [],
    options: {
        filters: {},
        sort: [],
    },
    queryObject: {
        Status: [],
        Duration: '',
        sort: 'Urgent',
        page: 1,
    },
    isDataLoading: false,
    error: '',
};

export const transactionsSlice = createSlice({
    name: 'transactions',
    initialState,
    reducers: {
        loading: (state) => {
            return { ...state, isDataLoading: true };
        },
        fetchTransactionsSuccess: (state, action) => {
            return {
                ...state,
                data: action.payload.transactions,
                numberOfResults: action.payload.numberOfResults,
                isDataLoading: false,
            };
        },
        fetchTransactionsFailure: (state, action) => {
            return {
                ...state,
                isDataLoading: false,
                error: action.payload.error,
            };
        },
        fetchTransactionFiltersSuccess: (state, action) => {
            return { ...state, options: action.payload, isDataLoading: false };
        },
        fetchTransactionFiltersFailure: (state, action) => {
            return {
                ...state,
                isDataLoading: false,
                error: action.payload.error,
            };
        },
        setQueryObject: (state, action) => {
            if (action.payload.name === 'page')
                return {
                    ...state,
                    queryObject: {
                        ...state.queryObject,
                        [action.payload.name]: action.payload.value,
                    },
                };
            return {
                ...state,
                queryObject: {
                    ...state.queryObject,
                    [action.payload.name]: action.payload.value,
                    page: 1,
                },
            };
        },
        resetQueryObject: (state) => {
            return {
                ...state,
                queryObject: { ...initialState.queryObject },
            };
        },
    },
});

export const {
    loading,
    fetchTransactionsSuccess,
    fetchTransactionsFailure,
    fetchTransactionFiltersSuccess,
    fetchTransactionFiltersFailure,
    setQueryObject,
    resetQueryObject,
} = transactionsSlice.actions;

export default transactionsSlice.reducer;
