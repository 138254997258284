const en = {
    account: {
        login: 'Login',
        usernameOrPass: 'Username or Phone Number',
        password: 'Password',
    },
    post: {
        plural: 'Posts',
        singular: 'Post',
        postDetails: 'Post Details',
        table: {
            postId: 'Post ID',
            thumbnail: 'Thumbnail',
            title: 'Title',
            availableQuantity: 'Stock',
            price: 'Price',
            status: 'Status',
            createdAt: 'Creation Date',
            actions: 'Actions',
        },
        status: {
            active: 'Available',
            deleted: 'Deleted',
            pendingReview: 'Pending review',
            underReview: 'Under Review',
            declined: 'Declined',
            rejected: 'Rejected',
            waitingStartDate: 'waiting Start Date',
            standBy: 'Stand By',
            sold: 'Sold',
        },
        actions: {
            managePromotion: 'Upgrade Promotion',
            manageDiscount: 'Manage Discount',
            applyDiscount: 'Apply Discount',
            appendImageDescription: 'Append Images & Description',
            takeOffline: 'Take Offline',
            restock: 'Restock',
            repost: 'Express Repost',
            repostWithModifications: 'Repost With Modifications',
            editPost: 'Edit Post',
            archive: 'Archive',
            editDeclinedPost: 'Edit Declined Post',
            proceed: 'Proceed',
            cancel: 'Cancel',
            share: 'Share',
            clone: 'Clone Post',
        },
        promotionOptions: {
            promotionPackage: 'Promotion package',
            searchResults: 'Featured Search Result',
            categoryView: 'Featured Category Fields',
            homePage: 'On Mazadat Homepage',
            personalizedEmail: 'In Personalized Email',
            monthlySubscription: 'Product Display In Stories',
        },
        promotionPlans: {
            noPlan: 'No Boost',
            silver: 'Premium Boost',
            gold: 'Elite Boost',
        },
    },
    order: {
        plural: 'Orders',
        singular: 'Order',
        orderDate: 'Order Date',
        orderTotal: 'Order Total',
        orderDetails: 'Order Details',
        viewOrderDetails: 'View Order Details',
        table: {
            orderId: 'Order ID',
            thumbnail: 'Thumbnail',
            title: 'Title',
            status: 'Status',
            createdAt: 'Order Date',
        },
    },
    return: {
        plural: 'Returns',
        singular: 'Return',
        returnDetails: 'Return Details',
        returnReason: 'Return Reason',
        table: {
            returnId: 'Return ID',
            thumbnail: 'Thumbnail',
            title: 'Title',
            status: 'Status',
            createdAt: 'Request Date',
            item: 'Item',
        },
    },
    transaction: {
        plural: 'Transactions',
        singular: 'Transaction',
        balance: 'Balance',
        onHold: 'On Hold',
        goldCoins: 'Gold Coins',
        sliverCoins: 'Silver Coins',
        noTransaction: 'You have no transactions',
        table: {
            id: 'Transaction Id',
            status: 'Status',
            createdAt: 'Date',
            type: 'Type',
            value: 'Value',
        },
    },
    userMessages: {
        messagesSentSuccessfully: 'Message sent successfully',
        fileSizeLimit: 'File size cannot exceed more than 1MB',
    },
    filters: {
        sortBy: 'Sort By',
        search: 'Search',
        result: 'Result For',
        status: 'Status',
        duration: 'Duration',
    },
    general: {
        increaseQuantityBy: 'Increase quantity by',
        quantity: 'Quantity',
        back:'back',
        and:' and ',
        optional : 'Optional',
        mazadatInc:'©Mazadat Inc, ',
        allRights:'All rights reserved.',
        home:'home',
        chooseFile: 'Choose file',
        typeMessage: 'Type a message',
        discountErrorMessage: `You can't add discount that is more than 90% or less than 0.`,
        percentage: 'Percentage',
        value: 'Value',
        egOff: 'EGP',
        priceAfterDiscount: 'Price after discount will be: ',
        done: 'Done!',
        secure:'100% Secure',
        salesManagement: 'Sales Management',
        transactions: 'Transactions',
        inquiries: 'Inquiries',
        bulk:'Bulk Post Creation',
        logout: 'Logout',
        auction: 'Auction',
        directSale: 'Direct Sale',
        expandAll: 'Expand All',
        collapseAll: 'Collapse all',
        clearAll: 'Clear All',
        privacyPolicy: 'Privacy Policy',
        termsAndConditions: 'Terms and Conditions',
        urgent : 'Urgent',
        bulkEdit:'Edit Posts'
    },
    alert: {
        offline:
            'Are you sure you want to take this post offline? Please note if you applied a promotion,it will expired and it is not refundable.',
        delete: 'Are you sure you want to continue?',
    },
    httpErrorMsg: {
        serverUnreachable: 'Server unreachable',
        somethingWrong: 'Something went wrong',
        unknownError: 'Unknown error',
        networkError: 'Network error',
        unauthorized: 'Unauthorized',
        notFound: 'Not found',
        noRefreshToken: 'No refresh token',
    },
    seller: {
        plural: 'Sellers',
        singular: 'Seller',
    },
    status: {
        pendingpickup: 'Pending pick-up',
        pendingdropoff: 'Pending drop-off',
        waitingforpickup: 'Waiting for pick-up',
        waitingfordropoff: 'Waiting for drop-off',
        pendingcompletion: 'Pending completion',
        completed: 'Completed',
        cancelled: 'Cancelled',
        rejected: 'Rejected',
        returned: 'Return requested',
        orderplaced: 'Order placed',
        itemdroppedoffbyseller: 'Item dropped-off by seller',
        itemdroppedoffbyyou: 'Item dropped-off by you',
        itempickedupbybuyer: 'Item picked-up by buyer',
        itempickedupbyyou: 'Item picked-up by you',
        ordercompleted: 'Order completed',
        ordercancelled: 'Order cancelled',
        returnrequestcreated: 'Return request created',
        itemrejectedbythebuyer: 'Item rejected by the buyer',
        itemrejectedbyyou: 'Item rejected by you',
        created: 'Created',
        closed: 'Closed',
        pending: 'Pending',
        returnDetailsWindowTitle: 'Return Details',
        sellerresponds: 'Seller\'s decision',
        buyerresponds: 'Buyer\'s decision',
        yourespond: 'Your decision',
        itemsdroppedoffbybuyer: `Item's dropped off by buyer`,
        itemsdroppedoffbyyou: `Item's dropped off by you`,
        itemspickedupbyseller: `Item's picked up by seller`,
        itemspickedupbybuyer: `Item's picked up by buyer`,
        itemspickedupbyyou: `Item's picked up by you`,
        returnrequestclosed: 'Return request closed',
        returnrequestcancelled: 'Return request cancelled',
        investigation: 'Investigation opened',
        dueon: 'Due on',
        acceptedon: 'Accepted on',
        rejectedon: 'Rejected on',
        yourequestedaninvestigation: 'You requested an investigation',
        buyerrequestedaninvestigation: 'Buyer requested an investigation',
        youcancelledtherequest: 'You cancelled the request',
        buyercancelledtherequest: 'Buyer cancelled the request',
        youdidnotshow: 'You did not show',
        buyerdidnotshow: 'Buyer did not show',
        sellerdidnotshow: 'Seller did not show',
        youdidnotrespond: 'You missed the decision window',
        buyerdidnotrespond: 'Buyer missed the decision window',
        buyersfault: 'In favor of the seller',
        sellersfault: 'In favor of the buyer',
        yourfault: 'In favor of the other party',
        complete: 'Complete',
        youdidnotdropofftheorder: 'You did not drop off the order',
        sellerdidnotdropofftheorder: 'Seller did not drop off the order',
        thisitemisrequestedtobereturned:
            'This item has a return request filed for it',
        completionispendingopenreturnrequestsofthisorder:
            'Completion is pending an on-going return request on one or more items in this order',
        buyerdidnotpickuptheorderintime: 'Buyer did not pick up the order in time',
        youdidnotpickuptheorderintime: 'You did not pick up the order in time',
        orderwasrejectedattheservicepoint:
            'Order was rejected at the service point',
        reason: 'Reason: ',
        completionispendingonopenreturnrequestsofthisorder:
            'Completion is pending on open return requests of this order',
    },
    notFound: {
        ooops: 'Ooops!',
        notfoundText: 'This page has not been found',
        sorrytext: 'We are really sorry, but the page you requested was not found. Perhaps you can find what you are looking for on the homepage.',
        homepage: 'Home Page',
        contactus: 'Contact Us',
        footer: {
            developedBy: 'Mazadat is developed by',
            copyRights: 'Copyrights © Mazadat 2021 all rights reserved.',
        }
    },
    bulk:{
        downloadLable:'Download',
        uploadLable:'Upload',
        historyLable:'History',
        selectCategory:'Select category',
        category:'Category',
        subCategory:'Sub-category',
        uploadText:'Upload your File',
        noFilesTextL:'No files chosen!',
        uploadButton:'Upload',
        downloadButton:'Generate download link',
        link:'Link',
        uploadMissingData:'Category, sub-category and file are required',
        errorMessage:'Something went wrong',
        successMessage:'Success',
        selectOption:'Select category',
        status:'Status',
        date:'Date',
        mapStatus:(status)=>{
            switch (status) {
                case 'FAILED':
                    return 'Failed'
                case 'COMPLETED':
                    return 'Completed'
                case 'IN_PROGRESS':
                    return 'In-progress' 
                default:
                    break;
            }
            
        }
    },
      login:{
        enterMobileUsername:'Enter mobile or username',
        mobileOrUsername:'Mobile or Username',
        termsOfUse:'Terms of Use',
        byContinuing:'By continuing, you agree to our ',
        enterYourPassword:'Enter your password',
        growYourBusiness:`Grow Your Business & Expand Your Horizons.`,
        enterLoginCode: 'Enter login code',
        dontWait:`Don't wait. The time will never be just right.`,
        signIn:'Sign In',
        signUp:'Sign Up',
        getStarted:`Opportunity Doesn’t Knock Twice.
        Bid, Buy, & Sell Safely & Conveniently`,
        solganInspiring:'The slogan is worth inspiring every businessman and effectively managing their marketing plan.',
        usernameOrPhoneRequired:'Username or Phone is required',
        passwordRequired:'Password is required',
        password:'Password',
        isRequired:'is required',
        passwordShort:'Password must be at least 8 characters.',
        dontHaveAccount:`Don't have an account? `,
        secured: '100% Private & Secured with Zero Risks of Scams.',
        moneyCollection: 'Easy Money Transactions Straight into Your Mazadat Wallet.',
        feedback: 'Feedback Mechanism to get the Best Ratings.',
        product: 'Product Auction Styled Listings & Selling at Fixed Prices.',
        promotedListing: 'Standard & Advanced Promoted Listings.',
        delivery: 'Delivery & Storage Services',
        and: ' & ',
        code: 'Login code',
        isIncorrect: 'is incorrect'
      },
      registration:{
        joinUs:'Join us',
        byContinuing:'By continuing, you agree to our ',
        termsOfUse:'privacy policy & terms of use',
        registeredBusiness:'Registered as a Business',
        invitationCode:'Invitation code',
        verify:'Verify',
        alreadyHaveAccount:'Already have an account? ',
        signIn:'Sign in',
        phoneNumber:'Mobile Number',
        username:'Username',
        enterUsername:'Enter your username',
        password:'New Password',
        passwordCharacters:'Password 8+ characters',
        opportunityKnocks:'Expand Your Business Borders & Register Now.',
        notLate:'It’s not too late to register.',
        successSubmission:'You have successfully registered , Please sign in to continue.',
        invitationCodeToolTip:'On Mazadat, you can invite your friends and family with an invitation code that you find in your user profile after signing up.Doing this makes you collect great prizes and money in your wallet to start your shopping journey. If you received an invitation code, use this option when registering to type it in.',
        registeredBusinessToolTip:'If you own a business and would like to benefit from Mazadat, use this option.Please note that you will be required to present the commercial register of the business.',
        secured: '100% Private & Secured with Zero Risks of Scams.',
        moneyCollection: 'Easy Money Transactions Straight into Your Mazadat Wallet.',
        feedback: 'Feedback Mechanism to get the Best Ratings.',
        product: 'Product Auction Styled Listings & Selling at Fixed Prices.',
        promotedListing: 'Standard & Advanced Promoted Listings.',
        delivery: 'Delivery & Storage Services',
        growYourBusiness:`Grow Your Business & Expand Your Horizons.`,
      },
      validationErrorMsg : {
        required : (name) => `${name} is required`,
        min : (name , min) =>  `Must be at least ${min} characters`,
        max : (name , max) => `Must be at most ${max} characters`,
        invalid : (name) => `${name} is invalid`
      },
      otp : {
        verifyYour : 'Verify your mobile number',
        mobile : 'mobile',
        number : 'number',
        enterTheCode : ' Enter the 4-digits code that sent to you mobile:',
        change : 'Change number',
        notReceiveCode : 'Didn’t receive the code?',
        resendIn : 'Resend in',
        resend : 'Resend',
        verify : 'verify'
      },
      passwordStrength : {
        weakPassword : 'Weak Password',
        averagePassword : 'Average Password',
        greatPassword : 'Great Password'
      }
};

export default en;
