import React from 'react';

export default function Info({
  title,
  showDescription,
  description,
  className,
}) {
  return (
    <div className={`info ${className && className}`}>
      <h3 className='info__title'>{title}</h3>
      {showDescription && <p className='info__description'>{description}</p>}
    </div>
  );
}
