import React from "react";
import { translate } from "../../../../app/i18n";
import config from "../../../../config";

const AuthFooter = () => {
  return (
    <div className='auth-footer'>
      <div className='auth-footer__right-reserved'>
        <p>
          <span className='auth-footer__right-reserved__mazadat-inc'>
            {translate("general.mazadatInc")}
          </span>
          <span>{translate("general.allRights")}</span>
        </p>
      </div>

      <div className='auth-footer__links'>
        <div className='auth-footer__links__contactus'>
          <a
            href={config.PROJECT_X_CONTACT_US}
            target='_blank'
            rel='noreferrer'
          >
            {translate("notFound.contactus")}
          </a>
        </div>
        <span className='auth-footer__links__contactus'>|</span>
        <div className='auth-footer__links__terms'>
          <a href={config.PROJECT_X_TERMS} target='_blank' rel='noreferrer'>
            {translate("general.termsAndConditions")}
          </a>
        </div>
        <span className='auth-footer__links__contactus'>|</span>

        <div className='auth-footer__links__privacy-policy'>
          <a
            href={config.PROJECT_X_PRIVACY_POLICY}
            target='_blank'
            rel='noreferrer'
          >
            {translate("general.privacyPolicy")}
          </a>
        </div>
      </div>
    </div>
  );
};

export default AuthFooter;
