import { Link } from 'react-router-dom';
import { Col, Row } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { translate } from '../../../app/i18n';
import config from '../../../config';
import { formatDate } from '../../../app/helper/formaters';

const ReturnInfo = ({ data }) => {
  const {
    order: id,
    esId,
    status,
    post,
    quantity,
    sellerData,
    reason,
    createdAt,
    items,
  } = data;

  return (
    <div>
      <Row>
        <Col lg={2}>
          <img
            src={config.IMG_URL + post.thumbnail}
            className='img-thumbnail'
            alt='return thumbnail'
          />
        </Col>
        <Col lg={10}>
          <p className='h4 mb-4'>{post.title}</p>
          <p className='h5'>
            <span>#{esId}</span>
            <span className={`ms-3 me-3 status status--${status?.toLowerCase()}`}>
              {status}
            </span>
            <span>{formatDate(createdAt)}</span>
          </p>
          <p className='h5 mb-3'>
            <span className='ms-2'>{items[0].price}</span>
            <span className='text-muted'>
              ({`${quantity} × ${items[0].price}`})
            </span>
          </p>
          <p className='h5 mb-3'>
            <span className='text-muted'>{translate('seller.plural')} :</span>
            <span className='ms-2 me-2'>{sellerData?.seller.username}</span>
          </p>
          <p className='h5 mb-3'>
            <span className='text-muted'>
              {translate('return.returnReason')} :
            </span>
            <span className='ms-2 me-2'>{reason}</span>
          </p>
          <Link to={`/order/${id}`}>{translate('order.viewOrderDetails')}</Link>
        </Col>
      </Row>
    </div>
  );
};

ReturnInfo.propTypes = {
  data: PropTypes.object,
  id: PropTypes.string,
  date: PropTypes.string,
  price: PropTypes.string,
  order: PropTypes.number,
  esId: PropTypes.number,
  status: PropTypes.string,
  post: PropTypes.object,
  quantity: PropTypes.number,
  sellerData: PropTypes.string,
  reason: PropTypes.string,
  createdAt: PropTypes.string,
  items: PropTypes.array,
};

export default ReturnInfo;
