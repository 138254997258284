import localforage from 'localforage';

export default class Fingerprint {
    // Storage parameters
    static #storeName = 'browser_fingerprint';
    static #storeKey = 'fingerprint';
    static #storageName = 'mazadat';

    // Storage Instance
    static #indexDB = localforage.createInstance({
        name: this.#storageName,
        storeName: this.#storeName,
        description: 'IndexDB/WebSQL storage instance',
        driver: [localforage.INDEXEDDB, localforage.WEBSQL],
    });

    static setFingerprint(uuid) {
        sessionStorage.setItem(this.#storeKey, uuid);
        localStorage.setItem(this.#storeKey, uuid);
        this.#indexDB.setItem(this.#storeKey, uuid).then();
    }

    static createFingerprint() {
        const uuid = crypto.randomUUID();
        this.setFingerprint(uuid);
        return uuid;
    }

    static getFromSessionStorage() {
        const uuid = sessionStorage.getItem(this.#storeKey);
        if (uuid && typeof uuid === 'string') return uuid;
        return null;
    }

    static getFromLocalStorage() {
        const uuid = localStorage.getItem(this.#storeKey);
        if (uuid && typeof uuid === 'string') {
            this.setFingerprint(uuid);
            return uuid;
        }
        return null;
    }

    static async getFromIndexDB() {
        const uuid = await this.#indexDB.getItem(this.#storeKey);
        if (uuid && typeof uuid === 'string') {
            this.setFingerprint(uuid);
            return uuid;
        }

        const fingerprint = this.init();
        if (fingerprint) return fingerprint;

        return this.createFingerprint();
    }

    /**
     * Promise based function
     * this return a fingerprint even if non exist before
     * @returns {Promise<string>}
     */
    static async getFingerprint() {
        const session_uuid = this.getFromSessionStorage();
        if (session_uuid) return session_uuid;

        const local_uuid = this.getFromLocalStorage();
        if (local_uuid) return local_uuid;

        const index_uuid = await this.getFromIndexDB();
        if (index_uuid) return index_uuid;
    }

    /**
     * Only for initializing redux or states
     * this only check sessionStorage and localStorage
     * this won't create a new fingerprint if non exist
     * @returns {string}
     */
    static init() {
        const session_uuid = this.getFromSessionStorage();
        if (session_uuid) return session_uuid;

        const local_uuid = this.getFromLocalStorage();
        if (local_uuid) return local_uuid;

        return '';
    }
}
