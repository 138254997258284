import PropTypes from 'prop-types';

export default function Toolbar({ title, leftItems, rightItems, subtitle }) {
    return (
        <div className='toolbar'>
            <div className='toolbar__left-items'>{leftItems}</div>
            <div className='toolbar__heading'>
                <div className='toolbar__heading__title'>{title}</div>
                {subtitle && (
                    <div className='toolbar__heading__subtitle'>{subtitle}</div>
                )}
            </div>
            <div className='toolbar__right-items'>{rightItems}</div>
        </div>
    );
}

Toolbar.propTypes = {
    title: PropTypes.string,
    subtitle: PropTypes.string,
    leftItems: PropTypes.any,
    rightItems: PropTypes.any,
};
