import libPhoneNumber from 'google-libphonenumber';
import { translate } from '../i18n';

const name_regex = /(?=^.{3,30}$)(?!(.*[0-9\u0660-\u0669].*){6})^[a-z\u0621-\u064A]([.\-_]?[a-z\u0621-\u064A0-9\u0660-\u0669]+)*$/;

export const validationInput = (value, name, min, max, isRequired ) => {
  if (isRequired && !value.length) {
    return translate('validationErrorMsg.required')(name);
  } else if (min && value.length < min) {
    return translate('validationErrorMsg.min')(name, min);
  } else if (min && value.length > max) {
    return translate('validationErrorMsg.max')(name, max);
    //@TODO : handle more than regex by the same line depend on name
   } else if(name === 'name' && !name_regex.test(value.toLowerCase())){
      return translate('validationErrorMsg.invalid')(name)
  }
  else {
    return;
  }
};

export const passwordStrengthBar = (password) => {
  let firstBarBackground = '',
    secondBarBackground = '',
    thirdBarBackground = '';

  if (password?.length === 0) {
    firstBarBackground = 'bg-lightgray';//gray
    secondBarBackground = 'bg-lightgray';
    thirdBarBackground = 'bg-lightgray';

  } else if (password?.length < 8) {
    firstBarBackground = 'bg-error';//red

  } else if (password?.length >= 8 && password?.length < 12) {
    firstBarBackground = 'bg-orange';//yellow
    secondBarBackground = 'bg-orange';
    
  } else {
    firstBarBackground = 'bg-secondary'//green
    secondBarBackground = 'bg-secondary';
    thirdBarBackground = 'bg-secondary';
  }
  return { firstBarBackground , secondBarBackground ,  thirdBarBackground}
};



export const validatePhoneNumber = (phoneNumber) =>{
  if (isValidPhoneNumber(phoneNumber)) {
      return { isValid: true, phoneNumber };
  } if (/^01[0-2|5]{1}[0-9]{8}$/.test(phoneNumber)) {
      return { isValid: true, phoneNumber: `+2${phoneNumber}` };
  } if (/^(201)[0-2|5]{1}[0-9]{8}$/.test(phoneNumber)) {
      return { isValid: true, phoneNumber: `+${phoneNumber}` };
  } if (/^(0201)[0-2|5]{1}[0-9]{8}$/.test(phoneNumber)) {
      return { isValid: true, phoneNumber: `+${phoneNumber.slice(1)}` };
  } if (/^(00201)[0-2|5]{1}[0-9]{8}$/.test(phoneNumber)) {
      return { isValid: true, phoneNumber: `+${phoneNumber.slice(2)}` };
  }
  return { isValid: false, phoneNumber };
}

export const isValidPhoneNumber = (phoneNumber) => {
  const phoneUtil = libPhoneNumber.PhoneNumberUtil.getInstance();

  const { PhoneNumberType } = libPhoneNumber;

  try {
    // parse phone number to country code and number

    const number = phoneUtil.parseAndKeepRawInput(phoneNumber);

    return (
      phoneUtil.isValidNumber(number) &&
      (phoneUtil.getNumberType(number) === PhoneNumberType.MOBILE ||
        phoneUtil.getNumberType(number) ===
          PhoneNumberType.FIXED_LINE_OR_MOBILE)
    );
  } catch {
    return false;
  }
};