import PropTypes from 'prop-types';
import { translate } from '../../../app/i18n';
import { CheckboxGroup, Collapsible, RadioGroup, Button } from '../../atoms';

export default function Sidebar({ 
  filters, 
  updateData,
  queryObject, 
  reset 
}) {
  return (
    <aside className='sidebar'>
      {Object.entries(filters).map(([key, value], i) => (
        <div key={i} className='sidebar__item'>
          <Collapsible label={key}>
            {(key === 'Status' || key === 'الحالة' || key === 'Type' || key === 'النوع')?
                <CheckboxGroup
                name={key}
                elements={value.map((el) => {
                  return {
                    value: el,
                    label: el,
                  };
                })}
                onChange={updateData}
                checkedItems={queryObject && queryObject[key]}
              />
            :
            <RadioGroup
            name={key}
            elements={value.map((el) => {
                return {
                    value: el,
                    label: el,
                };
            })}
            onSelect={updateData}
            intialValue={queryObject && queryObject[key]}
            selected={queryObject && queryObject[key]}
        />}

          </Collapsible>
        </div>
      ))}
      <Button fullWidth={true} onClick={reset}>
            {translate('general.clearAll')}
      </Button>
    </aside>
  );
}

Sidebar.propTypes = {
  filters: PropTypes.shape({
    duration: PropTypes.array,
    status: PropTypes.array,
  }),
  updateData: PropTypes.func,
  queryObject: PropTypes.any,
  reset: PropTypes.func,
};
