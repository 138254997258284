import React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Button } from '../../../atoms';
import config from '../../../../config';
import { LanguageSwitcher } from '../../../molecules';
import { translate } from '../../../../app/i18n';
import { routeConstants } from '../../../../constants';
const AuthHeader = () => {
  const { data, isDataLoading } = useSelector((state) => state.user);
  const { language } = data;
  const navigate = useNavigate();
  const HeaderLogo = () => {
    return (
      <img
        alt='mazadat logo'
        src={
          language == 'en' ? config.MAZADAT_LOGO : config.MAZADAT_LOGO_ARABIC
        }
      ></img>
    );
  };

  const onHandleNavigation = (route) => {
    navigate(route);
  };

  const LanguageSwitcherComponent = () => {
    return (
      <LanguageSwitcher
        active={language}
        isDataLoading={isDataLoading}
        showLanguageIcon={false}
      />
    );
  };

  const SignUpButton = ({ btnClass }) => {
    if (
      window.location.href.toString().includes(routeConstants.REGISTER) ||
      window.location.href
        .toString()
        .includes(routeConstants.REGISTER_WITH_NO_TOKEN) ||
      window.location.href.toString().includes('#step2')
    ) {
      return (
        <Button
          onClick={() => onHandleNavigation(routeConstants.LOGIN)}
          className={btnClass}
        >
          {translate('registration.signIn')}
        </Button>
      );
    } else
      return (
        <Button
          onClick={() => onHandleNavigation(routeConstants.REGISTER)}
          className={btnClass}
        >
          {translate('login.signUp')}
        </Button>
      );
  };

  return (
    <>
      <div className='d-web authHeaderContainer'>
        <div className='authHeaderContainer__black-logo'>
          <HeaderLogo />
        </div>
        <div className='authHeaderContainer__auth-buttons'>
          <SignUpButton
            btnClass={'authHeaderContainer__auth-buttons__sign-up-button'}
          />
          <div className='authHeaderContainer__auth-buttons__language-button'>
            <LanguageSwitcherComponent />
          </div>
        </div>
      </div>

      <div className='d-mobile authHeaderContainer'>
        <div className='authHeaderContainer__upper-header'>
          <div className='authHeaderContainer__upper-header__black-logo'>
            <HeaderLogo />
          </div>
          <div className='authHeaderContainer__upper-header__language-button'>
            <LanguageSwitcherComponent />
          </div>
        </div>
      </div>
    </>
  );
};
export default AuthHeader;
