import PropTypes from 'prop-types';
import { RiSendPlane2Fill as SendPlaneIcon } from 'react-icons/ri';
import { HiOutlinePhotograph as PhotographIcon } from 'react-icons/hi';
import { useSelector } from 'react-redux';
import { FileUploader } from '../../../atoms';
import { translate } from '../../../../app/i18n';
import { getIconDirection } from '../../../../app/helper/general';

export default function InputToolbar({
    onFileSelectSuccess,
    onFileSelectError,
    handleSubmit,
    text,
    setText,
}) {
    const { language } = useSelector(state => state.user.data);
    
    return (
        <div className='input-toolbar'>
            <div className='input-toolbar__items'>
                <div className='input-toolbar__side-items'>
                    <FileUploader
                        onFileSelectSuccess={onFileSelectSuccess}
                        onFileSelectError={onFileSelectError}
                    >
                        <PhotographIcon size={30} color='#007aff' />
                    </FileUploader>
                </div>
                <input
                    type='text'
                    className='input-toolbar__text-field'
                    value={text}
                    onChange={(e) => setText(e.target.value)}
                    placeholder={translate('general.typeMessage')}
                />
                <div className='input-toolbar__side-items'>
                    <button
                        type='submit'
                        className='input-toolbar__send-btn'
                        onClick={handleSubmit}
                    >
                        <SendPlaneIcon style={getIconDirection(language)} size={30} color='#007aff' />
                    </button>
                </div>
            </div>
        </div>
    );
}

InputToolbar.propTypes = {
    onFileSelectSuccess: PropTypes.func,
    onFileSelectError: PropTypes.func,
    handleSubmit: PropTypes.func,
    setText: PropTypes.func,
    text: PropTypes.string,
};
