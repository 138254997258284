export const routeConstants = {
  LOGIN: '/',
  HOME: '/home',
  REGISTER:'/register',
  REGISTER_SHAREABLE_LINK: "/registerUnverified",
  REGISTER_PHONE_INVITATION: "/registerByPhone",
  REGISTER_WITH_NO_TOKEN:"/signup",
  INQUIRIES: '/inquiries',
  TRANSACTIONS : '/transactions',
  POST_DETAILS : '/post/:id',
  ORDER_DETAILS: '/order/:id',
  RETURN_DETAILS: '/return/:id',
  BULK_UPLOAD:'/post/bulk'
};
