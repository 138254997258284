import { Container, Navbar, Nav } from 'react-bootstrap';
import { useDispatch , useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Button } from '../../atoms';
import { logout } from '../../../redux/actions/authActions';
import { routeConstants } from '../../../constants';
import { LanguageSwitcher } from '../../molecules';
import config from '../../../config';
import { translate } from '../../../app/i18n';

export default function Header() {
  const dispatch = useDispatch();
  const { data , isDataLoading } = useSelector(state => state.user);
  const { username , language , _id : id } = data;
  const handleLogOut = (event) => {
    event.preventDefault();
    dispatch(logout());
  };

  return (
    <Navbar className='bg-darkBlue header' expand='lg'>
      <Container fluid>
        <Navbar.Brand  as={Link} to={routeConstants.HOME}>
          <img 
            src={ language === 'ar' ?
            config.MAZADAT_LOGO_ARABIC : config.MAZADAT_LOGO} 
            width='130px' 
            alt='mazadat logo' 
          />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls='basic-navbar-nav' />
        <Navbar.Collapse
          className='bg-darkBlue ps-3 pe-3'
          id='basic-navbar-nav'
        >
          <Nav className='me-auto'>
            <Nav.Link as={Link} to={routeConstants.HOME}>
              {translate('general.salesManagement')}
            </Nav.Link>
            <Nav.Link as={Link} to={routeConstants.TRANSACTIONS}>
              {translate('general.transactions')}
            </Nav.Link>
            <Nav.Link as={Link} to={routeConstants.INQUIRIES}>
              {translate('general.inquiries')}
            </Nav.Link>
            <Nav.Link as={Link} to={routeConstants.BULK_UPLOAD}>
              {translate('general.bulk')}
            </Nav.Link>
          </Nav>
          <>
            <span className='text-white pe-2 text-capitalize'>{ username }</span>
            <LanguageSwitcher active={language} id={id} isDataLoading={isDataLoading}/>
            <Button size='small' className='mt-2 mb-2' onClick={handleLogOut}>
            {translate('general.logout')}
            </Button>
          </>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}
