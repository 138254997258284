import React, { useState } from 'react';
import StepWizard from 'react-step-wizard';
import { RegistrationForm, OtpForm } from '../../organisms';
import services from '../../../services';

export default function Registration() {
  const [mobile, setMobile] = useState();
  const [verified, setVerified] = useState(
    services.storageService.get('isMobileNumberVerified') === 'true'
      ? true
      : false
  );
  const [stepOneDate, setStepOneDate] = useState();
  const getMobileNumber = (number) => {
    setMobile(number);
  };
  const isNumberVerified = (verified) => {
    setVerified(verified);
    services.storageService.set('isMobileNumberVerified', verified);
  };
  const getStepOneDate = (data) => setStepOneDate(data);

  return (
    <StepWizard isHashEnabled>
      <RegistrationForm
        {...{ getMobileNumber, verified, isNumberVerified, getStepOneDate }}
      />
      <OtpForm {...{ mobile, isNumberVerified, stepOneDate, verified }} />
    </StepWizard>
  );
}
