import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    parentCategory: [],
    selectedParent:'',
    subCategory:[],
    selectedSub:'',
    isLoading: false,
    error: '',
};

const categorySlice = createSlice({
    name: 'category',
    initialState,
    reducers: {
        loading: (state) => ({ ...state, isLoading: true }),
        fetchCategorySuccess: (state, action) => {
            return {
                ...state,
                [action?.payload?.name]: action?.payload?.data,
                isLoading:false
            };

        },
        selectCategory:(state, action)=>{
        return {
            ...state,
            [action?.payload?.name]: action?.payload?.data,
            isLoading:false
            }
        },
        fetchCategoryFailure: (state, action) => {
            return {
                ...state,
                error: action.payload.error,
                isLoading: false,
            };
        },
    },
});
export const {
    loading,
    fetchCategorySuccess,
    selectCategory,
    fetchCategoryFailure,
} = categorySlice.actions;

export default categorySlice.reducer;
