// Paths
import { routeConstants } from '../constants/route.constants';
// components
import {  Login  } from '../components/pages';
import {Registration} from "../components/pages"

const authRoutes = [
  {
    path: routeConstants.LOGIN,
    component: <Login />,
  },
  {
    path: routeConstants.REGISTER,
    component: <Registration />,
  },
  {
    path: routeConstants.REGISTER_SHAREABLE_LINK,
    component: <Registration />,
  },
  {
    path: routeConstants.REGISTER_PHONE_INVITATION,
    component: <Registration />,
  },
  {
    path: routeConstants.REGISTER_WITH_NO_TOKEN,
    component: <Registration />,
  }
];

export default authRoutes;
