export const API = Object.freeze({
    REFRESH_TOKEN: '/api/login/token',
    LOGIN: '/api/login',
    GET_POSTS: '/api/post/myPosts/v2',
    GET_ORDERS: '/api/order/ordersAsSeller/v2',
    GET_RETURNS: '/api/returnRequest/asSeller/v2',
    GET_POST_FILTERS: '/api/post/filters',
    GET_RETURN_FILTERS: '/api/returnRequest/filters?userType=seller',
    GET_ORDER_FILTERS: '/api/order/filters?userType=seller',
    GET_INQUIRIES: '/api/question/user/to',
    GET_INQUIRY_MESSAGES: '/api/question',
    POST_INQUIRIES_ANSWER: '/api/question/answer',
    POST_INQUIRIES_IMAGE: '/api/question/answerWithImage',
    GET_TRANSACTIONS: '/api/userTransactions/filters/set',
    GET_TRANSACTION_FILTERS: '/api/userTransactions/filters/get',
    GET_USER_WALLET: '/api/userTransactions/wallet',
    GET_USER_QUESTIONS: '/api/question/user/to',
    PUT_POST_OFFLINE : '/api/post/takeOffline/',
    PUT_POST_ARCHIVE : '/api/post/archive/',
    PUT_POST_REPOST : '/api/post/repost/',
    PUT_POST_RESTOCK : '/api/post/restock/',
    PUT_POST_PROMOTION : '/api/post/promotionPlan/',
    PUT_POST_DISCOUNT : '/api/discount/',
    GET_ALL_PROMOTION_OPTIONS : '/api/home/getAllPromotionOptions',
    PUT_SHARE_POST: '/api/post/dynamicLink',
    CLONE_POST: '/api/post/clone/',
    GET_ORDER_DETAILS: '/api/order/details/',
    GET_USER_DATA: 'api/users/settings/personal',
    UPDATE_USER_LANGUAGE: 'api/users/language/',
    GET_RETURN_STATUS_HISTORY: 'api/returnRequest/details/',
    GET_RETURN_DETAILS: 'api/returnRequest/',
    POST_MOBILE_NUMBER_VERIFY_AND_OTP:'/api/users/verifyPhoneAndSendOTP',
    POST_RESEND_OTP:'/api/users/resendOTP',
    GET_UNIQUE_USER_NAME:'/api/users/username/',
    POST_REGISTRATION_DATA:'/api/users/registerCombined',
    POST_OTP_SUBMIT:'/api/users/submitOTP',
    GET_CATEGORY_PARENTS: '/api/category/parents',
    GET_CATEGORY_CHILDS:'/api/category/childs/',
    GET_BULK_UPLOADS:'/api/post/bulkUploads/getExcelPerCategory',
    POST_BULK_UPLOADS:'/api/post/bulkUploads',
    GET_BULK_HISTORY:'/api/post/bulkUploads/files',
    EDIT_POST_PRICE:'/api/post/updatePrice/',
    EDIT_POST_STOCK:'/api/post/updateQuantity/'
});

export const PUBLIC_API = [API.REFRESH_TOKEN, API.LOGIN];
