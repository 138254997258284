import PropTypes from 'prop-types';
import TransactionItem from '../transactionItem/transactionItem';
import { translate } from '../../../../app/i18n';

const TransactionsList = ({ transactions = [] }) => {
    if (transactions?.length <= 0) return <div>{translate('transaction.noTransaction')}</div>;

    return (
        <div>
            {transactions?.length > 0 ? (
                transactions.map((trans, key) => (
                    <TransactionItem key={key} transaction={trans} />
                ))
            ) : (
                <div>{translate('transaction.noTransaction')}</div>
            )}
        </div>
    );
};

TransactionsList.propTypes = {
    transactions: PropTypes.array,
};

export default TransactionsList;
