import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { translate } from '../../../app/i18n';
import services from '../../../services';
import ReturnInfo from './returnInfo';
import { Timeline } from '../../atoms';
import { isEmpty } from '../../../app/helper/general';

export default function ReturnDetails() {
  const { id } = useParams();
  const [state, setState] = useState({
    returns: {},
    status: [],
  });

  const fetchReturnDetails = async (id) => {
    try {
      const returnDetails = await services.returnService.fetchReturnDetails(id);
      const { sellerHistory } = await services.returnService.fetchReturnHistoryStatus(id);
      setState({ returns: returnDetails, status: sellerHistory });
    } 
    catch (error) {
      throw new Error(error);
    }
  };

  useEffect(() => {
    fetchReturnDetails(id);
  }, []);

  let { returns, status } = state;

  return (
    <div className='screen'>
      <h1>{translate('return.returnDetails')}</h1>
        {!isEmpty(returns) && <ReturnInfo data={returns} />}
        <Timeline data={status} />
    </div>
  );
}
