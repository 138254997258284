import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Row, Col } from 'react-bootstrap';
import Skeleton from 'react-loading-skeleton';

import TransactionsList from './transactionsList/transactionsList';
import { Pagination } from '../../atoms';
import { Searchbar, Sidebar } from '../../molecules';
import { translate } from '../../../app/i18n';
import { getNumberOfPagesByLimit , isEmpty } from '../../../app/helper/general';
import {
    fetchTransactions,
    clearFilters,
    updateQueryObject,
    fetchTransactionFilters
} from '../../../redux/actions/transactionAction';
import { fetchUserWallet } from '../../../redux/actions/userAction';
import UserWallet from './userWallet/userWallet';

export default function Transactions() {
    const dispatch = useDispatch();

    const {
        data,
        numberOfResults,
        isDataLoading,
        queryObject,
        options,
    } = useSelector((state) => state.transactions);

    useEffect(() => {
        Promise.all([
            dispatch(fetchTransactionFilters()),
            dispatch(fetchTransactions()),
            dispatch(fetchUserWallet())
          ]);
    }, []);

    const updateData = (name, value) => {
        dispatch(updateQueryObject(name, value));
        dispatch(fetchTransactions());
    };

    const reset = () => {
        dispatch(clearFilters());
        dispatch(fetchTransactions());
    };

    return (
        <div className='screen'>
            <Row>
                <Col lg={3}>
                {isEmpty(options.filters) ? 
                    <Skeleton style={{ height: '600px' }} /> :
                    <Sidebar
                        filters={options.filters}
                        updateData={updateData}
                        queryObject={queryObject}
                        reset={reset}
                    />
                }
                </Col>
                <Col>
                    <UserWallet />

                    <Searchbar
                        numberOfResults={numberOfResults}
                        sortOptions={options.sort}
                        queryObject={queryObject}
                        type={translate('transaction.singular')}
                        updateData={updateData}
                        showSearch={ false }
                    />
                    <TransactionsList
                        isDataLoading={isDataLoading}
                        transactions={data}
                    />
                    <Pagination
                        handleActivePage={(page) => updateData('page', page)}
                        data={{
                            counter: getNumberOfPagesByLimit('transactions'),
                            currentPage: queryObject.page,
                        }}
                    />
                </Col>
            </Row>
        </div>
    );
}
