export const getImagePathByEnv = (
    path,
    env = process.env.REACT_APP_CLOUD_ENV
    ) => {
    switch (path) {
      case 'IMG_URL':
        if (env === 'development') return 'imagesdev/';
        else return 'imagesprod/';
      case 'INQUIRIES_IMG_URL':
        if (env === 'development') return 'question-answer-dev/';
        else return 'question-answer-prod/';
      default:
        break;
    }
  };

export default {
    BASE_URL: process.env.REACT_APP_BACKEND_URL,
    IMG_URL: process.env.REACT_APP_IMAGE_CONTAINER + getImagePathByEnv('IMG_URL'),
    INQUIRIES_IMG_URL: process.env.REACT_APP_IMAGE_CONTAINER + getImagePathByEnv('INQUIRIES_IMG_URL'),
    AVATAR_IMG_URL: process.env.REACT_APP_IMAGE_CONTAINER + 'avatars/',
    MAZADAT_LOGO :  process.env.REACT_APP_IMAGE_CONTAINER + 'landing-page/mazadatLogo.svg',
    MAZADAT_LOGO_ARABIC : process.env.REACT_APP_IMAGE_CONTAINER+ 'landing-page/arMazadatLogo.svg',
    RECAPTCHA_SITE_KEY:process.env.REACT_APP_RECAPTCHA_SITE_KEY,
    LINK_ICON :  process.env.REACT_APP_IMAGE_CONTAINER + 'landing-page/link@3x-min.png',
    MAZADAT_APP_REDIRECTION:process.env.REACT_APP_MAZADAT_APP_REDIRECTION,
    PROJECT_X_CONTACT_US:process.env.REACT_APP_PROJECT_X+ 'contact-us',
    PROJECT_X_TERMS:process.env.REACT_APP_PROJECT_X+ 'legal/terms',
    PROJECT_X_PRIVACY_POLICY:process.env.REACT_APP_PROJECT_X+ 'legal/policy',
    GOOGLE_PLAY : process.env.REACT_APP_IMAGE_CONTAINER + 'landing-page/EnglishApp%20Store@3x-min.png',
    GOOGLE_PLAY_ARABIC : process.env.REACT_APP_IMAGE_CONTAINER + 'landing-page/Google%20Play@3x-min.png',
    APP_STORE : process.env.REACT_APP_IMAGE_CONTAINER + 'landing-page/EnglishGoogle%20Play@3x-min.png',
    APP_STORE_ARABIC : process.env.REACT_APP_IMAGE_CONTAINER + 'landing-page/App%20Store@3x-min.png',
    MAZADAT_SMALL_LOGO : process.env.REACT_APP_IMAGE_CONTAINER +'landing-page/EnglishLogo@3x-min.png',
    MAZADAT_SMALL_LOGO_ARABIC: process.env.REACT_APP_IMAGE_CONTAINER +'landing-page/logo@3x-min.png',
    MAZADAT_PAGE_NOT_FOUND:process.env.REACT_APP_IMAGE_CONTAINER+'landing-page/404Image.png',
    GOOGLE_HREF : 'https://play.google.com/store/apps/details?id=at.mazad.app',
    APPLE_HREF : 'https://apple.co/2SHG7Tx',
    CLOUD_ENV:process.env.REACT_APP_CLOUD_ENV,
    DEFAULT_LOCALE: 'en',
    LOCALES: {
      ENGLISH: 'en',
      Arabic: 'ar',
    },
    POSTS_PAGINATION_LIMIT:25
  };