import { useRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Message from '../message/message';
import InputToolbar from '../inputToolbar/inputToolbar';
import { Toolbar } from '../../../atoms';

export default function MessageList({ messages, submit }) {
    const [images, setImages] = useState([]);
    const [imagesToDisplay, setImagesToDisplay] = useState([]);
    const [text, setText] = useState('');
    const messagesEndRef = useRef(null);

    const onFileSelectError = (error) => alert(error);

    const onFileSelectSuccess = (file, display) => {
        setImages((imgs) => [...imgs, file]);
        setImagesToDisplay((imgs) => [...imgs, display]);
    };

    const resetFiles = () => {
        setImagesToDisplay(() => []);
        setImages(() => []);
    };

    const resetText = () => setText('');

    const removeImage = (index) => {
        setImages((prev) => prev.filter((file, i) => i !== index));
        setImagesToDisplay((prev) => prev.filter((file, i) => i !== index));
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        if (images?.length > 0) submit(images, 'image', resetFiles);
        else submit(text, 'text', resetText);
    };

    const scrollToBottom = () => {
        messagesEndRef.current.scrollIntoView();
    };

    useEffect(() => {
        scrollToBottom();
    }, []);

    return (
        <div className='messages-list'>
            <Toolbar title={messages?.title} subtitle={messages?.subtitle} />
            <div className='messages-list__container'>
                {messages?.messages.map((msg, i) => (
                    <Message key={i} message={msg} />
                ))}
                {imagesToDisplay.length > 0 && (
                    <div className='messages-list__display'>
                        {imagesToDisplay.map((img, i) => (
                            <div
                                key={i}
                                className='messages-list__display__item'
                            >
                                <button
                                    className='messages-list__display__item__close'
                                    onClick={() => removeImage(i)}
                                >
                                    <span>&times;</span>
                                </button>
                                <img src={img} />
                            </div>
                        ))}
                    </div>
                )}
                <div
                    style={{ float: 'left', clear: 'both' }}
                    ref={messagesEndRef}
                ></div>
            </div>
            <InputToolbar
                handleSubmit={handleSubmit}
                onFileSelectError={onFileSelectError}
                onFileSelectSuccess={onFileSelectSuccess}
                text={text}
                setText={setText}
                images={images}
            />
        </div>
    );
}

MessageList.propTypes = {
    messages: PropTypes.any,
    submit: PropTypes.func,
};
