import { createSlice } from '@reduxjs/toolkit';
import { translate } from '../../app/i18n';

const initialState = {
    data: [],
    options: {
        filters: {},
        sort: [],
    },
    queryObject: {
        sort: translate('general.urgent'),
        page:1,
    },
    isDataLoading: false,
    isBulkEditHidden:true,
    isSubmitClicked:0,
    error: '',
};

export const postsSlice = createSlice({
    name: 'posts',
    initialState,
    reducers: {
        loading: (state) => {
            return { ...state, isDataLoading: true };
        },
        fetchPostsSuccess: (state, action) => {
            return {
                ...state,
                data: action.payload.posts,
                numberOfResults: action.payload.numberOfResults,
                isDataLoading: false,
            };
        },
        fetchPostsFailure: (state, action) => {
            return { ...state, isDataLoading: false, error: action.payload.error };
        },
        fetchPostFiltersSuccess: (state, action) => {
            return { ...state, options: action.payload, isDataLoading: false };
        },
        fetchPostFiltersFailure: (state, action) => {
            return { ...state, isDataLoading: false, error: action.payload.error };
        },
        seteQueryObject: (state, action) => {
            if (action.payload.name === 'page')
                return {
                    ...state,
                    queryObject: {
                        ...state.queryObject,
                        [action.payload.name]: action.payload.value,
                    },
                };
            return {
                ...state,
                queryObject: {
                    ...state.queryObject,
                    [action.payload.name]: action.payload.value,
                    page: 1,
                },
            };
        },
        resetQueryObject: (state) => {
            return {
                ...state,
                queryObject: { ...initialState.queryObject },
            };
        },
        bulkEditHidden: (state,action) => {
            return {
                ...state,
                isBulkEditHidden: action.payload,
            };
        },
        submitClicked: (state) => {
            return {
                ...state,
                isSubmitClicked: state.isSubmitClicked + 1,
            };
        } 
    },
});

export const {
    loading,
    fetchPostsSuccess,
    fetchPostsFailure,
    fetchPostFiltersFailure,
    fetchPostFiltersSuccess,
    seteQueryObject,
    resetQueryObject,
    bulkEditHidden,
    submitClicked
} = postsSlice.actions;

export default postsSlice.reducer;
