import services from '../../services';
import {
    fetchReturnsSuccess,
    fetchReturnsFailure,
    fetchReturnFiltersSuccess,
    fetchReturnFiltersFailure,
    seteQueryObject,
    loading,
    resetQueryObject,
} from '../slices/returnsSlice';

export const fetchReturns = () => async (dispatch, getState) => {
    const { queryObject } = getState().returns;
    try {
        dispatch(loading());
        let {
            returnRequests,
            numberOfResults,
        } = await services.returnService.getAllReturns(queryObject);
        dispatch(fetchReturnsSuccess({ returnRequests, numberOfResults }));
    } catch (error) {
        dispatch(fetchReturnsFailure(error));
    }
};

export const fetchReturnFilters = () => async (dispatch) => {
    try {
        // handle success
        dispatch(loading());
        let data = await services.returnService.fetchReturnFilters();
        dispatch(fetchReturnFiltersSuccess(data));
    } catch (error) {
        //handle error
        dispatch(fetchReturnFiltersFailure(error));
    }
};

export const updateQueryObject = (name, value) => async (dispatch) => {
    dispatch(seteQueryObject({ name, value }));
};

export const clearFilters = () => async (dispatch) => {
    dispatch(resetQueryObject());
};

