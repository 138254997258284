import React, { useRef } from 'react';
import { ErrorMessage } from '../../atoms';
import { acceptOnlyNumbers } from '../../../app/helper/general';

export default function InputOtp({ handleChangeCode, error, code }) {
  const inputRef = useRef();

  const handleOnChange = (e) => {
    if (e.target.value.length <= 4) {
      if (e.target.value.length === 4) {
        document.querySelector('.input-otp').blur();
      }
      handleChangeCode(e.target.value);
    }
  };

  return (
    <div className='input-group-otp'>
      <input
        className={`input-otp ${error && 'input-otp-error'}`}
        type='number'
        min={0}
        max={9999}
        maxLength='4'
        onChange={handleOnChange}
        value={code}
        placeholder={'****'}
        ref={inputRef}
        onKeyPress={acceptOnlyNumbers}
      />
      <ErrorMessage className={'otp-error-padding'} {...{ error }} />
    </div>
  );
}
